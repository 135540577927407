import { Position, SpinButton, Stack } from '@fluentui/react'
import { useCallback, useMemo, useState } from 'react'
import { AllFields } from '../../../utils'
import { FilterOperationType, NumberField, NumberFilter } from '@notidar/api'
import { useTranslation } from 'react-i18next'

export interface NumberFilterComponentProps {
  filter: NumberFilter
  fields: AllFields[]
  onSubmit: (filter: NumberFilter) => void
}

export const NumberFilterComponent = ({ filter, fields, onSubmit }: NumberFilterComponentProps): JSX.Element | null => {
  const { t } = useTranslation();
  const [state, setState] = useState<{ lowerLimit?: number, upperLimit?: number } | undefined | null>({ lowerLimit: filter.lowerLimit ?? undefined, upperLimit: filter.upperLimit ?? undefined });
  const field = useMemo(() => fields.find(y => y.name === filter.field) as NumberField, [filter, fields]);

  const exponentMultiplier = field.exponent ? Math.pow(10, field.exponent) : 1;
  const step = 1 / exponentMultiplier;
  const minValue = Number.MIN_SAFE_INTEGER / exponentMultiplier;
  const maxValue = Number.MAX_SAFE_INTEGER / exponentMultiplier;

  const getOperationType = (lowerLimit?: number, upperLimit?: number): FilterOperationType => {
    if (lowerLimit === undefined) {
      return FilterOperationType.Lte;
    }
    if (upperLimit === undefined) {
      return FilterOperationType.Gte;
    }
    return FilterOperationType.Between;
  }

  const onLimitChange = useCallback((newLower?: number, newUpper?: number) => {
    setState({
      ...state,
      lowerLimit: newLower,
      upperLimit: newUpper
    });
    onSubmit({ ...filter, lowerLimit: newLower, upperLimit: newUpper, operationType: getOperationType(newLower, newUpper) });
  }, [setState, onSubmit, state]);

  const onLowerChange = useCallback((event?: React.SyntheticEvent<HTMLElement>, newLower?: number) => {
    const newUpper = newLower !== undefined && state?.upperLimit != undefined && state.upperLimit < newLower
      ? newLower
      : state?.upperLimit;
      onLimitChange(newLower, newUpper);
  }, [onLimitChange]);

  const onUpperChange = useCallback((event?: React.SyntheticEvent<HTMLElement>, newUpper?: number) => {
    const newLower = newUpper !== undefined && state?.lowerLimit != undefined && state.lowerLimit > newUpper
    ? newUpper
    : state?.lowerLimit;
    onLimitChange(newLower, newUpper);
  }, [onLimitChange]);

  const onLowerChangeString = useCallback((event?: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    const newLowerLimitNormalized = newValue === undefined ? undefined : parseFloat(newValue) * exponentMultiplier;
    onLowerChange(event, newLowerLimitNormalized);
  }, [onLowerChange]);

  const onUpperChangeString = useCallback((event?: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    const newUpperLimitNormalized = newValue === undefined ? undefined : parseFloat(newValue) * exponentMultiplier;
    onUpperChange(event, newUpperLimitNormalized);
  }, [onUpperChange]);

  const lowerLimitDisplay = (state?.lowerLimit === undefined ? undefined : state?.lowerLimit / exponentMultiplier)?.toString()?.concat(field.suffix ?? "") ?? '';
  const upperLimitDisplay = (state?.upperLimit === undefined ? undefined : state?.upperLimit / exponentMultiplier)?.toString()?.concat(field.suffix ?? "") ?? '';

  return (
    <Stack tokens={{ childrenGap: 10 }} horizontal>
      <SpinButton
        labelPosition={Position.top}
        label={t("content.filters.number.from", { field: field.displayName ?? field.name})}
        onChange={onLowerChangeString}
        onValidate={(value) => {
          if (value.trim() === '') {
            onLowerChange(undefined, undefined);
          }
          const parsed = parseFloat(value) * exponentMultiplier;
          if (!Number.isNaN(parsed) && Number.isSafeInteger(parsed)) {
            return value;
          }
        }}
        value={lowerLimitDisplay}
        onIncrement={() => onLowerChange(undefined, (state?.lowerLimit ?? 0) + 1)}
        onDecrement={() => onLowerChange(undefined, (state?.lowerLimit ?? 0) - 1)}
        min={minValue}
        max={maxValue}
        step={step}
      />
      <SpinButton
        labelPosition={Position.top}
        label={t("content.filters.number.to", { field: field.displayName ?? field.name})}
        onChange={onUpperChangeString}
        onValidate={(value) => {
          if (value.trim() === '') {
            onUpperChange(undefined, undefined);
          }
          const parsed = parseFloat(value) * exponentMultiplier;
          if (!Number.isNaN(parsed) && Number.isSafeInteger(parsed)) {
            return value;
          }
        }}
        value={upperLimitDisplay}
        onIncrement={() => onUpperChange(undefined, (state?.upperLimit ?? 0) + 1)}
        onDecrement={() => onUpperChange(undefined, (state?.upperLimit ?? 0) - 1)}
        min={minValue}
        max={maxValue}
        step={step}
      />
    </Stack>
  );
}
