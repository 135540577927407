import { EnumField, EnumValue, FieldType, MoneyField, MoneyValue, NumberField, NumberValue } from '@notidar/api'
import { AllFields } from '../utils'
import { AllValues } from '../sections/Utils'
import { MoneyValueComponent } from './money/MoneyValueComponent'
import { NumberValueComponent } from './number/NumberValueComponent'
import { EnumValueComponent } from './enum/EnumValueComponent'

export interface ValueComponentProps {
  field: AllFields
  value: AllValues
}

export const ValueComponent = ({ field, value }: ValueComponentProps): JSX.Element | null => {
  switch (field.type) {
    case FieldType.Money:
      return <MoneyValueComponent field={field as MoneyField} value={value as MoneyValue} />
    case FieldType.Number:
      return <NumberValueComponent field={field as NumberField} value={value as NumberValue} />
    case FieldType.Enum:
      return <EnumValueComponent field={field as EnumField} value={value as EnumValue} />
    default:
      return null
  }
}
