import { mergeStyleSets } from '@fluentui/react'
import { User } from '@notidar/api'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
    title: {
      margin: 10,
    },
  })
}

export interface UserInfoProps {
  user: User
}

export const UserInfo: React.FC<UserInfoProps> = ({ user }: UserInfoProps) => {
  const classNames = getClassNames()
  const { t } = useTranslation()

  const status = t('pages.user.user_status_active')

  return (
    <>
      <h2 className={classNames.title}>{t('pages.user.user_title')}</h2>
      <div className={classNames.container}>
        <p>{t('pages.user.user_email', { email: user.email })}</p>
        <p>{t('pages.user.user_status', { status })}</p>
      </div>
    </>
  )
}
