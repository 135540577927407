import { Channel, Post } from '@notidar/api'
import { FeedProps } from './Feed.types'
import { FontIcon, MessageBar, MessageBarType, Spinner, SpinnerSize, Stack, mergeStyleSets } from '@fluentui/react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { PostCard } from '../cards/PostCard'
import { PostContentContext } from '../context/postContentContext'
import { AppContentContext } from '../context/appContentContext'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    card: {
      paddingBottom: 10,
    },
    scrollDown: {
      height: 30,
      width: 30,
      fontSize: 30,
      margin: '10px',
    }
  })
}

export interface InfiniteListFeedProps extends FeedProps {
  loadMore: () => void
  refresh: () => void
  hasMore: boolean
  channel: Channel
  posts: Post[]
}

export const InfiniteListFeed = ({
  loadMore,
  refresh,
  hasMore,
  posts,
  channel,
  enableManagement,
  navigate,
}: InfiniteListFeedProps) => {
  const classNames = getClassNames()
  const { t } = useTranslation();
  const { screenType } = useContext(AppContentContext)

  return (
    <InfiniteScroll
      key={screenType}
      dataLength={posts.length}
      next={loadMore}
      refreshFunction={refresh}
      hasMore={hasMore}
      loader={<Spinner label={t('shared.loading')} size={SpinnerSize.large} />}
      pullDownToRefresh={screenType === 'mobile'}
      pullDownToRefreshThreshold={60}
      pullDownToRefreshContent={
        <Stack horizontalAlign='center'>
          <FontIcon iconName='ChevronDownSmall' className={classNames.scrollDown} />
        </Stack>
      }
      releaseToRefreshContent={
        <Stack horizontalAlign='center'>
          <Spinner size={SpinnerSize.large} />
        </Stack>
      }
      endMessage={
        <Stack horizontalAlign='center'>
          <MessageBar messageBarType={MessageBarType.info}>
            {posts.length > 0 ? t('pages.feed.no_more_posts') : t('pages.feed.no_posts')}
          </MessageBar>
        </Stack>
      }
      scrollableTarget={'scrollDiv'}
      style={{ overflow: 'hidden' }}
    >
      {posts.map((post, index) => (
        <div key={index} className={classNames.card}>
          <PostContentContext.Provider value={{ channelId: channel.channelId, postId: post.postId }}>
            <PostCard
              key={index}
              post={post}
              channel={channel}
              enableManagement={enableManagement}
              navigate={navigate}
            />
          </PostContentContext.Provider>
        </div>
      ))}
    </InfiniteScroll>
  )
}
