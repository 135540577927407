import { Card, CardList } from '../../App/Card'
import { UserInfo } from './UserInfo/UserInfo'
import { AccountInfo } from './AccountInfo/AccountInfo'
import { useAccount } from '@azure/msal-react'
import { useAppSelector } from '../../../redux/hooks'
import { selectUserState } from '../../../redux/features/userSlice'
import { AuthRequiredCard } from '../AuthRequiredCard'
import { Spinner, SpinnerSize } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

export const UserView = () => {
  let account = useAccount()
  const { status: userStatus, user } = useAppSelector(selectUserState)
  const { t } = useTranslation()

  if (!account) {
    return (
      <CardList>
        <AuthRequiredCard />
      </CardList>
    )
  }

  return (
    <CardList>
      <Card>
        {!user || userStatus === 'loading' ? (
          <Spinner style={{ margin: 10 }} label={t('shared.loading')} size={SpinnerSize.large} />
        ) : (
          <>
            <UserInfo user={user} />
            <AccountInfo user={user} />
          </>
        )}
      </Card>
    </CardList>
  )
}
