import {
  Dropdown,
  IDropdownOption,
  ITheme,
  PrimaryButton,
  Stack,
  mergeStyleSets,
  useTheme,
} from '@fluentui/react'
import { FieldType, Filter, FilterOperationType, FilterType } from '@notidar/api'
import { useState } from 'react'
import { allowedFilterTypes } from '@notidar/content'

const defaultFilterMap: Record<FilterType, Omit<Filter, "type" | "field" | "fieldType">> = {
  [FilterType.Text]: { operationType: FilterOperationType.TextSearch },
  [FilterType.Enum]: { operationType: FilterOperationType.AllIn },
  [FilterType.Number]: { operationType: FilterOperationType.Between },
  [FilterType.Money]: { operationType: FilterOperationType.Between },
  [FilterType.Unknown]: { operationType: FilterOperationType.Unknown }
}

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    button: {
      margin: '0 10px 0 0',
    },
    container: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      padding: '10px 10px 0',
    },
  })
}

export interface NewFilterComponentProps {
  onAdd: (filter: Filter) => void
}

export const NewFilterComponent = ({ onAdd }: NewFilterComponentProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme)
  const [newFilter, setNewFilter] = useState<{
    type?: FilterType | null
    isTypeValid: boolean
  }>({
    isTypeValid: false,
  })

  const addNewFilter = (): void => {
    const filterToCreate: Filter = {
      type: newFilter.type!,
      field: "",//TODO
      fieldType: FieldType.Unknown,//TODO
      ...defaultFilterMap[newFilter.type!],
    }
    onAdd(filterToCreate)
    setNewFilter(x => ({ isTypeValid: false, type: null }))
  }

  const newFilterTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    const type = option?.key as FilterType | undefined
    setNewFilter({ ...newFilter, type: type, isTypeValid: type !== undefined })
  }

  return (
    <Stack className={classNames.container}>
      <Stack horizontal>
        <PrimaryButton
          disabled={!(newFilter.isTypeValid)}
          className={classNames.button}
          onClick={addNewFilter}
          text='Add'
        />
        <Dropdown
          selectedKey={newFilter.type}
          className={classNames.button}
          onChange={newFilterTypeChange}
          placeholder='Select filter type'
          options={allowedFilterTypes}
        />
      </Stack>
    </Stack>
  )
}
