import { Label, List, mergeStyleSets, Text } from '@fluentui/react'
import { Field, PropertiesPostSectionItem } from '@notidar/api'
import { PropertyListItem } from './PropertyItem'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    message: {
      margin: '5px 0 5px',
      color: 'rgb(164, 38, 44)',
    },
  })
}

export interface PropertyListProps {
  fields: Field[]
  items: PropertiesPostSectionItem[]
  onRemove: (index: number) => void
}

export const PropertyList = ({ items, fields, onRemove }: PropertyListProps) => {
  const { t } = useTranslation();
  const classNames = getClassNames()

  const onRenderCell = (
    item?: PropertiesPostSectionItem,
    index?: number,
    _?: boolean
  ): JSX.Element | null => {
    if (!item || index === undefined) {
      return null
    }

    return <PropertyListItem key={index} field={fields.find(x => x.name === item.propertyField)} item={item} onRemove={() => onRemove(index)} />
  }

  if (items.length === 0) {
    return (
      <>
        <Label>{t("content.sections.properties.properties_title")}</Label>
        <Text nowrap block className={classNames.message}>
          {t("content.sections.properties.properties_not_available")}
        </Text>
      </>
    )
  }

  return (
    <>
      <Label>{t("content.sections.properties.properties_title")}</Label>
      <List items={items} onRenderCell={onRenderCell} />
    </>
  )
}
