import { DefaultButton } from '@fluentui/react'
import { useOnClickNavigate } from '../../hooks';
import { MessageCard } from './MessageCard';

export interface NavigateMessageCardProps {
  title: string,
  description: string,
  navigateText: string,
  navigateLink: string,
}

export const NavigateMessageCard = ({ title, description, navigateText, navigateLink }: NavigateMessageCardProps) => {
  const onClickNavigate = useOnClickNavigate()

  return (
    <MessageCard title={title} description={description}>
      <DefaultButton onClick={onClickNavigate(navigateLink)} text={navigateText} />
    </MessageCard>
  )
}
