import { ChannelIdentifier } from '../../../redux/models'
import { PrimaryButton, Stack, mergeStyleSets, Separator } from '@fluentui/react'
import { useState } from 'react'
import { ApiClient } from '../../../api'
import { useNavigate } from '../../../hooks'
import { Field, Filter } from '@notidar/api'
import { AllFilters } from '@notidar/content/src/utils'
import { FilterEditorComponent } from '@notidar/content'
import { NewFilterComponent } from './NewFilterComponent'

const getClassNames = () => {
  return mergeStyleSets({
    button: {
      margin: '0 10px 0 0',
    },
    footer: {
      margin: '0 10px 10px',
    },
  })
}

export interface FilterListProps {
  existingFilters: Filter[]
  existingFields: Field[]
  channelIdentifier: ChannelIdentifier
}

export const FilterList: React.FC<FilterListProps> = ({
  existingFilters,
  existingFields,
  channelIdentifier,
}: FilterListProps) => {
  const classNames = getClassNames()
  const navigate = useNavigate()

  const [filters, setFilters] = useState<{ filter: Filter; isValid?: boolean }[]>(
    existingFilters.map(x => ({ filter: x, isValid: true }))
  )

  const addNewFilter = (filter: Filter): void => {
    setFilters([...filters, { filter: { ...filter } }])
  }

  const onFilterRemove = (index: number): void => {
    const newFilters = [...filters]
    newFilters.splice(index, 1)
    setFilters(newFilters)
  }

  const onFilterUpdate = (index: number, filter: Filter, isValid: boolean): void => {
    const newFilters = [...filters]
    newFilters[index].filter = filter
    newFilters[index].isValid = isValid
    setFilters(newFilters)
  }

  const onReset = (): void => {
    setFilters(existingFilters.map(x => ({ filter: x, isValid: true })))
  }

  const onChannelFilterUpdate = async (): Promise<void> => {
    try {
      await ApiClient.updateChannelFiltersAsync(channelIdentifier, {
        filters: filters.map(x => x.filter) as AllFilters[],
      })
      navigate(`/manage/channel/${channelIdentifier}`)
    } catch (e) { }
  }

  return (
    <>
      {filters.map((x, index) => (
        <FilterEditorComponent
          key={index}
          filter={x.filter}
          fields={existingFields}
          onRemove={() => onFilterRemove(index)}
          onUpdate={(filter, isValid) => onFilterUpdate(index, filter, isValid)}
        />
      ))}
      <NewFilterComponent onAdd={addNewFilter} />
      <Separator />
      <Stack className={classNames.footer} horizontal>
        <PrimaryButton
          disabled={filters.some(x => !x.isValid)}
          className={classNames.button}
          onClick={onChannelFilterUpdate}
        >
          Save
        </PrimaryButton>
        <PrimaryButton className={classNames.button} onClick={onReset}>
          Reset
        </PrimaryButton>
      </Stack>
    </>
  )
}
