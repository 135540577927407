import { ChoiceGroup, IChoiceGroupOption, MessageBar, MessageBarType, Stack } from '@fluentui/react'
import { NotificationLevel } from '@notidar/api'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export interface SubscriptionNotificationEditComponentProps {
  selectedNotificationLevel: NotificationLevel
  onChange: (notificationLevel: NotificationLevel) => void
  loading?: boolean
}

export const SubscriptionNotificationEditComponent = ({ onChange, selectedNotificationLevel, loading }: SubscriptionNotificationEditComponentProps) => {
  const { t } = useTranslation();

  const options: IChoiceGroupOption[] = [
    { key: NotificationLevel.None, text: t("types.notification_level.none") },
    { key: NotificationLevel.HighImportance, text: t("types.notification_level.high_importance") },
    { key: NotificationLevel.All, text: t("types.notification_level.all") },
  ];

  const onChangeInternal = useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    const selectedKey = option?.key as NotificationLevel;
    if (selectedKey) {
      onChange(selectedKey);
    }
  }, [onChange]);

  return (
    <Stack verticalAlign='space-between'>
      <Stack tokens={{ childrenGap: 10 }}>
        <ChoiceGroup disabled={loading} selectedKey={selectedNotificationLevel} options={options} onChange={onChangeInternal} label={t('right_bar.notifications.label')} />
        <MessageBar messageBarType={MessageBarType.info}>
          {t('right_bar.notifications.note')}
        </MessageBar>
      </Stack>
    </Stack>
  )
}
