import {
  ActionsPostSection,
  AuthorPostSection,
  Channel,
  GalleryPostSection,
  MarkdownPostSection,
  Post,
  PostSectionType,
  PropertiesPostSection,
  TagsPostSection,
  TitlePostSection,
} from '@notidar/api'
import { notEmpty } from '@notidar/common'
import { TitleSectionComponent } from '../sections/title'
import { ActionsSectionComponent } from '../sections/actions/ActionsSectionComponent'
import { TagsSectionComponent } from '../sections/tags/TagsSectionComponent'
import { GallerySectionComponent } from '../sections/gallery/GallerySectionComponent'
import { MarkdownSectionComponent } from '../sections/markdown/MarkdownSectionComponent'
import { AuthorSectionComponent } from '../sections/author/AuthorSectionComponent'
import { ManageSectionComponent } from '../sections/manage/ManageSectionComponent'
import { CardContainer } from './CardContainer'
import { PropertiesSectionComponent } from '../sections'

export interface PostCardProps {
  channel: Channel
  post: Post
  enableManagement?: boolean
  navigate: (path: string) => void
}

export const PostCard = ({ post, channel, enableManagement, navigate }: PostCardProps) => {
  const sections: JSX.Element[] = channel.postSections
    .map((section, index) => {
      switch (section.type) {
        case PostSectionType.Title: {
          return (
            <TitleSectionComponent
              section={section as TitlePostSection}
              postValues={post.values}
              channelValues={{}}
              fields={channel.fields ?? {}}
              key={index}
            />
          )
        }
        case PostSectionType.Author: {
          return (
            <AuthorSectionComponent
              section={section as AuthorPostSection}
              postValues={post.values}
              channelValues={{}}
              fields={channel.fields ?? {}}
              key={index}
            />
          )
        }
        case PostSectionType.Gallery: {
          return (
            <GallerySectionComponent
              section={section as GalleryPostSection}
              postValues={post.values}
              channelValues={{}}
              fields={channel.fields ?? {}}
              key={index}
            />
          )
        }
        case PostSectionType.Markdown: {
          return (
            <MarkdownSectionComponent
              section={section as MarkdownPostSection}
              postValues={post.values}
              channelValues={{}}
              fields={channel.fields ?? {}}
              key={index}
            />
          )
        }
        case PostSectionType.Properties: {
          return (
            <PropertiesSectionComponent
              section={section as PropertiesPostSection}
              postValues={post.values}
              channelValues={{}}
              fields={channel.fields ?? {}}
              key={index}
            />
          )
        }
        case PostSectionType.Tags: {
          return (
            <TagsSectionComponent
              section={section as TagsPostSection}
              postValues={post.values}
              channelValues={{}}
              fields={channel.fields ?? {}}
              key={index}
            />
          )
        }
        case PostSectionType.Actions: {
          return (
            <ActionsSectionComponent
              section={section as ActionsPostSection}
              postValues={post.values}
              channelValues={{}}
              fields={channel.fields ?? {}}
              key={index}
            />
          )
        }
        default: {
          return null
        }
      }
    })
    .filter(notEmpty)

  if (enableManagement && post.postId) {
    sections.push(
      <ManageSectionComponent
        key={sections.length}
        channelIdentifier={channel.name}
        postId={post.postId}
        navigate={navigate}
      />,
    )
  }

  return <CardContainer>{sections}</CardContainer>
}
