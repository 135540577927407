import { PrimaryButton, Separator, Stack, mergeStyleSets } from '@fluentui/react'
import { ChannelIdentifier } from '../../../redux/models'
import { Fragment, useState } from 'react'
import { ApiClient } from '../../../api'
import { useNavigate } from '../../../hooks'
import {
  ActionsPostSection,
  AuthorPostSection,
  Field,
  GalleryPostSection,
  MarkdownPostSection,
  PropertiesPostSection,
  PostSection,
  PostSectionType,
  TagsPostSection,
  TitlePostSection,
  PropertiesPostSectionType,
} from '@notidar/api'
import { AllPostSections } from '@notidar/content/src/sections/Utils'
import { SectionEditorComponent } from '@notidar/content/src/sections/SectionEditorComponent'
import { NewSectionComponent } from '@notidar/content/src/sections/NewSectionComponent'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    button: {
      margin: '0 10px 0 0',
    },
    footer: {
      margin: '0 10px 10px',
    },
  })
}

const defaultSectionProps: Record<PostSectionType, AllPostSections | undefined> = {
  [PostSectionType.Title]: { type: PostSectionType.Title } as TitlePostSection,
  [PostSectionType.Tags]: { type: PostSectionType.Tags } as TagsPostSection,
  [PostSectionType.Markdown]: {
    type: PostSectionType.Markdown,
  } as MarkdownPostSection,
  [PostSectionType.Actions]: {
    type: PostSectionType.Actions,
  } as ActionsPostSection,
  [PostSectionType.Gallery]: {
    type: PostSectionType.Gallery,
  } as GalleryPostSection,
  [PostSectionType.Author]: { type: PostSectionType.Author } as AuthorPostSection,
  [PostSectionType.Properties]: {
    type: PostSectionType.Properties,
    displayType: PropertiesPostSectionType.Names,
  } as PropertiesPostSection,
}

export interface SectionListProps {
  existingSections: PostSection[]
  fields: Field[]
  channelIdentifier: ChannelIdentifier
}

export const SectionList: React.FC<SectionListProps> = ({
  existingSections,
  fields,
  channelIdentifier,
}: SectionListProps) => {
  const { t } = useTranslation();
  const classNames = getClassNames()
  const navigate = useNavigate()
  const [version, setVersion] = useState(0);
  const [sections, setSections] = useState<{ section: PostSection; isValid?: boolean }[]>(
    existingSections.map(x => ({ section: x, isValid: true })),
  )

  const addNewSection = (type: PostSectionType): void => {
    setSections([...sections, { section: { ...defaultSectionProps[type] } as PostSection }])
  }

  const onSectionRemove = (index: number): void => {
    setSections(sections.filter((_, i) => i !== index))
  }

  const onSectionUpdate = (index: number, section: PostSection, isValid: boolean): void => {
    const newSections = [...sections]
    newSections[index].section = section
    newSections[index].isValid = isValid
    setSections(newSections)
  }

  const onReset = (): void => {
    setSections(existingSections.map(x => ({ section: x, isValid: true })))
    setVersion(version + 1);
  }

  const onChannelSectionsUpdate = async (): Promise<void> => {
    try {
      await ApiClient.updateChannelPostSectionsAsync(channelIdentifier, {
        postSections: sections.map(x => x.section) as AllPostSections[],
      })
      navigate(`/manage/channel/${channelIdentifier}`)
    } catch (e) { }
  }

  return (
    <>
      <Fragment key={version}>
        {sections.map((section, index) => (
          <SectionEditorComponent
            key={index}
            section={section.section}
            fields={fields}
            onRemove={() => onSectionRemove(index)}
            onUpdate={(section, isValid) => onSectionUpdate(index, section, isValid)}
          />
        ))}
      </Fragment>
      <NewSectionComponent onAdd={addNewSection} />
      <Separator />
      <Stack className={classNames.footer} horizontal>
        <PrimaryButton
          disabled={sections.some(x => !x.isValid)}
          className={classNames.button}
          onClick={onChannelSectionsUpdate}
          text={t('pages.manage_sections.save')}
        />
        <PrimaryButton 
          className={classNames.button}
          onClick={onReset}
          text={t('pages.manage_sections.reset')}
        />
      </Stack>
    </>
  )
}
