import { Position, SpinButton, Stack } from '@fluentui/react'
import { useCallback, useContext, useMemo, useState } from 'react'
import { AllFields } from '../../../utils'
import { FilterOperationType, MoneyField, MoneyFilter } from '@notidar/api'
import { useTranslation } from 'react-i18next'
import { currencyDataMap } from '../../../fields/money/MoneyValueComponent'
import { CurrencyConversionContext } from '../../../fields/money/CurrencyConversionContext'

export interface MoneyFilterComponentProps {
  filter: MoneyFilter
  fields: AllFields[]
  onSubmit: (filter: MoneyFilter) => void
}

export enum RoundingType {
  Round,
  Floor,
  Ceil
}

export const MoneyFilterComponent = ({ filter, fields, onSubmit }: MoneyFilterComponentProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { targetCurrency, convertMoneyTo, convertMoneyFrom } = useContext(CurrencyConversionContext);
  const [state, setState] = useState<{ lowerLimit?: number, upperLimit?: number } | undefined | null>({ lowerLimit: filter.lowerLimit ?? undefined, upperLimit: filter.upperLimit ?? undefined });
  const field = useMemo(() => fields.find(y => y.name === filter.field) as MoneyField, [filter, fields]);

  const fieldCurrencyData = currencyDataMap[field.currency];
  const targetCurrencyData = currencyDataMap[targetCurrency ?? field.currency];
  const fieldCurrencyExponentMultiplier = Math.pow(10, fieldCurrencyData.exponent);
  const targetCurrencyExponentMultiplier = Math.pow(10, targetCurrencyData.exponent);

  const getOperationType = (lowerLimit?: number, upperLimit?: number): FilterOperationType => {
    if (lowerLimit === undefined) {
      return FilterOperationType.Lte;
    }
    if (upperLimit === undefined) {
      return FilterOperationType.Gte;
    }
    return FilterOperationType.Between;
  }

  const round = (value: number, roundingType?: RoundingType): number => {
    switch (roundingType ?? RoundingType.Round) {
      case RoundingType.Round:
        return Math.round(value);
      case RoundingType.Floor:
        return Math.floor(value);
      case RoundingType.Ceil:
        return Math.ceil(value);
    }
  }

  const onLimitChange = useCallback((newLower?: { value?: number, roundingType?: RoundingType }, newUpper?: { value?: number, roundingType?: RoundingType }) => {
    let convertedNewLower = newLower !== undefined
      ? (convertMoneyTo && newLower?.value !== undefined 
        ? round(convertMoneyTo(field.currency, newLower.value), newLower.roundingType)
        : newLower?.value) 
      : state?.lowerLimit;

    let convertedNewUpper = newUpper !== undefined
      ? (convertMoneyTo && newUpper?.value !== undefined 
        ? round(convertMoneyTo(field.currency, newUpper.value), newUpper.roundingType)
        : newUpper?.value) 
      : state?.upperLimit;
    
    if (convertedNewLower !== undefined && convertedNewUpper !== undefined) {
      if(convertedNewLower > convertedNewUpper) {
        if(newLower !== undefined) {
          convertedNewUpper = convertedNewLower;
        } else {
          convertedNewLower = convertedNewUpper;
        }
      }
    }

    setState({
      ...state,
      lowerLimit: convertedNewLower,
      upperLimit: convertedNewUpper
    });
    onSubmit({ ...filter, lowerLimit: convertedNewLower, upperLimit: convertedNewUpper, operationType: getOperationType(convertedNewLower, convertedNewUpper) });
  }, [setState, onSubmit, state]);

  const onLowerChange = useCallback((event?: React.SyntheticEvent<HTMLElement>, value?: number, roundingType?: RoundingType) => {
    onLimitChange({ value, roundingType }, undefined);
  }, [onLimitChange]);

  const onUpperChange = useCallback((event?: React.SyntheticEvent<HTMLElement>, value?: number, roundingType?: RoundingType) => {
    onLimitChange(undefined, { value, roundingType });
  }, [onLimitChange]);

  const onLowerChangeString = useCallback((event?: React.SyntheticEvent<HTMLElement>, newValue?: string, delta?: -1 | 1) => {
    const newLowerLimitNormalized = newValue === undefined ? undefined : Math.trunc(Math.round(parseFloat(newValue) * targetCurrencyExponentMultiplier) + (delta ?? 0));
    const roundingType = delta === undefined ? RoundingType.Round : delta > 0 ? RoundingType.Ceil : RoundingType.Floor;

    onLowerChange(event, newLowerLimitNormalized, roundingType);
  }, [onLowerChange]);

  const onUpperChangeString = useCallback((event?: React.SyntheticEvent<HTMLElement>, newValue?: string, delta?: -1 | 1) => {
    const newUpperLimitNormalized = newValue === undefined ? undefined : Math.trunc(Math.round(parseFloat(newValue) * targetCurrencyExponentMultiplier) + (delta ?? 0));
    const roundingType = delta === undefined ? RoundingType.Round : delta > 0 ? RoundingType.Ceil : RoundingType.Floor;
    onUpperChange(event, newUpperLimitNormalized, roundingType);
  }, [onUpperChange]);

  const lowerLimitDisplay = state?.lowerLimit === undefined
    ? undefined
    : convertMoneyFrom
      ? Math.round(convertMoneyFrom(field.currency, state.lowerLimit)) / targetCurrencyExponentMultiplier
      : state.lowerLimit / fieldCurrencyExponentMultiplier;

  const upperLimitDisplay = state?.upperLimit === undefined
    ? undefined
    : convertMoneyFrom
      ? Math.round(convertMoneyFrom(field.currency, state.upperLimit)) / targetCurrencyExponentMultiplier
      : state.upperLimit / fieldCurrencyExponentMultiplier;

  const lowerLimitDisplayString = lowerLimitDisplay?.toString()?.concat(targetCurrencyData.symbol) ?? '';
  const upperLimitDisplayString = upperLimitDisplay?.toString()?.concat(targetCurrencyData.symbol) ?? '';

  return (
    <Stack tokens={{ childrenGap: 10 }} horizontal>
      <SpinButton
        labelPosition={Position.top}
        label={t("content.filters.number.from", { field: field.displayName ?? field.name })}
        onChange={onLowerChangeString}
        onValidate={(value) => {
          if (value.trim() === '') {
            onLowerChange(undefined, undefined);
          }
          const parsed = parseFloat(value) * targetCurrencyExponentMultiplier;
          if (!Number.isNaN(parsed) && Number.isSafeInteger(parsed)) {
            return value;
          }
        }}
        value={lowerLimitDisplayString}
        onIncrement={(value, event) => onLowerChangeString(event, value ? value : '0', 1)}
        onDecrement={(value, event) => onLowerChangeString(event, value ? value : '0', -1)}
      />
      <SpinButton
        labelPosition={Position.top}
        label={t("content.filters.number.to", { field: field.displayName ?? field.name })}
        onChange={onUpperChangeString}
        onValidate={(value) => {
          if (value.trim() === '') {
            onUpperChange(undefined, undefined);
          }
          const parsed = parseFloat(value) * targetCurrencyExponentMultiplier;
          if (!Number.isNaN(parsed) && Number.isSafeInteger(parsed)) {
            return value;
          }
        }}
        value={upperLimitDisplayString}
        onIncrement={(value, event) => onUpperChangeString(event, value ? value : '0', 1)}
        onDecrement={(value, event) => onUpperChangeString(event, value ? value : '0', -1)}
      />
    </Stack>
  );
}
