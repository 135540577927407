import { Dropdown, IDropdownOption, mergeStyleSets } from '@fluentui/react'
import { CurrencyCode } from '@notidar/api'
import { useCallback, useMemo } from 'react'
import { ApiClient } from '../../../../api'
import { useAppDispatch } from '../../../../redux/hooks'
import { updateSettingsCurrency } from '../../../../redux/features/userSlice'
import { useTranslation } from 'react-i18next'

const defaultCurrencyCode = 'default'

const options = [
  { key: defaultCurrencyCode, text: 'Use channel currency', translationKey: 'content.fields.money.currency_name.default' },
  { key: CurrencyCode.USD, text: 'USD', translationKey: 'content.fields.money.currency_name.USD' },
  { key: CurrencyCode.EUR, text: 'EUR', translationKey: 'content.fields.money.currency_name.EUR' },
  { key: CurrencyCode.PLN, text: 'PLN', translationKey: 'content.fields.money.currency_name.PLN' },
  { key: CurrencyCode.RUB, text: 'RUB', translationKey: 'content.fields.money.currency_name.RUB' },
]

export const useLocalizedCurrencyOptions = () => {
  const { t } = useTranslation();
  const localizedFields = useMemo(() => options.map(({ key, text, translationKey }) => ({ key, text: t(translationKey) ?? text })), [t]);
  return localizedFields;
}

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
  })
}

export interface CurrencySettingsProps {
  currencyCode?: CurrencyCode
}

export const CurrencySettings: React.FC<CurrencySettingsProps> = ({ currencyCode }: CurrencySettingsProps) => {
  const classNames = getClassNames()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const allowedCurrencyOptions = useLocalizedCurrencyOptions();

  const onChange = useCallback(
    async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
      try {
        const value = option?.key as CurrencyCode | 'default';
        const desiredCurrencyCode = value === 'default' ? undefined : value;
        dispatch(updateSettingsCurrency(desiredCurrencyCode))
        await ApiClient.updateUserCurrencyAsync({ currencyCode: desiredCurrencyCode })
      } catch {
        // ignore
      }
    },
    [dispatch],
  )

  return (
    <div className={classNames.container}>
      <Dropdown
        label={t('pages.settings.currency_label')}
        selectedKey={currencyCode ?? defaultCurrencyCode}
        onChange={onChange}
        options={allowedCurrencyOptions}
      />
    </div>
  )
}
