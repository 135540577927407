import { Stack, DefaultButton, Separator } from '@fluentui/react'
import { ChannelIdentifier, PostId } from '../../models'
import { SectionContainer } from '../SectionContainer'
import { useTranslation } from 'react-i18next'

export interface ManageSectionComponentProps {
  channelIdentifier: ChannelIdentifier
  postId: PostId
  navigate: (path: string) => void
}

export const ManageSectionComponent = ({ channelIdentifier, postId, navigate }: ManageSectionComponentProps) => {
  const { t } = useTranslation()

  return (
    <SectionContainer>
      <Separator>{t('content.sections.shared.title')}</Separator>
      <Stack horizontal horizontalAlign='start' wrap tokens={{ childrenGap: 10 }}>
        <DefaultButton
          onClick={() => navigate(`/manage/channel/${channelIdentifier}/post/${postId}`)}
          text={t('content.sections.shared.edit')}
        />
      </Stack>
    </SectionContainer>
  )
}
