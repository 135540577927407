import { Stack, Toggle, mergeStyleSets } from '@fluentui/react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { selectAppState, setEnableManagement } from '../../redux/features/appSlice'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: '0 10px 10px',
    },
    sidebarContainer: {
      height: '100%',
    },
    centerManageToggle: {
      margin: "0 10px",
    },
  })
}

export const ChannelToggles = () => {
  const { t } = useTranslation()
  const classNames = getClassNames()
  const { enableManagement } = useAppSelector(selectAppState)
  const dispatch = useAppDispatch()

  return (
    <Stack verticalAlign='space-between' className={classNames.sidebarContainer}>
      <Stack>
        <Stack verticalAlign='center' horizontal className={classNames.centerManageToggle}>
          <Toggle
            label={t("right_bar.toggles.show_management_actions")}
            onText={t("shared.switch_on")}
            offText={t("shared.switch_off")}
            defaultChecked={enableManagement}
            onChange={(event, checked) => dispatch(setEnableManagement(checked ?? false))}
            role='checkbox'
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
