import { AccountInfo } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'

export const useOnSignOut = (account?: AccountInfo | null) => {
  const { instance } = useMsal()

  return () => {
    instance.logout({
      account: account,
    })
  }
}
