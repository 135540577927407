import {
  mergeStyleSets,
  Image,
  ImageCoverStyle,
  ImageFit,
  FontWeights,
  FontSizes,
  useTheme,
  ITheme,
  PersonaSize,
  PersonaCoin,
} from '@fluentui/react'
import { Channel } from '@notidar/api'
import { useOnClickNavigate } from '../../../../hooks'
import { useAppSelector } from '../../../../redux/hooks'
import { selectAppState } from '../../../../redux/features/appSlice'

const getClassNames = (theme: ITheme, isMobile: boolean) => {
  return mergeStyleSets({
    container: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: 10,
      //padding: "0 10px 10px",
      padding: 10,
      ':not(:last-child)': {
        borderBottom: '1px solid',
        borderColor: theme.palette.neutralQuaternary,
      },
      ':hover, :active, :active:hover': {
        background: theme.palette.neutralLight,
        cursor: 'pointer',
      },
      ':focus': {
        background: theme.palette.neutralLight,
        cursor: 'pointer',
      },
    },
    imageContainer: {
      //padding: "10px 0",
    },
    image: {
      flexGrow: 0,
      flexShrink: 0,
      width: isMobile ? 56 : 100,
      height: isMobile ? 56 : 100,
      borderRadius: isMobile ? 28 : 50,
      img: {
      },
    },
    infoContainer: {
      flexGrow: 4,
      overflow: 'hidden',
    },
    title: {
      margin: 0,
      fontSize: isMobile ? FontSizes.large : FontSizes.xLargePlus,
      fontWeight: FontWeights.semibold,
      padding: 0,
      color: theme.palette.neutralPrimary,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    description: {
      margin: 0,
      display: '-webkit-box',
      '-webkit-line-clamp': '3',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
}

export interface ChannelListItemProps {
  channel: Channel
}

export const ChannelListItem: React.FC<ChannelListItemProps> = ({ channel }: ChannelListItemProps) => {
  const theme = useTheme();
  const { screenType } = useAppSelector(selectAppState);
  const isMobile = screenType === 'mobile';
  const classNames = getClassNames(theme, isMobile);
  const onClickNavigate = useOnClickNavigate()

  const getImageComponent = () => {
    if (channel.imageUrl) {
      return (
        <Image
          className={classNames.image}
          imageFit={ImageFit.contain}
          coverStyle={ImageCoverStyle.portrait}
          src={channel.imageUrl}
        />
      )
    }
    if (channel.name.startsWith('rss.')) {
      return (
        <Image
          className={classNames.image}
          imageFit={ImageFit.contain}
          coverStyle={ImageCoverStyle.portrait}
          src={'/static-media/rss.png'}
        />
      )
    }

    return (
      <PersonaCoin
        {...{
          size: isMobile ? PersonaSize.size56 : PersonaSize.size100,
        }}
        hidePersonaDetails
        text={channel.title}
      />
    )
  }

  return (
    <div className={classNames.container} onClick={onClickNavigate(`/channel/${channel.name}`)}>
      <div className={classNames.imageContainer}>{getImageComponent()}</div>
      <div className={classNames.infoContainer}>
        <h2 className={classNames.title}>{channel.title}</h2>
        <p className={classNames.description}>{channel.description}</p>
      </div>
    </div>
  )
}
