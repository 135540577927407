import { CurrencyCode } from '@notidar/api';
import { createContext } from 'react';

export interface CurrencyConversionContextState {
  targetCurrency?: CurrencyCode
  convertMoneyFrom?: (currencyCode: CurrencyCode, value: number) => number
  convertMoneyTo?: (currencyCode: CurrencyCode, value: number) => number
}

export const CurrencyConversionContext = createContext<CurrencyConversionContextState>({
});