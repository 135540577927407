import {
  DateTimeField,
  DateTimeValue,
  FieldType,
  LinkField,
  LinkValue,
  MarkdownField,
  MarkdownValue,
  StringField,
  StringValue,
  ImageFileListField,
  ImageFileListValue,
  EnumValue,
  EnumListValue,
  EnumField,
  EnumListField,
  NumberValue,
  MoneyValue,
  NumberField,
  MoneyField,
} from '@notidar/api'
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const allowedFieldTypes = [
  { key: FieldType.String, text: 'String', translationKey: 'content.fields.string.name' },
  //{ key: FieldType.StringList, text: 'String list' },
  { key: FieldType.Number, text: 'Number', translationKey: 'content.fields.number.name' },
  { key: FieldType.Money, text: 'Money', translationKey: 'content.fields.money.name' },
  { key: FieldType.Link, text: 'Link', translationKey: 'content.fields.link.name' },
  { key: FieldType.DateTime, text: 'Date and time', translationKey: 'content.fields.datetime.name' },
  { key: FieldType.Markdown, text: 'Markdown', translationKey: 'content.fields.markdown.name' },
  { key: FieldType.ImageFileList, text: 'Image list', translationKey: 'content.fields.image.list_name' },
  { key: FieldType.Enum, text: 'Enum', translationKey: 'content.fields.enum.name' },
  { key: FieldType.EnumList, text: 'Enum list', translationKey: 'content.fields.enum.list_name' },
]

export const useLocalizedFieldTypes = () => {
  const { t } = useTranslation();
  const localizedTypes = useMemo(() => allowedFieldTypes.map(({ key, text, translationKey }) => ({ key, text: t(translationKey) ?? text })), [t]);
  return localizedTypes;
}

export interface FieldEditorComponentProps<TFieldType extends FieldTypeUnion> {
  field: FieldTypeToField<TFieldType>
  hidden: boolean
  onUpdate: (field: FieldTypeToField<TFieldType>, isValid: boolean) => void
}

export interface BaseValueEditorProps {}

export interface ValueEditorProps<TFieldType extends FieldTypeUnion> extends BaseValueEditorProps {
  field: FieldTypeToField<TFieldType>
  value?: Partial<FieldTypeToValue<TFieldType>>
  onUpdate: (field: FieldTypeToField<TFieldType>, value: FieldTypeToValue<TFieldType>) => void
  onValidation: (field: FieldTypeToField<TFieldType>, isValid: boolean) => void
}

export type FieldTypeUnion = `${FieldType}`

type FieldTypeToValue<T extends FieldTypeUnion> = T extends FieldType.String
  ? StringValue
  : T extends FieldType.Number
  ? NumberValue
  : T extends FieldType.Money
  ? MoneyValue
  : T extends FieldType.Markdown
  ? MarkdownValue
  : T extends FieldType.Link
  ? LinkValue
  : T extends FieldType.DateTime
  ? DateTimeValue
  : T extends FieldType.ImageFileList
  ? ImageFileListValue
  : T extends FieldType.Enum
  ? EnumValue
  : T extends FieldType.EnumList
  ? EnumListValue
  : never

type FieldTypeToField<T extends FieldTypeUnion> = T extends FieldType.String
  ? StringField
  : T extends FieldType.Number
  ? NumberField
  : T extends FieldType.Money
  ? MoneyField
  : T extends FieldType.Markdown
  ? MarkdownField
  : T extends FieldType.Link
  ? LinkField
  : T extends FieldType.DateTime
  ? DateTimeField
  : T extends FieldType.ImageFileList
  ? ImageFileListField
  : T extends FieldType.Enum
  ? EnumField
  : T extends FieldType.EnumList
  ? EnumListField
  : never
