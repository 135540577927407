import { useEffect } from 'react'

export const useExternalScripts = (url: string) => {
  useEffect(() => {
    const existingScript = document.querySelector(`script[src="${url}"]`)
    if (!existingScript) {
      const head = document.querySelector('head')
      const script = document.createElement('script')

      script.setAttribute('src', url)
      head?.appendChild(script)
    }
  }, [url])
}
