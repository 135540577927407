import { createContext } from 'react';
import { ChannelId, PostId } from '../models';

export interface PostContentContextState {
    postId?: PostId
    channelId?: ChannelId,
}

export const PostContentContext = createContext<PostContentContextState>({
    postId: undefined,
    channelId: undefined
});