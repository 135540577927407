import { useEffect, useState } from 'react'
import { Label, mergeStyleSets } from '@fluentui/react'
import PhotoSwipe from 'photoswipe'
import 'photoswipe/style.css'
import './custom-styles.css'
import { GalleryImage } from './GallerySectionTypes'

const getClassNames = (themeType?: "dark" | "light", screenType?: "mobile" | "desktop") => {
  return mergeStyleSets({
    container: {
      position: 'relative',
      overflow: 'hidden',
    },
    image: {
      display: 'block',
      opacity: 1,
      width: '100%',
      height: '100%',
      maxHeight: screenType == 'desktop' ? "512px" : "762px",
      objectFit: 'cover',
      filter: themeType === 'dark' ? 'brightness(0.5) contrast(1.1)' : 'none',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '10px',
      color: 'white',
      backdropFilter: 'blur(5px) brightness(40%)',
    },
  })
}

export interface ImageGallerySectionLightboxProps {
  galleryImages: GalleryImage[],
  themeType?: "dark" | "light",
  screenType?: "mobile" | "desktop",
}

export default function GallerySectionLightbox({ galleryImages, screenType, themeType }: ImageGallerySectionLightboxProps) {
  const classNames = getClassNames(themeType, screenType)
  const [lightbox, setLightbox] = useState<PhotoSwipe>()
  const [dataSource, setDataSource] = useState<GalleryImage[]>(galleryImages)

  useEffect(() => {
    return () => {
      lightbox?.destroy()
    }
  }, [lightbox])

  const onClick = (): void => {
    const localLightbox = new PhotoSwipe({
      dataSource: dataSource,
      showHideAnimationType: 'none',
      tapAction: 'close',
      doubleTapAction: false,
      returnFocus: true,
      pswpModule: () => import('photoswipe'),
    })
    setLightbox(localLightbox)

    localLightbox.on('gettingData', e => {
      if (!e.data.height && !e.data.width) {
        const image = new Image()
        image.onload = () => {
          if (e.slide) {
            e.slide.width = image.naturalWidth
            e.slide.height = image.naturalHeight
          }
          setDataSource(x => {
            const newArray = [...x]
            newArray[e.index] = {
              ...dataSource[e.index],
              width: image.naturalWidth,
              height: image.naturalHeight,
            }
            return newArray
          })
          lightbox?.updateSize(true)
        }

        image.src = e.data.src!
      }
    })

    localLightbox.on('close', () => {
      setLightbox(undefined)
    })

    localLightbox.init()
  }

  return (
    <div className={classNames.container}>
      {galleryImages.length > 1 && <Label className={classNames.overlay}>1/{galleryImages.length}</Label>}
      <img
        alt='Post related'
        className={classNames.image}
        src={galleryImages[0].optimizedSrc ?? galleryImages[0].src}
        onClick={onClick}
        loading='lazy'
        onLoad={e => {
          const image = e.target as HTMLImageElement
          setDataSource(x => {
            const newArray = [...x]
            newArray[0] = {
              ...dataSource[0],
              width: image.naturalWidth,
              height: image.naturalHeight,
            }
            return newArray
          })
        }}
      />
    </div>
  )
}
