import { FontSizes, Label, Spinner, SpinnerSize, Stack, mergeStyleSets } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { Connection, ConnectionStatus, ErrorCode, ErrorCodes, getErrorMessageCode } from '@notidar/api'
import { ApiClient } from '../../../../api'
import { useNavigate } from '../../../../hooks'
import { Card } from '../../../App/Card'

export interface RssConnectionProps {
  rssLink: string
}

type RssConnectionState = {
  rssLink?: string
  connection?: Connection
  isLoading: boolean
  errorCode?: ErrorCode
  loadAttempts: number
}

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 0,
    },
    header: {
      fontSize: FontSizes.large,
      textAlign: 'center',
    },
    paragraph: {
      margin: 10,
      textAlign: 'center',
    },
  })
}

export const RssConnection: React.FC<RssConnectionProps> = ({ rssLink }: RssConnectionProps) => {
  const { t } = useTranslation()
  const classNames = getClassNames()
  const navigate = useNavigate()
  const [state, setState] = useState<RssConnectionState>({
    isLoading: false,
    loadAttempts: 0,
  })

  const checkConnection = useCallback(
    async (connection: Connection, attempt?: number): Promise<void> => {
      try {
        const response = await ApiClient.getConnectionAsync(connection.connectionId)
        setState(x => ({ ...x, connection: response.data.connection, loadAttempts: x.loadAttempts + 1 }))
      } catch (e: any) {
        const errorCode = getErrorMessageCode(e)
        setState(x => ({ ...x, isLoading: false, errorCode }))
      }
    },
    [setState],
  )

  const createConnection = useCallback(
    async (rssLink: string): Promise<void> => {
      setState({ rssLink, isLoading: true, loadAttempts: 0 })
      try {
        const response = await ApiClient.createRssConnectionAsync({ link: rssLink })
        setState(x => ({ ...x, connection: response.data.connection, isLoading: false, errorCode: undefined }))
      } catch (e: any) {
        const errorCode = getErrorMessageCode(e)
        setState(x => ({ ...x, isLoading: false, errorCode }))
      }
    },
    [setState],
  )

  useEffect(() => {
    const connection = state.connection
    if(state.loadAttempts > 20) {
      setState(x => ({ ...x, isLoading: false, errorCode: ErrorCodes.CONNECTION_RSS_TAKES_TOO_LONG }))
    }
    if (connection) {
      const timer =
        connection.status === 'Available'
          ? setTimeout(() => navigate(`/channel/${connection.channelName}`), 1000)
          : setTimeout(() => checkConnection(connection, state.loadAttempts), 3000)
      return () => clearTimeout(timer)
    }
  }, [navigate, checkConnection, state.connection, state.loadAttempts])

  useEffect(() => {
    if (rssLink && rssLink !== state.rssLink) {
      createConnection(rssLink)
    }
  }, [createConnection, state.rssLink, rssLink])

  if (state.isLoading) {
    return (
      <Card>
        <Stack className={classNames.container}>
          <Label className={classNames.header}>{t('pages.search.rss_connecting')}</Label>
          <Spinner className={classNames.paragraph} size={SpinnerSize.large} />
        </Stack>
      </Card>
    )
  }

  if (state.errorCode) {
    return (
      <Card>
        <Stack className={classNames.container}>
          <Label className={classNames.header}>{t('shared.not_found')}</Label>
          <p className={classNames.paragraph}>{t(`shared.errors.${state.errorCode}`)}</p>
        </Stack>
      </Card>
    )
  }

  if (state.connection) {
    return (
      <Card>
        <Stack className={classNames.container}>
          <Label className={classNames.header}>
            {state.connection.status === ConnectionStatus.Initialization
              ? t('pages.search.rss_creating')
              : t('pages.search.rss_created')}
          </Label>
          <Spinner className={classNames.paragraph} size={SpinnerSize.large} />
        </Stack>
      </Card>
    )
  }

  return (
    <Card>
      <Stack className={classNames.container}>
        <p className={classNames.paragraph}>{t('pages.search.rss_description')}</p>
      </Stack>
    </Card>
  )
}
