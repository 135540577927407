import { mergeStyleSets } from '@fluentui/react'

const getClassNames = () => {
  return mergeStyleSets({
    header: {
      margin: 10,
    },
  })
}

export interface ChannelNameHeaderProps {
  name: string
}

export const ChannelNameHeader = ({ name }: ChannelNameHeaderProps) => {
  const classNames = getClassNames()
  return <h2 className={classNames.header}>{name}</h2>
}
