import { NavigateFunction, NavigateOptions, To, useNavigate as libUseNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../redux/hooks'
import { selectAppState, hideLeftSideBar } from '../redux/features/appSlice'

export interface OnClickNavigateFunction {
  (to: To, options?: NavigateOptions): (ev?: React.MouseEvent<any> | undefined) => void
  (delta: number): (ev?: React.MouseEvent<any> | undefined) => void
}

export const useMenuOnClickNavigate = (): OnClickNavigateFunction => {
  const navigate = libUseNavigate()
  const dispatch = useAppDispatch()
  const appState = useAppSelector(selectAppState)

  return (...args: any[]) => {
    return (ev?: React.MouseEvent<any> | undefined) => {
      navigate(args[0], args[1])
      if (appState.screenType === 'mobile' && appState.isLeftSideBarVisible) {
        dispatch(hideLeftSideBar())
      }
      ev?.preventDefault()
    }
  }
}

export const useOnClickNavigate = (): OnClickNavigateFunction => {
  const navigate = libUseNavigate()

  return (...args: any[]) => {
    return (ev?: React.MouseEvent<any> | undefined) => {
      navigate(args[0], args[1])
      ev?.preventDefault()
    }
  }
}

export const useNavigate = (): NavigateFunction => {
  return libUseNavigate()
}
