import { IconButton, Label, Stack } from '@fluentui/react'
import { ActionsPostSectionItem } from '@notidar/api'
import { useTranslation } from 'react-i18next'

export interface ActionItemProps {
  item: ActionsPostSectionItem
  onRemove: () => void
}

export const ActionItem = ({ item, onRemove }: ActionItemProps) => {
  const { t } = useTranslation();
  return (
    <Stack horizontal>
      <Label>{item.displayString ? `${item.displayString} (${item.actionField})` : `${item.actionField}`}</Label>
      <IconButton 
        iconProps={{ iconName: 'Cancel' }} 
        title={t("content.sections.actions.actions_remove")} 
        ariaLabel={t("content.sections.actions.actions_remove")} 
        onClick={onRemove}
      />
    </Stack>
  )
}
