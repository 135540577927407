import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../redux/hooks'
import { selectChannelState } from '../redux/features/channelSlice'

export const useIsChannelSelected = () => {
  const { channel, status } = useAppSelector(selectChannelState);
  const location = useLocation();
  if(status === 'loading') {
    return undefined;
  }
  return !!channel?.name && location.pathname.startsWith(`/channel/${channel.name}`);
}