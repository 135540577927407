import {
  ActionsPostSection,
  AuthorPostSection,
  Field,
  GalleryPostSection,
  MarkdownPostSection,
  PropertiesPostSection,
  PostSectionType,
  TagsPostSection,
  TitlePostSection,
  PostSection,
} from '@notidar/api'
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next'

export const allowedSectionTypes = [
  { key: PostSectionType.Title, text: 'Title section', translationKey: 'content.sections.title.name' },
  { key: PostSectionType.Markdown, text: 'Markdown section', translationKey: 'content.sections.markdown.name' },
  { key: PostSectionType.Gallery, text: 'Gallery section', translationKey: 'content.sections.gallery.name' },
  { key: PostSectionType.Properties, text: 'Properties section', translationKey: 'content.sections.properties.name' },
  { key: PostSectionType.Actions, text: 'Actions section', translationKey: 'content.sections.actions.name' },
  { key: PostSectionType.Author, text: 'Author section', translationKey: 'content.sections.author.name' },
  { key: PostSectionType.Tags, text: 'Tags section', translationKey: 'content.sections.tags.name' },
]

export const useLocalizedSectionTypes = () => {
  const { t } = useTranslation();
  const localizedFields = useMemo(() => allowedSectionTypes.map(({ key, text, translationKey }) => ({ key, text: t(translationKey) ?? text })), [t]);
  return localizedFields;
}

export interface SectionEditorComponentProps<TFieldType extends SectionTypeUnion> {
  fields: Field[]
  section: Partial<SectionTypeToSection<TFieldType>> & PostSection
  hidden: boolean
  onUpdate: (section: Partial<SectionTypeToSection<TFieldType>> & PostSection, isValid: boolean) => void
}

type SectionTypeToSection<T extends SectionTypeUnion> = T extends PostSectionType.Title
  ? TitlePostSection
  : T extends PostSectionType.Markdown
  ? MarkdownPostSection
  : T extends PostSectionType.Gallery
  ? GalleryPostSection
  : T extends PostSectionType.Actions
  ? ActionsPostSection
  : T extends PostSectionType.Tags
  ? TagsPostSection
  : T extends PostSectionType.Author
  ? AuthorPostSection
  : T extends PostSectionType.Properties
  ? PropertiesPostSection
  : never

type SectionTypeUnion = `${PostSectionType}`
