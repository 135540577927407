import { useEffect } from 'react'
import store from '../redux/store'
import { setSystemTheme } from '../redux/features/appSlice'
import { systemDarkThemeMediaQuery } from '../globals'

export const useSystemThemeDetection = () => {
  useEffect(() => {
    const type = 'change'
    const listener = (ev: MediaQueryListEvent) => {
      store.dispatch(setSystemTheme(ev.matches ? 'dark' : 'light'))
    }
    systemDarkThemeMediaQuery.addEventListener(type, listener)
    return () => systemDarkThemeMediaQuery.removeEventListener(type, listener) // cleanup
  }, [])
}
