import { Card, CardList } from '../../App/Card'
import { useParams } from 'react-router-dom'
import { Spinner } from '@fluentui/react'
import { ApiClient } from '../../../api'
import { useState, useEffect } from 'react'
import { useAppSelector } from '../../../redux/hooks'
import { selectUserChannelsState } from '../../../redux/features/userChannelsSlice'
import { ChannelIdentifier } from '../../../redux/models'
import { FieldList } from './FieldList'
import { Channel } from '@notidar/api'
import { ChannelNameHeader } from '../../Content/Shared/ChannelNameHeader'
import { useTranslation } from 'react-i18next'

export const ManageChannelFieldsView = () => {
  const { t } = useTranslation();
  const { channelIdentifier } = useParams<{ channelIdentifier: ChannelIdentifier }>()
  const { channels: userChannels } = useAppSelector(selectUserChannelsState)

  const [state, setState] = useState<{ channel?: Channel | null; isLoading: boolean }>({
    isLoading: true,
    channel: undefined,
  })

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiClient.getChannelAsync(channelIdentifier!, { secure: true });
      setState({ channel: response.data.channel, isLoading: false })
    }

    fetchData().catch(() => {
      setState({ channel: null, isLoading: false })
    })
  }, [channelIdentifier])

  const isManagedChannel =
    state.channel && userChannels?.findIndex(x => x.channelId === state.channel!.channelId) !== -1

  return (
    <CardList>
      <Card>
        {state.channel === undefined && <Spinner label={t('shared.loading')} />}
        {state.channel === null && <div>{t('pages.manage_fields.channel_not_found')}</div>}
        {state.channel && !isManagedChannel && <div>{t('pages.manage_fields.channel_not_allowed')}</div>}
        {state.channel && isManagedChannel && (
          <>
            <ChannelNameHeader name={t("pages.manage_fields.title", { channel: state.channel.name })}/>
            <FieldList
              channelIdentifier={channelIdentifier!}
              existingFields={state.channel.fields}
            />
          </>
        )}
      </Card>
    </CardList>
  )
}
