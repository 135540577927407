import { CurrencyCode, EnumField, EnumValue, MoneyField, MoneyValue } from '@notidar/api'

export interface EnumValueComponentProps {
  field: EnumField
  value: EnumValue
}

export const EnumValueComponent = ({ field, value }: EnumValueComponentProps): JSX.Element | null => {
  const enumId = value.enumPayload?.enumId;
  if (enumId === undefined) {
    return null
  }
  const enumValue = field.possibleValues.find(x => x.enumId === enumId);
  const displayValue = enumValue?.displayString;
  if (displayValue === undefined) {
    return null
  }
  return (
    <span>
      {displayValue}
    </span>
  )
}
