import { Stack, TextField } from '@fluentui/react'
import {
  FieldType,
  LinkField,
  LinkListField,
} from '@notidar/api'
import { FieldEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface LinkFieldEditorComponentProps
  extends FieldEditorComponentProps<FieldType.Link | FieldType.LinkList> {}

export const LinkFieldEditorComponent = ({
  field,
  hidden,
  onUpdate,
}: LinkFieldEditorComponentProps) => {
  const { t } = useTranslation();
  const [fieldState, setFieldState] = useState<LinkField | LinkListField>(field)

  const isValid = (field: LinkField | LinkListField): boolean => {
    return validateTitle(field.displayName ?? '') === ''
  }
  
  const validateTitle = (value: string): string => {
    if (value.length === 0) {
      return ''
    }
  
    if (value.length > 32) {
      return t("content.fields.shared.displayName_too_long");
    }
  
    return ''
  }

  useEffect(() => {
    onUpdate(fieldState, isValid(fieldState))
  }, [fieldState]) // eslint-disable-line react-hooks/exhaustive-deps

  const titleValueChanged = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const newField: LinkField | LinkListField = { ...fieldState, displayName: newValue }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  if (hidden) {
    return null
  }

  return (
    <Stack>
      <TextField
        label={t("content.fields.shared.displayName")}
        value={fieldState.displayName ?? ''}
        onChange={titleValueChanged}
        onGetErrorMessage={validateTitle}
        deferredValidationTime={500}
      />
    </Stack>
  )
}
