import { FontSizes, Label, Stack, mergeStyleSets } from '@fluentui/react'
import { Card } from '../App/Card';

const classNames = mergeStyleSets({
  container: {
    margin: 10,
  },
  header: {
    fontSize: FontSizes.xxLargePlus,
    textAlign: 'center',
  },
  paragraph: {
    margin: 10,
    textAlign: 'center',
  },
});

export interface MessageCardProps {
  title: string,
  description: string,
  children?: React.ReactNode
}

export const MessageCard = ({ title, description, children }: MessageCardProps) => {
  return (
    <Card>
      <Stack className={classNames.container}>
        <Label className={classNames.header}>{title}</Label>
        <p className={classNames.paragraph}>{description}</p>
        {children && <Stack.Item align='center'>
          {children}
        </Stack.Item>}
      </Stack>
    </Card>
  )
}
