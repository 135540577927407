import * as React from 'react'
import { mergeStyleSets } from '@fluentui/react'

const getClassNames = () => {
  return mergeStyleSets({
    innerContainer: {
      margin: '0 auto',
      maxWidth: 1024,
      minWidth: 260, // for Galaxy Fold 280 = 260 + 10 + 10
    },
    outerContainer: {
      padding: 10,
    },
  })
}

export interface CardListProps {
  children: React.ReactNode
}

export const CardList: React.FC<CardListProps> = ({ children }: CardListProps) => {
  const classNames = getClassNames()
  return (
    <div className={classNames.outerContainer}>
      <div className={classNames.innerContainer}>{children}</div>
    </div>
  )
}
