import { Dropdown, IDropdownOption, SearchBox } from '@fluentui/react'
import { useMemo, useState } from 'react'
import { AllFields } from '../../../utils'
import { EnumField, EnumFilter, EnumListField } from '@notidar/api'
import { useTranslation } from 'react-i18next'

export interface EnumFilterComponentProps {
  filter: EnumFilter
  fields: AllFields[]
  onSubmit: (filter: EnumFilter) => void
}

export const EnumFilterComponent = ({ filter, fields, onSubmit }: EnumFilterComponentProps): JSX.Element | null => {
  const { t } = useTranslation(); 
  const [state, setState] = useState<string[] | undefined | null>(filter.enumIds);
  const field = useMemo(() => fields.find(y => y.name === filter.field) as EnumField | EnumListField, [filter, fields]);
  const options = useMemo(() => field.possibleValues.map(x => ({ key: x.enumId, text: x.displayString })), [field]);

  const onChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
    const newState = [...state ?? []];
    if (option) {
      if (option.selected) {
        newState.push(option.key as string);
      } else {
        const index = newState.indexOf(option.key as string);
        if (index > -1) {
          newState.splice(index, 1);
        }
      }
    }
    setState(newState)
    onSubmit({ ...filter, enumIds: newState });
  }

  return <Dropdown
    multiSelect
    label={field.displayName ?? field.name}
    selectedKeys={state}
    onChange={onChange}
    placeholder={t("content.filters.enum.placeholder")}
    options={options}
  />
}
