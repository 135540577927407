import { PublisherAccountType, ReaderAccountType, User } from '@notidar/api'

export enum PublisherAction {
  ManageChannels = 'ManageChannels',
}

export enum ReaderAction {}

const publisherRules: Record<PublisherAccountType, Set<PublisherAction>> = {
  Basic: new Set([PublisherAction.ManageChannels]),
  Demo: new Set([PublisherAction.ManageChannels]),
  Premium: new Set([PublisherAction.ManageChannels]),
  Enterprise: new Set([PublisherAction.ManageChannels]),
}

const readerRules: Record<ReaderAccountType, Set<ReaderAction>> = {
  Basic: new Set([]),
  Demo: new Set([]),
  Premium: new Set([]),
  Enterprise: new Set([]),
}

export function canReader(user: User | null | undefined, action: ReaderAction): boolean {
  const key = user?.readerAccountType
  return key ? readerRules[key].has(action) : false
}

export function canPublish(user: User | null | undefined, action: PublisherAction): boolean {
  const key = user?.publisherAccountType
  return key ? publisherRules[key].has(action) : false
}
