import { MessageBar, MessageBarType, PrimaryButton, Stack, mergeStyleSets } from '@fluentui/react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { selectChannelState } from '../../redux/features/channelSlice'
import { ChannelFilterList } from './ChannelFilterList'
import { selectPostsState, updateFilters, loadNextPosts } from '../../redux/features/postsSlice'
import { updateSubscriptionFilters } from '../../redux/features/subscriptionsSlice'
import { AllFilters } from '@notidar/content/src/utils'
import { areFilterEquals, notEmptyFilter } from '@notidar/content'
import { selectSubscriptionsState } from '../../redux/features/subscriptionsSlice'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    sidebarContainer: {
      height: '100%',
      padding: '10px',
    },
  })
}

export const ChannelFilters = () => {
  const classNames = getClassNames()
  const dispatch = useAppDispatch()
  const { t } = useTranslation();
  const { channel } = useAppSelector(selectChannelState)
  const { subscriptions } = useAppSelector(selectSubscriptionsState)
  const { filters, channelId, subscriptionId } = useAppSelector(selectPostsState)

  const [areFiltersUntouched, setFiltersUntouched] = useState(true);

  if (channel === undefined || channelId !== channel.channelId) {
    return null
  }

  const subscription = subscriptions?.find(s => s.subscriptionId === subscriptionId);

  if(subscriptionId && !subscription) {
    return (
      <Stack verticalAlign='space-between' className={classNames.sidebarContainer}>
        <MessageBar messageBarType={MessageBarType.info}>{t('right_bar.filters.unavailable')}</MessageBar>
      </Stack>
    )
  }
  
  const onSubmit = (newFilter: AllFilters): void => {
    const newFilters = [...filters];
    const index = newFilters.findIndex(filter => areFilterEquals(filter, newFilter));
    if (index !== -1) {
      newFilters[index] = newFilter;
      dispatch(updateFilters(newFilters));
      dispatch(loadNextPosts());
      setFiltersUntouched(false);
    }
  }

  const onFiltersSave = (): void => {
    if(subscription) {
      const actualFilters = filters.filter(notEmptyFilter);
      dispatch(updateSubscriptionFilters({ channelId: subscription.channelId, subscriptionId: subscription.subscriptionId, filters: actualFilters }));
      setFiltersUntouched(true);
    }
  }

  return (
    <Stack key={`${channelId}-${subscriptionId}`} verticalAlign='space-between' className={classNames.sidebarContainer}>
      <ChannelFilterList fields={channel.fields} filters={filters} onSubmit={onSubmit} />
      {subscription && <PrimaryButton 
        disabled={areFiltersUntouched} 
        onClick={onFiltersSave}
        text={t("content.filters.shared.subscription_update")}
      />}
    </Stack>
  )
}

