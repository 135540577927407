import { Channel } from '@notidar/api'
import { ChannelListItem } from './ChannelListItem'
import {
  mergeStyleSets,
  Label,
  FontSizes,
  useTheme,
  ITheme,
  Spinner,
  SpinnerSize,
} from '@fluentui/react'
import { useTranslation } from 'react-i18next'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    messageContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    message: {
      fontSize: FontSizes.medium,
      padding: 0,
      color: theme.palette.black,
    },
  })
}

export interface ChannelListProps {
  channels: Channel[]
  showLoading: boolean
  showError: boolean
}

export const ChannelList: React.FC<ChannelListProps> = ({
  channels,
  showLoading,
}: ChannelListProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme)
  const { t } = useTranslation()

  if (showLoading) {
    return <Spinner label={t('shared.loading')} size={SpinnerSize.large} />
  }

  if (channels.length === 0) {
    return (
      <div className={classNames.messageContainer}>
        <Label className={classNames.message}>{t('pages.search.no_channels_found')}</Label>
      </div>
    )
  }

  return (
    <>
      {channels.map((channel, index) => (
        <ChannelListItem channel={channel} key={index} />
      ))}
    </>
  )
}
