import { useAppSelector } from '../../../redux/hooks'
import { RightSideBarState, RightSideBarType, selectAppState } from '../../../redux/features/appSlice'
import { Stack, ITheme, mergeStyleSets, useTheme } from '@fluentui/react'
import { Body } from './Body'
import { Header } from './Header'
import { useIsChannelSelected } from '../../../hooks'

const getClassNames = (theme: ITheme, rightSideBarState: RightSideBarState) => {
  return mergeStyleSets({
    sideBarContainer: {
      position: 'sticky',
      top: 0,
      height: '100%',
      borderWidth: '0px 0px 0px 1px',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      overflow: 'hidden',
      width: rightSideBarState ? undefined : 0,
    },
    header: {
      height: '60px',
      borderWidth: '0px 0px 1px 0px',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
    },
    container: {
      width: '280px',
    },
    navigation: {
      overflow: 'auto',
      height: 'calc(100% - 61px)',
    },
  })
}

export const RightSideBar = () => {
  const theme = useTheme()
  const appState = useAppSelector(selectAppState)
  const isChannelSelected = useIsChannelSelected()
  const classNames = getClassNames(theme, appState.rightSideBarState)

  let sideBarType = appState.rightSideBarState?.type;
  if(!isChannelSelected && (sideBarType === RightSideBarType.Filters || sideBarType === RightSideBarType.Notifications)) {
    if(isChannelSelected === undefined) {
      sideBarType = RightSideBarType.Empty;
    } else {
      sideBarType = undefined;
    }
  }

  if (sideBarType === undefined) {
    return null
  }

  return (
    <Stack horizontal className={classNames.sideBarContainer}>
      <div className={classNames.container}>
        <div className={classNames.header}>
          <Header type={sideBarType} />
        </div>
        <div className={classNames.navigation}>
          <Body type={sideBarType} />
        </div>
      </div>
    </Stack>
  )
}
