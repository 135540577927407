import { PublicClientApplication, BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser'

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_susi',
    editProfile: 'B2C_1_profile_edit',
    passwordReset: 'B2C_1_password_reset',
  },
  authorities: {
    signUpSignIn: {
      authority: 'https://notidarorg.b2clogin.com/notidarorg.onmicrosoft.com/B2C_1_susi',
    },
    editProfile: {
      authority: 'https://notidarorg.b2clogin.com/notidarorg.onmicrosoft.com/B2C_1_profile_edit',
    },
    passwordReset: {
      authority: 'https://notidarorg.b2clogin.com/notidarorg.onmicrosoft.com/B2C_1_password_reset',
    },
  },
  authorityDomain: 'notidarorg.b2clogin.com',
}

export const msalConfig: Configuration = {
  auth: {
    clientId: '4ee0cf77-fb02-4c4c-8565-a8acef3a1887',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/auth',
    postLogoutRedirectUri: '/',
    authorityMetadata:
      '{"issuer":"https://notidarorg.b2clogin.com/b212d9e2-fc60-47ae-a63a-2762b6159b86/v2.0/","authorization_endpoint":"https://notidarorg.b2clogin.com/notidarorg.onmicrosoft.com/b2c_1_susi/oauth2/v2.0/authorize","token_endpoint":"https://notidarorg.b2clogin.com/notidarorg.onmicrosoft.com/b2c_1_susi/oauth2/v2.0/token","end_session_endpoint":"https://notidarorg.b2clogin.com/notidarorg.onmicrosoft.com/b2c_1_susi/oauth2/v2.0/logout","jwks_uri":"https://notidarorg.b2clogin.com/notidarorg.onmicrosoft.com/b2c_1_susi/discovery/v2.0/keys","response_modes_supported":["query","fragment","form_post"],"response_types_supported":["code","code id_token","code token","code id_token token","id_token","id_token token","token","token id_token"],"scopes_supported":["openid"],"subject_types_supported":["pairwise"],"id_token_signing_alg_values_supported":["RS256"],"token_endpoint_auth_methods_supported":["client_secret_post","client_secret_basic"],"claims_supported":["newUser","oid","sub","idp","emails","tfp","isForgotPassword","iss","iat","exp","aud","acr","nonce","auth_time"]}', //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/performance.md
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            //console.error(message)
            return
          case LogLevel.Info:
            //console.info(message)
            return
          case LogLevel.Verbose:
            //console.debug(message)
            return
          case LogLevel.Warning:
            //console.warn(message)
            return
          default:
            return
        }
      },
    },
  },
}

const msalInstance = new PublicClientApplication(msalConfig)
msalInstance.initialize()

export { msalInstance }
