import { Stack, TextField } from '@fluentui/react'
import { FieldType, ImageFileListField } from '@notidar/api'
import { FieldEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface ImageFieldEditorComponentProps
  extends FieldEditorComponentProps<FieldType.ImageFileList> {}

export const ImageFieldEditorComponent = ({
  field,
  hidden,
  onUpdate,
}: ImageFieldEditorComponentProps) => {
  const { t } = useTranslation();
  const [fieldState, setFieldState] = useState<ImageFileListField>(field)

  const isValid = (field: ImageFileListField): boolean => {
    return validateTitle(field.displayName ?? '') === ''
  }

  const validateTitle = (value: string): string => {
    if (value.length === 0) {
      return ''
    }
  
    if (value.length > 32) {
      return t("content.fields.shared.displayName_too_long");
    }
  
    return ''
  }

  useEffect(() => {
    onUpdate(fieldState, isValid(fieldState))
  }, [fieldState]) // eslint-disable-line react-hooks/exhaustive-deps

  const titleValueChanged = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const newField: ImageFileListField = { ...fieldState, displayName: newValue }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  if (hidden) {
    return null
  }

  return (
    <Stack>
      <TextField
        label={t("content.fields.shared.displayName")}
        value={fieldState.displayName ?? ''}
        onChange={titleValueChanged}
        onGetErrorMessage={validateTitle}
        deferredValidationTime={500}
      />
    </Stack>
  )
}
