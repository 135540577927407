import { Checkbox, Stack, TextField } from '@fluentui/react'
import { EnumField, EnumListField, EnumOption, FieldType } from '@notidar/api'
import { FieldEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { CreateEnumOptionForm } from './CreateEnumOptionForm'
import { EnumOptionItemList } from './EnumOptionItemList'
import { useTranslation } from 'react-i18next'

export interface EnumFieldEditorComponentProps
  extends FieldEditorComponentProps<FieldType.Enum | FieldType.EnumList> { }

export const EnumFieldEditorComponent = ({
  field,
  hidden,
  onUpdate,
}: EnumFieldEditorComponentProps) => {
  const [fieldState, setFieldState] = useState<EnumField | EnumListField>(field);
  const { t } = useTranslation();

  const validateTitle = (value: string): string => {
    if (value.length === 0) {
      return ''
    }
  
    if (value.length > 32) {
      return t("content.fields.shared.displayName_too_long");
    }
  
    return ''
  }

  useEffect(() => {
    onUpdate(fieldState, isValid(fieldState))
  }, [fieldState]) // eslint-disable-line react-hooks/exhaustive-deps

  const isValid = (field: EnumField | EnumListField): boolean => {
    return (
      (field?.possibleValues ?? []).length > 0 && validateTitle(field.displayName ?? '') === ''
    )
  }

  const addEnumOption = (enumOption: EnumOption): void => {
    const newField: EnumField | EnumListField = {
      ...fieldState,
      possibleValues: [...(fieldState?.possibleValues ?? []), enumOption],
    }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  const removeEnumOption = (enumId: string): void => {
    const index = fieldState?.possibleValues?.findIndex(x => x.enumId === enumId)
    if (index !== undefined && index !== -1) {
      const newEnums = [...fieldState.possibleValues]
      newEnums.splice(index, 1)
      const newField = {
        ...fieldState,
        possibleValues: newEnums,
      }
      setFieldState(newField)
      onUpdate(newField, isValid(newField))
    }
  }

  const titleValueChanged = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const newField: EnumField | EnumListField = { ...fieldState, displayName: newValue }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  const searchableValueChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    const newField: EnumField | EnumListField = { ...fieldState, searchable: checked }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  if (hidden) {
    return null
  }

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <TextField
        label={t('content.fields.shared.displayName')}
        value={fieldState.displayName ?? ''}
        onChange={titleValueChanged}
        onGetErrorMessage={validateTitle}
        deferredValidationTime={500}
      />
      <Checkbox label={t("content.fields.shared.searchable")} checked={fieldState.searchable} onChange={searchableValueChanged} />
      <div>
        <EnumOptionItemList
          enumOptionList={fieldState?.possibleValues ?? []}
          onRemove={item => removeEnumOption(item.enumId)}
        />
        <CreateEnumOptionForm
          existingEnumOption={fieldState?.possibleValues ?? []}
          onAdd={addEnumOption}
        />
      </div>
    </Stack>
  )
}
