import { ITheme, mergeStyleSets, useTheme } from '@fluentui/react'
import { RightSideBarState, selectAppState } from '../../../redux/features/appSlice'
import { useAppSelector } from '../../../redux/hooks'
import { Outlet } from 'react-router-dom'

const getClassNames = (
  theme: ITheme,
  appMode: 'mobile' | 'desktop',
  isLeftSideBarVisible: boolean,
  rightSideBarState: RightSideBarState,
) => {
  return mergeStyleSets({
    body: {
      height: 'calc(100% - 61px);',
      overflow: 'auto',
      scrollbarGutter: 'stable'
    },
    container: {
      backgroundColor: theme.palette.neutralLight,
      overflow: 'hidden',
      height: '100%',
      maxWidth: '100vw',
    },
    content: {
      left: isLeftSideBarVisible ? 0 : undefined,
      right: rightSideBarState ? 0 : undefined,
      height: '100%',
      width: appMode === 'mobile' ? '100vw' : undefined,
    },
  })
}

export const Content = () => {
  const theme = useTheme()
  const appState = useAppSelector(selectAppState)
  const classNames = getClassNames(theme, appState.screenType, appState.isLeftSideBarVisible, appState.rightSideBarState)

  return (
    <div className={classNames.container}>
      <div className={classNames.content}>
        <div id='scrollDiv' className={classNames.body}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
