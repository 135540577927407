import { ChannelIdentifier } from '../../../redux/models'
import { PrimaryButton, Stack, mergeStyleSets, Separator } from '@fluentui/react'
import { Fragment, useState } from 'react'
import { ApiClient } from '../../../api'
import { useNavigate } from '../../../hooks'
import { Field } from '@notidar/api'
import { FieldEditorComponent } from '../../Content/Fields/FieldEditorComponent'
import { NewFieldComponent } from '../../Content/Fields/NewFieldComponent'
import { AllFields } from '@notidar/content/src/utils'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    button: {
      margin: '0 10px 0 0',
    },
    footer: {
      margin: '0 10px 10px',
    },
  })
}

export interface FieldListProps {
  existingFields: Field[]
  channelIdentifier: ChannelIdentifier
}

export const FieldList: React.FC<FieldListProps> = ({
  existingFields,
  channelIdentifier,
}: FieldListProps) => {
  const { t } = useTranslation();
  const classNames = getClassNames()
  const navigate = useNavigate()
  const [version, setVersion] = useState(0);
  const [fields, setFields] = useState<{ field: Field; isValid?: boolean }[]>(
    existingFields.map(x => ({ field: x, isValid: true }))
  )

  const addNewField = (field: AllFields): void => {
    setFields([...fields, { field: { ...field } }])
  }

  const onFieldRemove = (field: Field): void => {
    setFields(fields.filter((x, _) => x.field.name !== field.name))
  }

  const onFieldUpdate = (field: Field, isValid: boolean): void => {
    const newFields = [...fields]
    const index = newFields.findIndex(x => x.field.name === field.name)
    newFields[index].field = field
    newFields[index].isValid = isValid
    setFields(newFields)
  }

  const onReset = (): void => {
    setFields(existingFields.map(x => ({ field: x, isValid: true })));
    setVersion(version + 1);
  }

  const onChannelFieldsUpdate = async (): Promise<void> => {
    try {
      await ApiClient.updateChannelFieldsAsync(channelIdentifier, {
        fields: fields.map(x => x.field) as Field[],
      })
      navigate(`/manage/channel/${channelIdentifier}`)
    } catch (e) { }
  }

  return (
    <>
      <Fragment key={version}>
        {fields.map(x => (
          <FieldEditorComponent
            key={x.field.name}
            field={x.field}
            onRemove={onFieldRemove}
            onUpdate={onFieldUpdate}
          />
        ))}
      </Fragment>
      <NewFieldComponent existingFields={fields.map(x => x.field)} onAdd={addNewField} />
      <Separator />
      <Stack className={classNames.footer} horizontal>
        <PrimaryButton
          disabled={fields.some(x => !x.isValid)}
          className={classNames.button}
          onClick={onChannelFieldsUpdate}
          text={t('pages.manage_fields.save')}
        />
        <PrimaryButton
          className={classNames.button}
          onClick={onReset}
          text={t('pages.manage_fields.reset')}
        />
      </Stack>
    </>
  )
}
