import React from 'react'
import { MsalProvider } from '@azure/msal-react'
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser'
import { msalInstance } from '.'
import { useNavigate } from 'react-router-dom'
import { CustomNavigationClient } from './CustomNavigationClient'

msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback((event: EventMessage) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload
  ) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

export interface MsalInitializerProps {
  children: React.ReactNode
}

export const MsalInitializer: React.FC<MsalInitializerProps> = ({
  children,
}: MsalInitializerProps) => {
  const navigate = useNavigate()
  msalInstance.setNavigationClient(new CustomNavigationClient(navigate))

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}
