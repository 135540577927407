import {
  Dropdown,
  IDropdownOption,
  ITheme,
  PrimaryButton,
  Stack,
  mergeStyleSets,
  useTheme,
} from '@fluentui/react'
import { PostSectionType } from '@notidar/api'
import { useState } from 'react'
import { useLocalizedSectionTypes } from './Shared.types'
import { useTranslation } from 'react-i18next'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    button: {
      margin: '0 10px 0 0',
    },
    container: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      padding: '10px 10px 0',
    },
  })
}

export interface NewSectionComponentProps {
  onAdd: (type: PostSectionType) => void
}

export const NewSectionComponent = ({ onAdd }: NewSectionComponentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classNames = getClassNames(theme)
  const [newSection, setNewSection] = useState<{
    type?: PostSectionType | null
    isTypeValid: boolean
  }>({
    isTypeValid: false,
  })
  const allowedSectionTypes = useLocalizedSectionTypes();

  const addNewSection = (): void => {
    onAdd(newSection.type!)
    setNewSection(x => ({ isTypeValid: false, type: null }))
  }

  const newSectionTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    const type = option?.key as PostSectionType | undefined
    setNewSection({ ...newSection, type: type, isTypeValid: type !== undefined })
  }

  return (
    <Stack className={classNames.container}>
      <Stack horizontal>
        <PrimaryButton
          disabled={!newSection.isTypeValid}
          onClick={addNewSection}
          className={classNames.button}
          text={t("content.sections.shared.add_section")}
        />
        <Dropdown
          selectedKey={newSection.type}
          className={classNames.button}
          onChange={newSectionTypeChange}
          placeholder={t("content.sections.shared.type_placeholder")}
          options={allowedSectionTypes}
        />
      </Stack>
    </Stack>
  )
}
