import { SpinButton, Stack } from '@fluentui/react'
import { FieldType } from '@notidar/api'
import { ValueEditorProps } from '../Shared.types'
import { ItemHeader } from '../../Shared/ItemHeader'
import { useState } from 'react'
import { toIntegerNumber, toStringNumber } from '../utils'
import { currencyDataMap } from '@notidar/content/src/fields/money/MoneyValueComponent'

export interface MoneyValueEditorProps extends ValueEditorProps<FieldType.Money> {}

const validate = (value: string, exponent: number, symbol: string): string => {
  const int = toIntegerNumber(value.replace(/[^\d.-]/g, ''), exponent)
  return `${toStringNumber(int, exponent)} ${symbol}`
}

export const MoneyValueEditor = ({ field, value, onUpdate, onValidation }: MoneyValueEditorProps) => {
  const { exponent, symbol } = currencyDataMap[field.currency]
  const [newValue, setNewValue] = useState<number | undefined>(value?.moneyPayload?.value ?? undefined)

  const onValueChange = (event: React.SyntheticEvent<HTMLElement>, newValue?: string): void => {
    const result = toIntegerNumber(newValue || '0', exponent)
    setNewValue(result)
    onUpdate(field, { type: FieldType.Money, moneyPayload: { value: result } })
    onValidation(field, true)
  }

  const onIncrement = (
    value: string,
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ): string | void => {
    const int = toIntegerNumber(value, exponent)
    const increased = int + 1
    if (increased > Number.MAX_SAFE_INTEGER) {
      return value
    }
    return `${toStringNumber(increased, exponent)} ${symbol}`
  }

  const onDecrement = (
    value: string,
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ): string | void => {
    const int = toIntegerNumber(value, exponent)
    const decreased = int - 1
    if (decreased < Number.MIN_SAFE_INTEGER) {
      return value
    }
    return `${toStringNumber(decreased, exponent)} ${symbol}`
  }

  return (
    <Stack>
      <ItemHeader name={field.name} title={field.displayName} />
      <Stack>
        <SpinButton
          value={`${toStringNumber(newValue || 0, exponent)} ${symbol}`}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          onChange={onValueChange}
          onValidate={value => validate(value, exponent, symbol)}
        />
      </Stack>
    </Stack>
  )
}
