import { Spinner, mergeStyleSets } from '@fluentui/react'
import { Card, CardList } from '../../App/Card'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'
import { selectUserChannelsState } from '../../../redux/features/userChannelsSlice'
import { useEffect, useState } from 'react'
import { Channel, Post, Value } from '@notidar/api'
import { ChannelIdentifier, FieldKey, PostId } from '../../../redux/models'
import { ApiClient } from '../../../api'
import { ValueListEditor } from '../../Content/Fields/ValueListEditor'
import { useNavigate } from '../../../hooks'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
    header: {
      margin: '10px 0 0',
    },
    block: {
      height: '100%',
      width: '100%',
      flexWrap: 'wrap',
      overflow: 'hidden',
    },
  })
}

export const EditPostView = () => {
  const { t } = useTranslation();
  const classNames = getClassNames()
  const navigate = useNavigate()
  const { channelIdentifier } = useParams<{ channelIdentifier: ChannelIdentifier }>()
  const { postId } = useParams<{ postId: PostId }>()
  const { channels: userChannels } = useAppSelector(selectUserChannelsState)

  const [postState, setPostState] = useState<{
    post?: Post | null
    isSaving: boolean
    isLoading: boolean
  }>({
    isLoading: true,
    isSaving: false,
    post: undefined,
  })

  const [channelState, setChannelState] = useState<{
    channel?: Channel | null
    isLoading: boolean
  }>({
    isLoading: true,
    channel: undefined,
  })

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiClient.getChannelAsync(channelIdentifier!, { secure: true });
      setChannelState(prev => ({ ...prev, channel: response.data.channel, isLoading: false }))
    }

    fetchData().catch(() => {
      setChannelState(prev => ({ ...prev, channel: null, isLoading: false }))
    })
  }, [channelIdentifier, setChannelState])

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiClient.getChannelPostAsync(postId!, { secure: true });
      setPostState(prev => ({ ...prev, post: response.data.post, isLoading: false }))
    }

    fetchData().catch(() => {
      setPostState(prev => ({ ...prev, post: null, isLoading: false }))
    })
  }, [channelIdentifier, postId, setPostState])

  const updatePost = async (values: Record<FieldKey, Value>): Promise<void> => {
    if (postState.isSaving || !postState.post?.postId) {
      return
    }
    if (!channelState.channel?.channelId) {
      return
    }
    setPostState(prev => ({ ...prev, isSaving: true }))
    try {
      await ApiClient.updatePostValuesAsync(postState.post.postId, {
        values: values,
        expireDate: null,
      })
      navigate(`/channel/${channelIdentifier}`)
    } catch (e) {
      setPostState(prev => ({ ...prev, isSaving: false }))
    }
  }

  const deletePost = async (): Promise<void> => {
    if (postState.isSaving || !postState.post?.postId) {
      return
    }
    if (!channelState.channel?.channelId) {
      return
    }
    setPostState(prev => ({ ...prev, isSaving: true }))
    try {
      await ApiClient.deletePostAsync(postState.post.postId)
      navigate(`/channel/${channelIdentifier}`)
    } catch (e) {
      setPostState(prev => ({ ...prev, isSaving: false }))
    }
  }

  const isManagedChannel =
    channelState.channel && userChannels?.findIndex(x => x.channelId === channelState.channel?.channelId) !== -1

  return (
    <CardList>
      <Card>
        <div className={classNames.container}>
          {channelState.isLoading || (postState.isLoading && <Spinner label={t('shared.loading')} />)}
          {!channelState.isLoading && !channelState.channel && <div>{t('pages.edit_post.channel_not_found')}</div>}
          {!postState.isLoading && !postState.post && <div>{t('pages.edit_post.post_not_found')}</div>}
          {channelState.channel && !isManagedChannel && <div>{t('pages.edit_post.channel_not_allowed')}</div>}
          {channelState.channel && postState.post && isManagedChannel && (
            <>
              <h2 className={classNames.header}>{t("pages.edit_post.title")}</h2>
              <ValueListEditor
                channel={channelState.channel}
                postId={postState.post.postId}
                values={postState.post.values}
                disabled={postState.isSaving}
                onSave={values => updatePost(values)}
                onSaveText={t('pages.edit_post.save')}
                onDelete={deletePost}
                onDeleteText={t('pages.edit_post.delete')}
                previewOnText={t('pages.edit_post.preview_on')}
                previewOffText={t('pages.edit_post.preview_off')}
              />
            </>
          )}
        </div>
      </Card>
    </CardList>
  )
}
