import { Spinner, mergeStyleSets } from '@fluentui/react'
import { Card, CardList } from '../../App/Card'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'
import { selectUserChannelsState } from '../../../redux/features/userChannelsSlice'
import { useEffect, useState } from 'react'
import { Channel, Value } from '@notidar/api'
import { ChannelIdentifier, FieldKey } from '../../../redux/models'
import { ApiClient } from '../../../api'
import { ValueListEditor } from '../../Content/Fields/ValueListEditor'
import { useNavigate } from '../../../hooks'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
    header: {
      margin: '10px 0 0',
    },
    block: {
      height: '100%',
      width: '100%',
      flexWrap: 'wrap',
      overflow: 'hidden',
    },
  })
}

export const CreatePostView = () => {
  const { t } = useTranslation();
  const classNames = getClassNames()
  const navigate = useNavigate()
  const { channelIdentifier } = useParams<{ channelIdentifier: ChannelIdentifier }>()
  const { channels: userChannels } = useAppSelector(selectUserChannelsState)

  const [state, setState] = useState<{
    channel?: Channel | null
    isSaving: boolean
    isLoading: boolean
    isPreview: boolean
  }>({
    isLoading: true,
    isSaving: false,
    channel: undefined,
    isPreview: false,
  })

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiClient.getChannelAsync(channelIdentifier!, { secure: true });
      setState(prev => ({ ...prev, channel: response.data.channel, isLoading: false }))
    }

    fetchData().catch(() => {
      setState(prev => ({ ...prev, channel: null, isLoading: false }))
    })
  }, [channelIdentifier, setState])

  const createPost = async (values: Record<FieldKey, Value>): Promise<void> => {
    if (state.isSaving) {
      return
    }
    setState(prev => ({ ...prev, isSaving: true }))
    try {
      await ApiClient.createPostAsync({
        channelIdentifier: channelIdentifier!,
        values: values,
        expireDate: null,
        externalUniqueId: null,
      })
      navigate(`/manage/channel/${channelIdentifier}`)
    } catch (e) {
      setState(prev => ({ ...prev, isSaving: false }))
    }
  }

  const isManagedChannel =
    state.channel && userChannels?.findIndex(x => x.channelId === state.channel!.channelId) !== -1

  return (
    <CardList>
      <Card>
        <div className={classNames.container}>
          {state.channel === undefined && <Spinner label={t('shared.loading')} />}
          {state.channel === null && <div>{t('pages.create_post.channel_not_found')}</div>}
          {state.channel && !isManagedChannel && <div>{t('pages.create_post.channel_not_allowed')}</div>}
          {state.channel && isManagedChannel && (
            <>
              <h2 className={classNames.header}>{t("pages.create_post.title")}</h2>
              <br />
              <ValueListEditor
                channel={state.channel}
                disabled={state.isSaving}
                onSave={values => createPost(values)}
                onSaveText={t('pages.create_post.save')}
                previewOnText={t('pages.create_post.preview_on')}
                previewOffText={t('pages.create_post.preview_off')}
              />
            </>
          )}
        </div>
      </Card>
    </CardList>
  )
}
