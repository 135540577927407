import { ChoiceGroup, IChoiceGroupOption, mergeStyleSets } from '@fluentui/react'
import { Theme } from '@notidar/api'
import { useCallback } from 'react'
import { ApiClient } from '../../../../api'
import { useAppDispatch } from '../../../../redux/hooks'
import { updateSettingsTheme } from '../../../../redux/features/userSlice'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
  })
}

export interface AppearanceSettingsProps {
  selectedTheme: Theme
}

export const AppearanceSettings: React.FC<AppearanceSettingsProps> = ({ selectedTheme }: AppearanceSettingsProps) => {
  const classNames = getClassNames()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const options: IChoiceGroupOption[] = [
    { key: Theme.System, text: t('pages.settings.theme_option_system') },
    { key: Theme.Dark, text: t('pages.settings.theme_option_dark') },
    { key: Theme.Light, text: t('pages.settings.theme_option_light') },
  ]

  const onChange = useCallback(
    async (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
      try {
        const value = option?.key as Theme
        if (value) {
          dispatch(updateSettingsTheme(value))
          await ApiClient.updateUserThemeAsync({ theme: value })
        }
      } catch {
        // ignore
      }
    },
    [dispatch],
  )

  return (
    <div className={classNames.container}>
      <ChoiceGroup selectedKey={selectedTheme} options={options} onChange={onChange} label={t('pages.settings.theme_label')} />
    </div>
  )
}
