import { useEffect } from 'react'
import store from '../redux/store'
import { setDesktopScreen, setMobileScreen } from '../redux/features/appSlice'
import { mobileScreenMediaQuery } from '../globals'

export const useMobileScreenDetection = () => {
  useEffect(() => {
    const type = 'change'
    const listener = (ev: MediaQueryListEvent) => {
      if (ev.matches) {
        store.dispatch(setMobileScreen())
      } else {
        store.dispatch(setDesktopScreen())
      }
    }
    mobileScreenMediaQuery.addEventListener(type, listener)
    return () => mobileScreenMediaQuery.removeEventListener(type, listener) // cleanup
  }, [])
}
