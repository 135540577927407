import { Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react'
import { DateTimeField, FieldType } from '@notidar/api'
import { FieldEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface DateTimeFieldEditorComponentProps
  extends FieldEditorComponentProps<FieldType.DateTime> {}

export const DateTimeFieldEditorComponent = ({
  field,
  hidden,
  onUpdate,
}: DateTimeFieldEditorComponentProps) => {
  const { t } = useTranslation();
  const [fieldState, setFieldState] = useState<DateTimeField>(field);

  const formatOptions: IDropdownOption[] = [
    { key: "y", text: t("content.fields.datetime.precision_year") },
    { key: "ym", text: t("content.fields.datetime.precision_month") },
    { key: "ymd", text: t("content.fields.datetime.precision_day") },
    { key: "ymdt", text: t("content.fields.datetime.precision_time") },
    { key: "ymdts", text: t("content.fields.datetime.precision_time_accurate") },
  ]
  
  const isValid = (field: DateTimeField): boolean => {
    const isFormatValid = field.minimalValidMask !== undefined
    return isFormatValid && validateTitle(field.displayName ?? '') === ''
  }
  
  const validateTitle = (value: string): string => {
    if (value.length === 0) {
      return ''
    }
  
    if (value.length > 32) {
      return t("content.fields.shared.displayName_too_long");
    }
  
    return ''
  }

  useEffect(() => {
    onUpdate(fieldState, isValid(fieldState))
  }, [fieldState]) // eslint-disable-line react-hooks/exhaustive-deps

  const titleValueChanged = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const newField: DateTimeField = { ...fieldState, displayName: newValue }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  const formatValueChanged = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    const newField: DateTimeField = {
      ...fieldState,
      minimalValidMask: option?.key as string,
    }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  if (hidden) {
    return null
  }

  return (
    <Stack>
      <TextField
        label={t("content.fields.shared.displayName")}
        value={fieldState.displayName ?? ''}
        onChange={titleValueChanged}
        onGetErrorMessage={validateTitle}
        deferredValidationTime={500}
      />
      <Dropdown
        label={t("content.fields.datetime.precision")}
        placeholder={t("content.fields.datetime.pick_precision")}
        options={formatOptions}
        selectedKey={fieldState.minimalValidMask}
        onChange={formatValueChanged}
      />
    </Stack>
  )
}
