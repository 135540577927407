import { SpinButton, Stack } from '@fluentui/react'
import { FieldType } from '@notidar/api'
import { ValueEditorProps } from '../Shared.types'
import { ItemHeader } from '../../Shared/ItemHeader'
import { useState } from 'react'
import { toIntegerNumber, toStringNumber } from '../utils'

export interface NumberValueEditorProps extends ValueEditorProps<FieldType.Number> {}

const validate = (value: string, exponent: number): string => {
  const int = toIntegerNumber(value.replace(/[^\d.-]/g, ''), exponent)
  return toStringNumber(int, exponent)
}

export const NumberValueEditor = ({ field, value, onUpdate, onValidation }: NumberValueEditorProps) => {
  const exponent = field.exponent ?? 0
  const [newValue, setNewValue] = useState<number | undefined>(value?.numberPayload?.value ?? undefined);

  const onValueChange = (event: React.SyntheticEvent<HTMLElement>, newValue?: string): void => {
    const result = toIntegerNumber(newValue || '0', exponent)
    setNewValue(result)
    onUpdate(field, { type: FieldType.Number, numberPayload: { value: result } })
    onValidation(field, true)
  }

  const onIncrement = (
    value: string,
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ): string | void => {
    const int = toIntegerNumber(value, exponent)
    const increased = int + 1
    if (increased > Number.MAX_SAFE_INTEGER) {
      return value
    }
    return toStringNumber(increased, exponent)
  }

  const onDecrement = (
    value: string,
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ): string | void => {
    const int = toIntegerNumber(value, exponent)
    const decreased = int - 1
    if (decreased < Number.MIN_SAFE_INTEGER) {
      return value
    }
    return toStringNumber(decreased, exponent)
  }

  return (
    <Stack>
      <ItemHeader name={field.name} title={field.displayName} />
      <Stack>
        <SpinButton
          value={toStringNumber(newValue ?? 0, exponent)}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          onChange={onValueChange}
          onValidate={value => validate(value, exponent)}
        />
      </Stack>
    </Stack>
  )
}
