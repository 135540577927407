import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { mobileScreenMediaQuery, systemDarkThemeMediaQuery } from '../../globals'

interface AppState {
  screenType: 'mobile' | 'desktop'
  systemThemeType: 'light' | 'dark'
  isLeftSideBarVisible: boolean
  rightSideBarState: RightSideBarState
  enableManagement: boolean
}

export enum RightSideBarType {
  Empty = 'empty',
  Filters = 'filters',
  Notifications = 'notifications',
  Toggles = 'toggles',
}

export type RightSideBarState =
  | undefined
  | { type: RightSideBarType.Empty }
  | { type: RightSideBarType.Filters }
  | { type: RightSideBarType.Notifications }
  | { type: RightSideBarType.Toggles }

const initialState: AppState = {
  screenType: mobileScreenMediaQuery.matches ? 'mobile' : 'desktop',
  isLeftSideBarVisible: !mobileScreenMediaQuery.matches,
  rightSideBarState: undefined,
  enableManagement: false,
  systemThemeType: systemDarkThemeMediaQuery.matches ? 'dark' : 'light',
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showLeftSideBar: state => {
      if (state.screenType === 'mobile') {
        state.rightSideBarState = undefined
      }
      state.isLeftSideBarVisible = true
    },
    hideLeftSideBar: state => {
      state.isLeftSideBarVisible = false
    },
    hideSideBars: state => {
      state.isLeftSideBarVisible = false
      state.rightSideBarState = undefined
    },
    showRightSideBar: (state, action: PayloadAction<RightSideBarType>) => {
      if (state.screenType === 'mobile') {
        state.isLeftSideBarVisible = false
      }
      state.rightSideBarState = { type: action.payload }
    },
    hideRightSideBar: state => {
      state.rightSideBarState = undefined
    },
    swapRightSideBar: (state, action: PayloadAction<RightSideBarType>) => {
      if (state.screenType === 'mobile') {
        state.isLeftSideBarVisible = false
      }
      state.rightSideBarState?.type === action.payload
        ? (state.rightSideBarState = undefined)
        : (state.rightSideBarState = { type: action.payload });
    },
    setDesktopScreen: state => {
      state.screenType = 'desktop'
      state.isLeftSideBarVisible = true
      state.rightSideBarState = undefined
    },
    setMobileScreen: state => {
      state.screenType = 'mobile'
      state.isLeftSideBarVisible = false
      state.rightSideBarState = undefined
    },
    setEnableManagement: (state, action: PayloadAction<boolean>) => {
      state.enableManagement = action.payload
    },
    setSystemTheme: (state, action: PayloadAction<'dark' | 'light'>) => {
      state.systemThemeType = action.payload
    },
  },
})

export const {
  hideLeftSideBar,
  hideRightSideBar,
  hideSideBars,
  showLeftSideBar,
  showRightSideBar,
  swapRightSideBar,
  setDesktopScreen,
  setMobileScreen,
  setEnableManagement,
  setSystemTheme,
} = appSlice.actions

export const selectAppState = (state: RootState) => state.app

export default appSlice.reducer
