export const getMaxNumberString = (exponent: number): string => {
  const int = Number.MAX_SAFE_INTEGER.toString()
  const leftPart = int.substring(0, int.length - exponent)
  const rightPart = int.substring(int.length - exponent, int.length)
  return leftPart + '.' + rightPart
}

export const toIntegerNumber = (value: string, exponent: number): number => {
  const parts = value.split('.')
  parts[0] = parts[0] ?? '0'
  parts[1] = (parts[1] ?? '').padEnd(exponent, '0').substring(0, exponent)
  
  const int = parseInt(parts.join(''), 10)
  if (Number.isNaN(int)) {
    return 0
  }
  if (!Number.isFinite(int)) {
    return 0
  }
  if (int > Number.MAX_SAFE_INTEGER) {
    return toIntegerNumber(getMaxNumberString(exponent), exponent)
  }
  if (int < Number.MIN_SAFE_INTEGER) {
    return toIntegerNumber('-' + getMaxNumberString(exponent), exponent)
  }
  return int
}

export const toStringNumber = (int: number, exponent: number): string => {
  const intValue = int.toString()
  const value = int < 0 ? '-' + intValue.substring(1).padStart(exponent + 1, '0') : intValue.padStart(exponent + 1, '0')
  const leftPart = value.substring(0, value.length - exponent)
  const rightPart = value.substring(value.length - exponent, value.length)
  return exponent > 0 ? leftPart + '.' + rightPart : leftPart
}
