import { Stack, Link, PrimaryButton } from '@fluentui/react'
import { FontSizes } from '@fluentui/theme'
import { mergeStyles } from '@fluentui/merge-styles'
import { ActionsPostSection, LinkValue, LinkField, FieldType } from '@notidar/api'
import { SectionComponentProps } from '../SectionComponent'
import { SectionContainer } from '../SectionContainer'
import { parseFieldValue } from '../../utils'
import { notEmpty } from '@notidar/common'

const containerClass = mergeStyles({
  margin: '8px',
})

export interface ActionsSectionComponentProps
  extends SectionComponentProps<ActionsPostSection> {}

export const ActionsSectionComponent = (props: ActionsSectionComponentProps): JSX.Element | null => {
  const itemsToRender =
    props.section?.actionItems
      ?.map((x, index) => {
        const { actionField, displayString } = x
        
        const link = parseFieldValue<FieldType.Link, string>(
          props,
          actionField,
          {
            Link: (value: LinkValue, field: LinkField) => { return value.linkPayload?.link ?? undefined }
          })

        if (link) {
          if (true) {
            return (
              <div className={containerClass} key={index}>
                <PrimaryButton href={link} target="_blank" text={displayString}/>
              </div>
            )
          } else {
            return (
              <Link target='_blank' href={link} style={{ fontSize: FontSizes.large }} key={index}>
                {displayString}
              </Link>
            )
          }
        } else {
          return null
        }
      })
      .filter(notEmpty) ?? []

  if (itemsToRender.length === 0) {
    return null
  }

  return (
    <SectionContainer>
      <Stack horizontal horizontalAlign='start' wrap tokens={{ childrenGap: 10 }}>
        {itemsToRender}
      </Stack>
    </SectionContainer>
  )
}
