import { DefaultButton, Dropdown, IDropdownOption, IStackTokens, Stack } from '@fluentui/react'
import {
  FieldType,
  MarkdownPostSection,
  PostSection,
  PostSectionType,
} from '@notidar/api'
import { SectionEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { getFieldByFieldKey } from '../../utils'
import { useTranslation } from 'react-i18next'

const stackTokens: IStackTokens = { childrenGap: 10 }

const isValid = (section: Partial<MarkdownPostSection> & PostSection): boolean => {
  return section.markdownField !== undefined
}

export interface MarkdownSectionEditorComponentProps
  extends SectionEditorComponentProps<PostSectionType.Markdown> {}

export const MarkdownSectionEditorComponent = ({
  fields,
  hidden,
  onUpdate,
  section,
}: MarkdownSectionEditorComponentProps) => {
  const { t } = useTranslation();
  const [sectionState, setSectionState] = useState<Partial<MarkdownPostSection> & PostSection>(section)

  const allowedMarkdownFields = fields
    .filter(x => x.type === FieldType.Markdown)
    .map(x => ({ key: x.name, text: x.displayName ? `${x.displayName} (${x.name})` : x.name }))

  useEffect(() => {
    onUpdate(sectionState, isValid(sectionState))
  }, [sectionState]) // eslint-disable-line react-hooks/exhaustive-deps

  const onMarkdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    const newState: Partial<MarkdownPostSection> & PostSection = {
      ...sectionState,
      markdownField: option?.key as string,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onMarkdownClear = (): void => {
    const newState: Partial<MarkdownPostSection> & PostSection = {
      ...sectionState,
      markdownField: undefined,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const markdownField = getFieldByFieldKey({ fields }, sectionState.markdownField)

  if (hidden) {
    return null
  }

  return (
    <Stack>
      <Stack tokens={stackTokens} horizontal wrap verticalAlign='end'>
        <Dropdown
          key={sectionState.markdownField}
          disabled={allowedMarkdownFields.length === 0}
          onChange={onMarkdownChange}
          selectedKey={markdownField ? sectionState.markdownField : undefined}
          placeholder={
            allowedMarkdownFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field")
          }
          options={allowedMarkdownFields}
          label={t("content.sections.markdown.markdown_field")}
        />
        <DefaultButton
          disabled={!sectionState.markdownField}
          onClick={onMarkdownClear}
          text={t("content.sections.shared.clear")}
        />
      </Stack>
    </Stack>
  )
}
