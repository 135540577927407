import { createContext } from 'react';

export interface AppContentContextState {
  mediaBaseUrl: string
  screenType: 'mobile' | 'desktop'
  themeType: 'light' | 'dark'
}

export const AppContentContext = createContext<AppContentContextState>({
  mediaBaseUrl: "https://notidarprod.blob.core.windows.net/public",
  screenType: 'mobile',
  themeType: 'light'
});