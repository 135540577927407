import { Card, CardList } from '../../App/Card'
import { useParams } from 'react-router-dom'
import { mergeStyleSets, PrimaryButton, Spinner, Stack } from '@fluentui/react'
import { ApiClient } from '../../../api'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import { selectUserChannelsState, removeChannel } from '../../../redux/features/userChannelsSlice'
import { useNavigate } from '../../../hooks'
import { ChannelIdentifier } from '../../../redux/models'
import { Channel, ChannelStatus, ChannelVisibilityStatus } from '@notidar/api'
import { ChannelNameHeader } from '../../Content/Shared/ChannelNameHeader'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
    header: {
      margin: '10px 0 0',
    },
    block: {
      height: '100%',
      width: '100%',
      flexWrap: 'wrap',
      overflow: 'hidden',
    },
  })
}

const channelStatuses = [
  { key: ChannelStatus.Creating, text: 'Creating', translationKey: 'pages.manage_channel.statuses.creating' },
  { key: ChannelStatus.Deleting, text: 'Deleting', translationKey: 'pages.manage_channel.statuses.deleting' },
  { key: ChannelStatus.Ready, text: 'Ready', translationKey: 'pages.manage_channel.statuses.ready' },
  { key: ChannelStatus.Updating, text: 'Updating', translationKey: 'pages.manage_channel.statuses.updating' },
]

const channelVisibilityStatuses = [
  { key: ChannelVisibilityStatus.Private, text: 'Private', translationKey: 'pages.manage_channel.visibilities.private' },
  { key: ChannelVisibilityStatus.Public, text: 'Public', translationKey: 'pages.manage_channel.visibilities.public' },
]

const useLocalizedChannelStatuses = () => {
  const { t } = useTranslation();
  const localizedChannelStatuses = useMemo(() => channelStatuses.map(({ key, text, translationKey }) => ({ key, text: t(translationKey) ?? text })), [t]);
  return localizedChannelStatuses;
}

const useLocalizedChannelVisibilityStatuses = () => {
  const { t } = useTranslation();
  const localizedChannelVisibilityStatuses = useMemo(() => channelVisibilityStatuses.map(({ key, text, translationKey }) => ({ key, text: t(translationKey) ?? text })), [t]);
  return localizedChannelVisibilityStatuses;
}

export const ManageChannelView = () => {
  const { t } = useTranslation();
  const localizedChannelStatuses = useLocalizedChannelStatuses();
  const localizedChannelVisibilityStatuses = useLocalizedChannelVisibilityStatuses();
  const classNames = getClassNames()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { channelIdentifier } = useParams<{ channelIdentifier: ChannelIdentifier }>()
  const { channels: userChannels } = useAppSelector(selectUserChannelsState)

  const [state, setState] = useState<{ channel?: Channel | null; isLoading: boolean }>({
    isLoading: true,
    channel: undefined,
  })

  const loadChannel = useCallback(
    async (channelIdentifier: ChannelIdentifier): Promise<void> => {
      try {
        const response = await ApiClient.getChannelAsync(channelIdentifier, { secure: true })
        setState({ channel: response.data.channel, isLoading: false })
        if (response.data.channel.status === ChannelStatus.Updating) {
          setTimeout(loadChannel, 1000, channelIdentifier)
        }
      } catch {
        dispatch(removeChannel(channelIdentifier))
        setState({ channel: null, isLoading: false })
      }
    },
    [dispatch],
  )

  useEffect(() => {
    loadChannel(channelIdentifier!);
  }, [loadChannel, channelIdentifier])

  const onChannelDelete = async (): Promise<void> => {
    setState({ ...state, isLoading: true })
    try {
      if (state.channel) {
        await ApiClient.deleteChannelAsync(state.channel.channelId)
      }
      const newState = { ...state, isLoading: false }
      newState.channel!.status = ChannelStatus.Updating
      setState(newState)
      setTimeout(loadChannel, 1000, channelIdentifier)
    } catch (e) {
      setState({ ...state, isLoading: false })
    }
  }

  const changeChannelVisibility = async (): Promise<void> => {
    if (!state.channel) {
      return
    }

    setState({ ...state, isLoading: true })
    try {
      const newVisibilityStatus =
        state.channel?.visibilityStatus === ChannelVisibilityStatus.Public
          ? ChannelVisibilityStatus.Private
          : ChannelVisibilityStatus.Public
      const result = await ApiClient.updateChannelVisibilityAsync(state.channel.channelId, {
        visibilityStatus: newVisibilityStatus,
      })
      setState(x => ({ ...x, isLoading: false, channel: result.data.channel }))
    } catch (e) {
      setState({ ...state, isLoading: false })
    }
  }

  const onChannelFields = (): void => {
    if (state.channel) {
      navigate(`/manage/channel/${state.channel!.name}/fields`)
    }
  }

  const navigateToChannel = (): void => {
    if (state.channel) {
      navigate(`/channel/${state.channel!.name}`)
    }
  }

  const createChannelPost = (): void => {
    if (state.channel) {
      navigate(`/manage/channel/${state.channel!.name}/post`)
    }
  }

  const onChannelElements = (): void => {
    if (state.channel) {
      navigate(`/manage/channel/${state.channel!.name}/sections`)
    }
  }

  // const onChannelFilters = (): void => {
  //   if (state.channel) {
  //     navigate(`/manage/channel/${state.channel!.name}/filters`)
  //   }
  // }

  const isManagedChannel =
    state.channel && userChannels?.findIndex(x => x.channelId === state.channel!.channelId) !== -1

  return (
    <CardList>
      <Card>
        {state.channel === undefined && <Spinner label={t('shared.loading')} />}
        {state.channel === null && <div>{t('pages.manage_channel.channel_not_found')}</div>}
        {state.channel && !isManagedChannel && <div>{t('pages.manage_channel.channel_not_allowed')}</div>}
        {state.channel && isManagedChannel && (
          <>
            {/* <ChannelNameHeader name={`Manage @${state.channel.name}`} /> */}
            <ChannelNameHeader name={t('pages.manage_channel.header', { channel: state.channel.name })} />
            <Stack className={classNames.container}>
              <div>
                {t(
                  "pages.manage_channel.channel_info",
                  {
                    status: localizedChannelStatuses.find(x => x.key === state.channel?.status)?.text ?? "Unknown",
                    visibility: localizedChannelVisibilityStatuses.find(x => x.key === state.channel?.visibilityStatus)?.text ?? "Unknown",
                  }
                )}
              </div>
              <br />
              <Stack
                tokens={{ childrenGap: 10 }}
                verticalAlign='center'
                horizontal
                horizontalAlign='start'
                className={classNames.block}
              >
                <PrimaryButton
                  disabled={state.channel.status === ChannelStatus.Updating}
                  onClick={onChannelFields}
                  text={t("pages.manage_channel.update_fields")} />
                <div>
                  {t("pages.manage_channel.fields_counter", { fields: state.channel.fields.length })}
                </div>
              </Stack>
              <br />
              <Stack
                tokens={{ childrenGap: 10 }}
                verticalAlign='center'
                horizontal
                horizontalAlign='start'
                className={classNames.block}
              >
                <PrimaryButton
                  disabled={state.channel.status === ChannelStatus.Updating}
                  onClick={onChannelElements}
                  text={t("pages.manage_channel.update_sections")} />
                <div>
                  {t("pages.manage_channel.sections_counter", { sections: state.channel.postSections.length })}
                </div>
              </Stack>
              <br />
              <Stack horizontal>
                <PrimaryButton onClick={navigateToChannel} text={t("pages.manage_channel.navigate_to_channel")} />
              </Stack>
              <br />
              {/* <Stack horizontal>
                <PrimaryButton disabled={true}>Update metadata</PrimaryButton>
              </Stack>
              <br /> */}
              {/* <Stack horizontal>
                <PrimaryButton
                  disabled={
                    state.channel.status === ChannelStatus.Updating ||
                    state.channel.fields.length === 0
                  }
                  onClick={onChannelFilters}
                >Update filters</PrimaryButton>
              </Stack>
              <br /> */}
              <Stack horizontal>
                <PrimaryButton disabled={state.channel.status === ChannelStatus.Updating} onClick={changeChannelVisibility}>
                  {state.channel.visibilityStatus === ChannelVisibilityStatus.Private ? t("pages.manage_channel.make_public") : t("pages.manage_channel.make_private")}
                </PrimaryButton>
              </Stack>
              <br />
              <Stack horizontal>
                <PrimaryButton
                  disabled={
                    state.channel.status === ChannelStatus.Updating ||
                    state.channel.fields.length === 0 ||
                    state.channel.postSections.length === 0
                  }
                  onClick={createChannelPost}
                  text={t("pages.manage_channel.add_post")} />
              </Stack>
              <br />
              <Stack horizontal>
                <PrimaryButton disabled={state.channel.status === ChannelStatus.Updating} onClick={onChannelDelete} text={t("pages.manage_channel.delete_channel")} />
              </Stack>
            </Stack>
          </>
        )}
      </Card>
    </CardList>
  )
}
