import { FieldType, Filter, FilterOperationType, FilterType, TextFilter } from "@notidar/api"
import { AllFields } from "../../utils"
import { FilterTypeToFilter, FilterTypeUnion } from "../../models"

export interface FilterEditorComponentProps<TFilterType extends FilterTypeUnion> {
  fields: AllFields[]
  filter: Partial<FilterTypeToFilter<TFilterType>> & Filter
  hidden: boolean
  onUpdate: (filter: Partial<FilterTypeToFilter<TFilterType>> & Filter, isValid: boolean) => void
}

export const allowedFilterTypes = [
  { key: FilterType.Enum, text: 'Enum' },
  { key: FilterType.Text, text: 'Text search' },
];

export const defaultFilterPerField: Record<Partial<FieldType>, Omit<Filter, "field" | "fieldType"> | undefined> = {
  [FieldType.String]: { type: FilterType.Text, operationType: FilterOperationType.TextSearch },
  [FieldType.Unknown]: undefined,
  [FieldType.Markdown]: { type: FilterType.Text, operationType: FilterOperationType.TextSearch },
  [FieldType.Link]: undefined,
  [FieldType.Bool]: undefined,
  [FieldType.Number]: { type: FilterType.Number, operationType: FilterOperationType.Between },
  [FieldType.Money]: { type: FilterType.Money, operationType: FilterOperationType.Between },
  [FieldType.DateTime]: undefined,
  [FieldType.Enum]: { type: FilterType.Enum, operationType: FilterOperationType.AnyIn },
  [FieldType.EnumList]: { type: FilterType.Enum, operationType: FilterOperationType.AllIn },
  [FieldType.StringList]: { type: FilterType.Text, operationType: FilterOperationType.TextSearch },
  [FieldType.LinkList]: undefined,
  [FieldType.ImageFileList]: undefined,
  [FieldType.FileList]: undefined,
  [FieldType.Measurement]: undefined,
};