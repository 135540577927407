import React, { useEffect } from 'react'
import { useMsal, useAccount } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { useMobileScreenDetection } from '../../hooks'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { selectSubscriptionsState, getSubscriptions } from '../../redux/features/subscriptionsSlice'
import { selectUserChannelsState, getUserChannels } from '../../redux/features/userChannelsSlice'
import { selectUserState, getUser } from '../../redux/features/userSlice'
import { ThemeProvider, createTheme } from '@fluentui/react'
import { Theme } from '@notidar/api'
import { useSystemThemeDetection } from '../../hooks/useSystemThemeDetection'
import { selectAppState } from '../../redux/features/appSlice'
import { AppContentContext } from '@notidar/content/src/context/appContentContext'
import { loadCurrency, selectCurrenciesState } from '../../redux/features/currenciesSlice'
import { CurrencyConversionContext } from '@notidar/content/src/fields/money/CurrencyConversionContext'

const lightTheme = createTheme({
  defaultFontStyle: {
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI Regular Cyrillic", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
  },
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
})

const darkTheme = createTheme({
  isInverted: true,
  defaultFontStyle: {
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI Regular Cyrillic", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
  },
  palette: {
    themePrimary: '#2f81f7',
    themeLighterAlt: '#02050a',
    themeLighter: '#081528',
    themeLight: '#0e274a',
    themeTertiary: '#1c4e94',
    themeSecondary: '#2973da',
    themeDarkAlt: '#438ff8',
    themeDark: '#60a0f9',
    themeDarker: '#89b8fb',
    neutralLighterAlt: '#262626',
    neutralLighter: '#2f2f2f',
    neutralLight: '#3d3d3d',
    neutralQuaternaryAlt: '#464646',
    neutralQuaternary: '#4d4d4d',
    neutralTertiaryAlt: '#6b6b6b',
    neutralTertiary: '#f8f8f8',
    neutralSecondary: '#f9f9f9',
    neutralSecondaryAlt: '#f9f9f9',
    neutralPrimaryAlt: '#fafafa',
    neutralPrimary: '#f6f6f6',
    neutralDark: '#fdfdfd',
    black: '#fefefe',
    white: '#1c1c1c',
  },
})

export interface AppInitializerProps {
  children: React.ReactNode
}

export const AppInitializer: React.FC<AppInitializerProps> = ({ children }: AppInitializerProps) => {
  useMobileScreenDetection()
  useSystemThemeDetection()
  let account = useAccount()
  const { inProgress, accounts } = useMsal()
  const dispatch = useAppDispatch()
  const { status: subscriptionsStatus } = useAppSelector(selectSubscriptionsState)
  const { status: userChannelsStatus } = useAppSelector(selectUserChannelsState)
  const { status: userStatus, user } = useAppSelector(selectUserState)
  const { systemThemeType, screenType } = useAppSelector(selectAppState)
  const { currencies } = useAppSelector(selectCurrenciesState)

  useEffect(() => {
    if (account) {
      if (subscriptionsStatus === 'idle') {
        dispatch(getSubscriptions())
      }
      if (userChannelsStatus === 'idle') {
        dispatch(getUserChannels())
      }
      if (userStatus === 'idle') {
        dispatch(getUser())
      }
      if (user?.settings.currencyCode) {
        dispatch(loadCurrency(user.settings.currencyCode));
      }
    }
  }, [account, subscriptionsStatus, userStatus, userChannelsStatus, user?.settings.currencyCode, dispatch])

  useEffect(() => {
    if (user?.settings.currencyCode) {
      dispatch(loadCurrency(user.settings.currencyCode));
    }
  }, [user?.settings.currencyCode, dispatch]);

  if (inProgress !== InteractionStatus.None) {
    return null
  }

  if (account || accounts.length > 0) {
    if (subscriptionsStatus !== 'succeeded' || userStatus !== 'succeeded' || userChannelsStatus !== 'succeeded') {
      return null
    }
  }

  const userTheme = user?.settings.theme ?? Theme.System;
  const themeType = userTheme === Theme.System ? systemThemeType : userTheme === Theme.Dark ? 'dark' : 'light'
  const theme = themeType === 'dark' ? darkTheme : lightTheme
  const rates = user?.settings.currencyCode !== undefined && currencies[user.settings.currencyCode] !== undefined 
    ? currencies[user?.settings.currencyCode!]?.rates
    : undefined;

  return (
    <ThemeProvider style={{ height: '100%', colorScheme: themeType }} theme={theme}>
      <CurrencyConversionContext.Provider value={
        { 
          targetCurrency: user?.settings.currencyCode, 
          convertMoneyFrom: rates !== undefined
            ? (currencyCode, value) => value / rates[currencyCode] 
            : undefined,
          convertMoneyTo: rates !== undefined
            ? (currencyCode, value) => value * rates[currencyCode]
            : undefined
        }}>
        <AppContentContext.Provider value={
          {
            themeType: themeType,
            screenType: screenType,
            mediaBaseUrl: process.env.REACT_APP_FILE_URL ?? 'https://notidarprod.blob.core.windows.net/public'
          }}>
          {children}
        </AppContentContext.Provider>
      </CurrencyConversionContext.Provider>
    </ThemeProvider>
  )
}
