import { SearchBox, IconButton, Stack } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

export interface SearchPanelProps {
  target: 'search' | 'rss',
  term: string,
  onTermChange?: (term: string) => void,
  onTermSubmit?: (term: string) => void,
  onTargetChange?: (target: 'search' | 'rss') => void
}

export const SearchPanel: React.FC<SearchPanelProps> = ({ onTargetChange, onTermSubmit, onTermChange, target, term }: SearchPanelProps) => {
  const { t } = useTranslation()

  return (
    <Stack horizontal horizontalAlign='start' wrap={false} tokens={{ childrenGap: 10 }}>
      <Stack.Item grow>
        {target === 'search' && (
          <SearchBox
            iconProps={{ iconName: 'Search' }}
            placeholder={t('pages.search.search_channel_placeholder')}
            value={term}
            onChange={(e, value) => onTermChange?.(value || '')}
            onSearch={onTermSubmit}
          />
        )}
        {target === 'rss' && (
          <SearchBox
            iconProps={{ iconName: 'custom_rss' }}
            placeholder={t('pages.search.rss_channel_placeholder')}
            value={term}
            onChange={(e, value) => onTermChange?.(value || '')}
            onSearch={onTermSubmit}
          />
        )}
      </Stack.Item>
      <Stack.Item disableShrink>
        {target === 'search' && (
          <IconButton
            iconProps={{ iconName: 'custom_rss' }}
            title={t('pages.search.rss_button_title')}
            onClick={() => onTargetChange?.('rss')}
          />
        )}
        {target === 'rss' && (
          <IconButton
            iconProps={{ iconName: 'Search' }}
            title={t('pages.search.search_button_title')}
            onClick={() => onTargetChange?.('search')}
          />
        )}
      </Stack.Item>
    </Stack>
  )
}
