import { PrimaryButton, Separator, Stack, Toggle, mergeStyleSets } from '@fluentui/react'
import { Channel, DateTimeField, EnumField, EnumListField, Field, FieldType, MoneyField, NumberField, Value } from '@notidar/api'
import { StringValueEditor } from './String/StringValueEditor'
import { useState } from 'react'
import { FieldKey } from '../../../redux/models'
import { DateTimeValueEditor } from './DateTime/DateTimeValueEditor'
import { MarkdownValueEditor } from './Markdown/MarkdownValueEditor'
import { LinkValueEditor } from './Link/LinkValueEditor'
import { ImageFileValueEditor } from './ImageFile/ImageValueEditor'
import { PostCard } from '@notidar/content/src/cards/PostCard'
import { EnumValueEditor } from './Enum/EnumValueEditor'
import { NumberValueEditor } from './Number/NumberValueEditor'
import { MoneyValueEditor } from './Money/MoneyValueEditor'
import { PostContentContext } from '@notidar/content/src/context/postContentContext'
import { notEmptyValue } from '@notidar/content'

export interface ValueListEditorProps {
  channel: Channel
  postId?: string,
  values?: Record<FieldKey, Value>
  disabled?: boolean
  onSave: (values: Record<FieldKey, Value>) => void
  onSaveText: string
  onDelete?: () => void
  onDeleteText?: string
  previewOnText: string
  previewOffText: string
}

const getClassNames = () => {
  return mergeStyleSets({
    actionButton: {
      margin: '0 10px 0 0',
    },
    toggle: {
      margin: '0',
    },
  })
}

export const ValueListEditor = ({ channel, values, disabled, onSave, onDelete, onDeleteText, onSaveText, previewOffText, previewOnText }: ValueListEditorProps) => {
  const classNames = getClassNames()
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [newValues, setNewValues] = useState<Record<FieldKey, Value>>(values ?? {})
  const [validationState, setValidationState] = useState<Record<FieldKey, boolean>>({})

  const onValueUpdate = (field: Field, value: Value): void => {
    let newValuesState = { ...newValues }
    if(notEmptyValue(value)) {
      newValuesState[field.name] = value
    } else {
      delete newValuesState[field.name]
    }
    setNewValues(newValuesState)
  }

  const onValueValidation = (field: Field, isValid: boolean): void => {
    if (validationState[field.name] !== isValid) {
      let newValidationState = { ...validationState }
      newValidationState[field.name] = isValid
      setValidationState(newValidationState)
    }
  }

  return (
    <Stack>
      {channel.fields.map((field, index) => {
        switch (field.type) {
          case FieldType.String:
            return (
              <StringValueEditor
                key={index}
                field={field}
                value={values?.[field.name]}
                onUpdate={onValueUpdate}
                onValidation={onValueValidation}
              />
            )
          case FieldType.Number:
            return (
              <NumberValueEditor
                key={index}
                field={field as NumberField}
                value={values?.[field.name]}
                onUpdate={onValueUpdate}
                onValidation={onValueValidation}
              />
            )
          case FieldType.Money:
            return (
              <MoneyValueEditor
                key={index}
                field={field as MoneyField}
                value={values?.[field.name]}
                onUpdate={onValueUpdate}
                onValidation={onValueValidation}
              />
            )
          case FieldType.DateTime:
            return (
              <DateTimeValueEditor
                key={index}
                field={field as DateTimeField}
                value={values?.[field.name]}
                onUpdate={onValueUpdate}
                onValidation={onValueValidation}
              />
            )
          case FieldType.Markdown:
            return (
              <MarkdownValueEditor
                key={index}
                field={field}
                value={values?.[field.name]}
                onUpdate={onValueUpdate}
                onValidation={onValueValidation}
              />
            )
          case FieldType.Link:
            return (
              <LinkValueEditor
                key={index}
                field={field}
                value={values?.[field.name]}
                onUpdate={onValueUpdate}
                onValidation={onValueValidation}
              />
            )
          case FieldType.Enum:
          case FieldType.EnumList:
            return (
              <EnumValueEditor
                key={index}
                field={field as EnumField | EnumListField}
                value={values?.[field.name]}
                onUpdate={onValueUpdate}
                onValidation={onValueValidation}
              />
            )
          case FieldType.ImageFileList:
            return (
              <ImageFileValueEditor
                channelId={channel.channelId}
                key={index}
                field={field}
                value={values?.[field.name]}
                onUpdate={onValueUpdate}
                onValidation={onValueValidation}
              />
            )
          default:
            return undefined
        }
      })}
      <Separator />
      <Stack verticalAlign='center' horizontal>
        <PrimaryButton
          className={classNames.actionButton}
          onClick={() => onSave(newValues as Record<FieldKey, Value>)}
          disabled={disabled || Object.values(validationState).some(x => !x)}
          text={onSaveText}
        />
        {onDelete && onDeleteText && (
          <PrimaryButton 
            className={classNames.actionButton}
            onClick={onDelete} 
            disabled={disabled}
            text={onDeleteText}
          />
        )}
        <Toggle
          className={classNames.toggle}
          disabled={disabled}
          onText={previewOnText}
          offText={previewOffText}
          role='checkbox'
          defaultChecked={showPreview}
          onChange={(_, checked) => setShowPreview(!!checked)}
        />
      </Stack>
      {showPreview && (
        <>
          <Separator />
          <PostContentContext.Provider value={{ channelId: channel.channelId }}>
            <PostCard
              channel={channel}
              post={{ values: newValues, channelId: channel.channelId, postId: '' }}
              enableManagement={false}
              navigate={() => { }}
            />
          </PostContentContext.Provider>
        </>
      )}
    </Stack>
  )
}
