import * as React from 'react'
import { mergeStyleSets } from '@fluentui/react'

const getClassNames = () => {
  return mergeStyleSets({
    innerContainer: {
      margin: '0 auto',
      maxWidth: 1024,
      minWidth: 260, // for Galaxy Fold 280 = 260 + 10 + 10
      padding: 10,
    },
  })
}

export interface CentralizedViewProps {
  children: React.ReactNode
}

export const CentralizedView: React.FC<CentralizedViewProps> = ({ children }: CentralizedViewProps) => {
  const classNames = getClassNames()
  return (
    <div className={classNames.innerContainer}>{children}</div>
  )
}
