import { configureStore } from '@reduxjs/toolkit'
import channelReducer from './features/channelSlice'
import appReducer from './features/appSlice'
import postsReducer from './features/postsSlice'
import channelSearchSlice from './features/channelSearchSlice'
import subscriptionsSlice from './features/subscriptionsSlice'
import userChannelsSlice from './features/userChannelsSlice'
import userSlice from './features/userSlice'
import currenciesSlice from './features/currenciesSlice'

const store = configureStore({
  preloadedState: {},
  reducer: {
    channel: channelReducer,
    app: appReducer,
    posts: postsReducer,
    channelSearch: channelSearchSlice,
    subscriptions: subscriptionsSlice,
    userChannels: userChannelsSlice,
    user: userSlice,
    currencies: currenciesSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
