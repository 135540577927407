import { Separator, Text, mergeStyleSets } from '@fluentui/react'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: '15px 0 5px 0',
    },
  })
}

export interface ItemHeaderProps {
  title?: string | null
  name: string
}

export const ItemHeader = ({ title, name }: ItemHeaderProps) => {
  const classNames = getClassNames()
  return (
    <div className={classNames.container}>
      <Separator alignContent='center'>
        <Text variant={'large'} nowrap block>
          {title ? `${title} (${name})` : `${name}`}
        </Text>
      </Separator>
    </div>
  )
}
