import { Image, ImageCoverStyle, ImageFit, mergeStyleSets } from '@fluentui/react'

const getClassNames = () => {
  return mergeStyleSets({
    imageContainer: {
      flexGrow: 0,
      flexShrink: 0,
      width: 100,
      height: 100,
      img: {},
    },
  })
}

export interface ImageFilePreviewProps {
  imageUrl: string,
}

export const ImageFilePreview = ({ imageUrl }: ImageFilePreviewProps) => {
  const classNames = getClassNames()

  return (
    <Image
      className={classNames.imageContainer}
      imageFit={ImageFit.contain}
      coverStyle={ImageCoverStyle.portrait}
      src={imageUrl}
    />
  )
}
