import { Stack, mergeStyleSets, IconButton, IButtonStyles } from '@fluentui/react'
import { FontSizes } from '@fluentui/theme'
import { useAppDispatch } from '../../../redux/hooks'
import { RightSideBarType, hideRightSideBar } from '../../../redux/features/appSlice'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    header: {
      fontSize: FontSizes.xLargePlus,
      fontWeight: 600,
      padding: '0 10px',
      cursor: 'pointer',
    },
    container: {
      height: '100%',
    },
  })
}

const buttonIconStyles: IButtonStyles = {
  root: {
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 20,
  },
}

export interface HeaderProps {
  type: RightSideBarType
}

export const Header = ({ type }: HeaderProps) => {
  const { t } = useTranslation()
  const classNames = getClassNames()
  const dispatch = useAppDispatch()

  const onHideRightSideBar = () => {
    dispatch(hideRightSideBar())
  }

  let title = ''
  switch (type) {
    case RightSideBarType.Filters:
      title = t('header.filters')
      break
    case RightSideBarType.Notifications:
      title = t('header.notifications')
      break
    case RightSideBarType.Toggles:
      title = t('header.toggles')
      break
    default:
      break
  }

  return (
    <Stack
      horizontal
      horizontalAlign='start'
      verticalAlign='center'
      className={classNames.container}
    >
      <IconButton
        onClick={onHideRightSideBar}
        styles={buttonIconStyles}
        iconProps={{ iconName: 'ChevronRight' }}
      />
      <h1 className={classNames.header}>{title}</h1>
    </Stack>
  )
}
