import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiClient } from '../../api'
import type { RootState } from '../store'
import { OperationStatus } from '../models'
import { CurrencyCode, ErrorCode, ErrorCodes } from '@notidar/api'

interface CurrencyState {
  status: OperationStatus
  error: ErrorCode | null
  rates?: Record<CurrencyCode, number>
  requestId: string
}

interface CurrenciesState {
  currencies: Partial<Record<CurrencyCode, CurrencyState>>
}

const initialState: CurrenciesState = {
  currencies: {}
}

export const loadCurrency = createAsyncThunk<Record<CurrencyCode, number>, CurrencyCode, {state: RootState }>(
  'currencies/loadCurrency',
  async (currencyCode, thunkAPI) => {
    const { currencies } = selectCurrenciesState(thunkAPI.getState())
    const currentState = currencies[currencyCode];

    if(currentState?.status !== 'loading' || currentState?.requestId !== thunkAPI.requestId) {
      return thunkAPI.rejectWithValue(ErrorCodes.UNKNOWN); 
    } else {
      const response = await ApiClient.getCurrencyRates(
        currencyCode,
        { signal: thunkAPI.signal }
      );
      return response.data.rates as Record<CurrencyCode, number>;
    }
  }
)

export const currenciesReducer = createSlice({
  name: 'currency',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(loadCurrency.rejected, (state, action) => {
        const currentState = state.currencies[action.meta.arg];
        if(currentState?.requestId === action.meta.requestId) {
          currentState.status = 'failed';
        }
      })
      .addCase(loadCurrency.pending, (state, action) => {
        if(state.currencies[action.meta.arg] === undefined){
          state.currencies[action.meta.arg] = {
            error: null,
            status: 'loading',
            rates: undefined,
            requestId: action.meta.requestId
          }
        }

        const currentState = state.currencies[action.meta.arg]!;

        if(currentState.status === 'failed') {
          currentState.error = null;
          currentState.rates = undefined;
          currentState.requestId = action.meta.requestId;
          currentState.status = 'loading'
        }
      })
      .addCase(loadCurrency.fulfilled, (state, action) => {
        const currentState = state.currencies[action.meta.arg];
        if(currentState) {
          currentState.status = 'succeeded';
          currentState.rates = action.payload;
        }
      })
  },
})

export const selectCurrenciesState = (state: RootState) => state.currencies;

export default currenciesReducer.reducer