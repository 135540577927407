import { EnumFilter, FilterType, MoneyFilter, NumberFilter, TextFilter } from '@notidar/api'
import { AllFields, AllFilters } from '../utils'
import { TextFilterComponent } from './text/render/TextFilterComponent'
import { EnumFilterComponent } from './enum/render/EnumFilterComponent'
import { NumberFilterComponent } from './number/render/NumberFilterComponent'
import { MoneyFilterComponent } from './money/render/MoneyFilterComponent'

export interface FilterComponentProps {
  fields: AllFields[]
  filter: AllFilters
  onSubmit: (filter: AllFilters) => void
}

export const FilterComponent = ({ fields, filter, onSubmit }: FilterComponentProps): JSX.Element | null => {
  switch (filter.type) {
    case FilterType.Text:
      return <TextFilterComponent filter={filter as TextFilter} fields={fields} onSubmit={onSubmit} />
    case FilterType.Enum:
      return <EnumFilterComponent filter={filter as EnumFilter} fields={fields} onSubmit={onSubmit} />
    case FilterType.Number:
      return <NumberFilterComponent filter={filter as NumberFilter} fields={fields} onSubmit={onSubmit} />
    case FilterType.Money:
      return <MoneyFilterComponent filter={filter as MoneyFilter} fields={fields} onSubmit={onSubmit} />
    default:
      return null
  }
}
