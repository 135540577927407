import { ITheme, IconButton, NeutralColors, Stack, Text, mergeStyleSets, useTheme } from '@fluentui/react'
import { useState } from 'react'
import {
  ActionsPostSection,
  AuthorPostSection,
  Field,
  GalleryPostSection,
  MarkdownPostSection,
  PostSection,
  PostSectionType,
  PropertiesPostSection,
  TagsPostSection,
  TitlePostSection,
} from '@notidar/api'
import { TitleSectionEditorComponent } from './title'
import { MarkdownSectionEditorComponent } from './markdown/MarkdownSectionEditorComponent'
import { GallerySectionEditorComponent } from './gallery/GallerySectionEditorComponent'
import { ActionsSectionEditorComponent } from './actions/ActionsSectionEditorComponent'
import { TagsSectionEditorComponent } from './tags/TagsSectionEditorComponent'
import { AuthorSectionEditorComponent } from './author/AuthorSectionEditorComponent'
import { useLocalizedSectionTypes } from './Shared.types'
import { PropertiesSectionEditor } from './properties'
import { useTranslation } from 'react-i18next'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    header: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
    },
    headerText: {
      margin: '0 10px 0',
    },
    formContainer: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      padding: '0 10px 10px',
    },
  })
}

export interface SectionEditorComponentProps {
  fields: Field[]
  section: PostSection
  onUpdate: (section: PostSection, isValid: boolean) => void
  onRemove: (section: PostSection) => void
}

export const SectionEditorComponent = ({ fields, section, onUpdate, onRemove }: SectionEditorComponentProps) => {
  const { t } = useTranslation();
  const theme = useTheme()
  const classNames = getClassNames(theme)
  const [state, setState] = useState<{
    isValid?: boolean
  }>({})
  const [isHidden, setIsHidden] = useState<boolean>(true)
  const allowedSectionTypes = useLocalizedSectionTypes();

  const onUpdateInternal = (section: PostSection, isValid: boolean): void => {
    setState({ isValid: isValid })
    if (isHidden && !isValid) {
      setIsHidden(false)
    }
    onUpdate(section, isValid)
  }

  const getEditor = (section: PostSection, fields: Field[]): JSX.Element | null => {
    switch (section.type) {
      case PostSectionType.Title:
        return (
          <TitleSectionEditorComponent
            fields={fields}
            section={section as TitlePostSection}
            hidden={isHidden}
            onUpdate={onUpdateInternal}
          />
        )
      case PostSectionType.Author:
        return (
          <AuthorSectionEditorComponent
            fields={fields}
            section={section as AuthorPostSection}
            hidden={isHidden}
            onUpdate={onUpdateInternal}
          />
        )
      case PostSectionType.Markdown:
        return (
          <MarkdownSectionEditorComponent
            fields={fields}
            section={section as MarkdownPostSection}
            hidden={isHidden}
            onUpdate={onUpdateInternal}
          />
        )
      case PostSectionType.Tags:
        return (
          <TagsSectionEditorComponent
            fields={fields}
            section={section as TagsPostSection}
            hidden={isHidden}
            onUpdate={onUpdateInternal}
          />
        )
      case PostSectionType.Gallery:
        return (
          <GallerySectionEditorComponent
            fields={fields}
            section={section as GalleryPostSection}
            hidden={isHidden}
            onUpdate={onUpdateInternal}
          />
        )
      case PostSectionType.Actions:
        return (
          <ActionsSectionEditorComponent
            fields={fields}
            section={section as ActionsPostSection}
            hidden={isHidden}
            onUpdate={onUpdateInternal}
          />
        )
      case PostSectionType.Properties:
        return (
          <PropertiesSectionEditor
            fields={fields}
            section={section as PropertiesPostSection}
            hidden={isHidden}
            onUpdate={onUpdateInternal}
          />
        )
      default:
        return null
    }
  }

  const switchIsHidden = () => {
    setIsHidden(x => !x)
  }

  const remove = () => {
    onRemove(section)
  }

  return (
    <Stack>
      <Stack
        className={classNames.header}
        style={isHidden ? {} : { backgroundColor: theme.palette.neutralQuaternary }}
        horizontal
        verticalAlign='center'
        horizontalAlign='space-between'
      >
        <Stack horizontal verticalAlign='center'>
          <Text
            className={classNames.headerText}
            style={state.isValid === false ? { color: 'rgb(164, 38, 44)' } : {}}
            variant={'large'}
            nowrap
            block
          >
            {allowedSectionTypes.find(x => x.key === section.type)?.text}
          </Text>
          <IconButton iconProps={{ iconName: 'Cancel' }} title={t("shared.remove")} onClick={remove} />
        </Stack>
        <IconButton
          iconProps={{ iconName: isHidden ? 'ChevronDown' : 'ChevronUp' }}
          title={isHidden ? t("shared.expand") : t("shared.collapse")}
          onClick={switchIsHidden}
        />
      </Stack>
      <div className={isHidden ? undefined : classNames.formContainer}>{getEditor(section, fields)}</div>
    </Stack>
  )
}
