import { AllFields, AllValues } from '../../Utils'
import { ITheme, Stack, mergeStyleSets, useTheme } from '@fluentui/react'
import { IconType, PropertiesPostSectionType } from '@notidar/api'
import { PropertiesSectionItemComponent } from './PropertiesSectionItemComponent'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    container: {
      containerType: 'inline-size',
    },
    manyItems: {
      width: '100%',
      '@container (min-width: 400px)': {
        width: '50%',
      },
      '@container (min-width: 800px)': {
        width: '33%',
      },
    },
    twoItems: {
      width: '100%',
      '@container (min-width: 400px)': {
        width: '50%',
      }
    },
    singleItem: {
      width: '100%',
    },
  })
}

export interface PropertiesSectionItemsComponentProps {
  items: { field: AllFields; value: AllValues; icon: IconType }[]
  displayType: PropertiesPostSectionType
}

export const PropertiesSectionItemsComponent = ({
  items,
  displayType,
}: PropertiesSectionItemsComponentProps): JSX.Element | null => {
  const theme = useTheme()
  const classNames = getClassNames(theme)
  const classToUse = items.length > 2 ? classNames.manyItems : (items.length === 2 ? classNames.twoItems : classNames.singleItem)
  const elements = items?.map((item, index) => {
    return (
      <Stack key={index} horizontal horizontalAlign='center' className={classToUse}>
        <PropertiesSectionItemComponent
          field={item.field}
          icon={item.icon}
          displayType={displayType}
          value={item.value}
        />
      </Stack>
    )
  })

  return (
    <Stack horizontal wrap horizontalAlign='start' className={classNames.container}>
      {elements}
    </Stack>
  )
}
