import { Stack, TextField } from '@fluentui/react'
import { FieldType } from '@notidar/api'
import { ValueEditorProps } from '../Shared.types'
import { ItemHeader } from '../../Shared/ItemHeader'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface LinkValueEditorProps extends ValueEditorProps<FieldType.Link> {}

export const LinkValueEditor = ({ field, value, onUpdate, onValidation }: LinkValueEditorProps) => {
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState<string | undefined>(value?.linkPayload?.link ?? undefined)
  
  const onValueChange = (
    _?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const result = newValue || ''
    setNewValue(result)
    onUpdate(field, { type: FieldType.Link, linkPayload: { link: result} })
    onValidation(field, true)
  }

  return (
    <Stack>
      <ItemHeader name={field.name} title={field.displayName} />
      <TextField
          value={newValue}
          placeholder={t("content.fields.link.placeholder")}
          validateOnLoad={false}
          deferredValidationTime={500}
          onChange={onValueChange}
        />
    </Stack>
  )
}
