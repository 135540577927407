import { DefaultButton, Dropdown, IDropdownOption, Label, PrimaryButton, Stack } from '@fluentui/react'
import {
  Field,
  FieldType,
  PropertiesPostSectionItem,
  IconType,
} from '@notidar/api'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const allowedIcons = [
  { key: IconType.RadioBullet, text: 'Radio button', translationKey: 'content.sections.properties.icons.radio' },
  { key: IconType.Phone, text: 'Phone', translationKey: 'content.sections.properties.icons.phone' },
  { key: IconType.Mail, text: 'Mail', translationKey: 'content.sections.properties.icons.mail' },
  { key: IconType.Location, text: 'Location', translationKey: 'content.sections.properties.icons.location' },
  { key: IconType.Link, text: 'Link', translationKey: 'content.sections.properties.icons.link' },
]

export const useLocalizedIcons = () => {
  const { t } = useTranslation();
  const localizedFields = useMemo(() => allowedIcons.map(({ key, text, translationKey }) => ({ key, text: t(translationKey) ?? text })), [t]);
  return localizedFields;
}

export interface CreatePropertyItemFormProps {
  fields: Field[]
  onAdd: (item: PropertiesPostSectionItem) => void
}

export const CreatePropertyItemForm = ({ fields, onAdd }: CreatePropertyItemFormProps) => {
  const { t } = useTranslation();
  const allowedIconsTypes = useLocalizedIcons();
  const [state, setState] = useState<Partial<PropertiesPostSectionItem>>({
    icon: IconType.RadioBullet,
  })
  const [validationState, setValidationState] = useState<{
    isIconValid: boolean
    isFieldValid: boolean
  }>({ isIconValid: true, isFieldValid: false })

  const allowedPropertyFields = fields
    .filter(x => [FieldType.Money, FieldType.Number, FieldType.Enum].includes(x.type))
    .map(x => ({ key: x.name, text: x.displayName ? `${x.displayName} (${x.name})` : x.name }))

  const onClick = (): void => {
    setValidationState({ isFieldValid: false, isIconValid: true })
    setState({ icon: IconType.RadioBullet })
    onAdd(state as PropertiesPostSectionItem)
  }

  const onFieldChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    setState(x => ({ ...x, propertyField: option?.key as string }))
    setValidationState(x => ({ ...x, isFieldValid: true }))
  }

  const onIconChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    setState(x => ({ ...x, icon: option?.key as IconType }))
    setValidationState(x => ({ ...x, isIconValid: true }))
  }

  const onPropertyClear = (): void => {
    setState(x => ({ ...x, propertyField: undefined, icon: IconType.RadioBullet }))
    setValidationState(x => ({ ...x, isIconValid: true, isFieldValid: false }))
  }

  return (
    <Stack>
      <Label>{t("content.sections.properties.properties_add_title")}</Label>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <PrimaryButton
          disabled={!validationState.isFieldValid || !validationState.isIconValid}
          onClick={onClick}
          text={t("content.sections.properties.properties_add")}
        />
        <Dropdown
          key={state.propertyField}
          disabled={allowedPropertyFields.length === 0}
          onChange={onFieldChange}
          selectedKey={state.propertyField}
          placeholder={allowedPropertyFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field")}
          options={allowedPropertyFields}
        />
        <Dropdown
          key={state.icon}
          onChange={onIconChanged}
          selectedKey={state.icon}
          placeholder={t("content.sections.properties.properties_icon_placeholder")}
          options={allowedIconsTypes}
        />
        <DefaultButton 
          onClick={onPropertyClear} 
          text={t("content.sections.shared.clear")}
        />
      </Stack>
    </Stack>
  )
}
