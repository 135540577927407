import { DefaultButton, Dropdown, IDropdownOption, IStackTokens, Stack } from '@fluentui/react'
import { FieldType, PostSection, PostSectionType, TitlePostSection } from '@notidar/api'
import { SectionEditorComponentProps } from '../../Shared.types'
import { useEffect, useState } from 'react'
import { getFieldByFieldKey } from '../../../utils'
import { useTranslation } from 'react-i18next'

const stackTokens: IStackTokens = { childrenGap: 10 }

const isValid = (section: Partial<TitlePostSection> & PostSection): boolean => {
  return section.titleField !== undefined
}

export interface TitleSectionEditorComponentProps extends SectionEditorComponentProps<PostSectionType.Title> { }

export const TitleSectionEditorComponent = ({
  fields,
  hidden,
  onUpdate,
  section,
}: TitleSectionEditorComponentProps) => {
  const { t } = useTranslation();
  const [sectionState, setSectionState] = useState<Partial<TitlePostSection> & PostSection>(section)

  const allowedTitleSubtitleFields = fields
    .filter(x => x.type === FieldType.String)
    .map(x => ({ key: x.name, text: x.displayName ? `${x.displayName} (${x.name})` : x.name }))

  const allowedLinkFields = fields
    .filter(x => x.type === FieldType.Link)
    .map(x => ({ key: x.name, text: x.displayName ? `${x.displayName} (${x.name})` : x.name }))

  useEffect(() => {
    onUpdate(sectionState, isValid(sectionState))
  }, [sectionState]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTitleChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    const newState: Partial<TitlePostSection> & PostSection = {
      ...sectionState,
      titleField: option?.key as string,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onTitleClear = (): void => {
    const newState: Partial<TitlePostSection> & PostSection = {
      ...sectionState,
      titleField: undefined,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onLinkChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    const newState: Partial<TitlePostSection> & PostSection = {
      ...sectionState,
      linkField: option?.key as string,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onLinkClear = (): void => {
    const newState: Partial<TitlePostSection> & PostSection = {
      ...sectionState,
      linkField: undefined,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onSubtitleChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    const newState: Partial<TitlePostSection> & PostSection = {
      ...sectionState,
      subtitleField: option?.key as string,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onSubtitleClear = (): void => {
    const newState: Partial<TitlePostSection> & PostSection = {
      ...sectionState,
      subtitleField: undefined,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const titleField = getFieldByFieldKey({ fields }, sectionState.titleField)
  const linkField = getFieldByFieldKey({ fields }, sectionState.linkField)
  const subtitleField = getFieldByFieldKey({ fields }, sectionState.subtitleField)

  if (hidden) {
    return null
  }

  return (
    <Stack>
      <Stack tokens={stackTokens} horizontal wrap verticalAlign='end'>
        <Dropdown
          key={sectionState.titleField}
          disabled={allowedTitleSubtitleFields.length === 0}
          onChange={onTitleChange}
          selectedKey={titleField ? sectionState.titleField : undefined}
          placeholder={allowedTitleSubtitleFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field")}
          options={allowedTitleSubtitleFields}
          label={t("content.sections.title.title_field")}
        />
        <DefaultButton 
          disabled={!sectionState.titleField} 
          onClick={onTitleClear}
          text={t("content.sections.shared.clear")}
        />
      </Stack>
      <Stack tokens={stackTokens} horizontal wrap verticalAlign='end'>
        <Dropdown
          key={sectionState.linkField}
          disabled={allowedLinkFields.length === 0}
          onChange={onLinkChange}
          selectedKey={linkField ? sectionState.linkField : undefined}
          placeholder={allowedLinkFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field")}
          options={allowedLinkFields}
          label={t("content.sections.title.link_field")}
        />
        <DefaultButton 
          disabled={!sectionState.linkField}
          onClick={onLinkClear}
          text={t("content.sections.shared.clear")}
        />
      </Stack>
      <Stack tokens={stackTokens} horizontal wrap verticalAlign='end'>
        <Dropdown
          key={sectionState.subtitleField}
          disabled={allowedTitleSubtitleFields.length === 0}
          onChange={onSubtitleChange}
          selectedKey={subtitleField ? sectionState.subtitleField : undefined}
          placeholder={allowedTitleSubtitleFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field")}
          options={allowedTitleSubtitleFields}
          label={t("content.sections.title.subtitle_field")}
        />
        <DefaultButton
          disabled={!sectionState.titleField}
          onClick={onTitleClear}
          text={t("content.sections.shared.clear")}
        />
      </Stack>
    </Stack>
  )
}
