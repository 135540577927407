import { ITheme, Label, mergeStyleSets, useTheme } from '@fluentui/react'
import { FontSizes } from '@fluentui/theme'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    rootClass: {
      borderRadius: 4,
      padding: '0, 8px',
      borderColor: theme.palette.neutralQuaternary,
      borderStyle: 'solid',
      borderWidth: 1,
      background: theme.palette.neutralQuaternary,
    },
  })
}

export interface TagProps {
  value: string
}

export const Tag = ({ value }: TagProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme)

  return (
    <div className={classNames.rootClass}>
      <Label style={{ fontSize: FontSizes.smallPlus, padding: 0, lineHeight: '20px' }}>{value}</Label>
    </div>
  )
}
