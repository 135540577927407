import { PrimaryButton, mergeStyleSets } from '@fluentui/react'
import { PublisherAccountType, ReaderAccountType, SupportTicketType, User } from '@notidar/api'
import { useState } from 'react'
import { ApiClient } from '../../../../api'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
    title: {
      margin: 10,
    },
  })
}

export interface AccountInfoProps {
  user: User
}

export const AccountInfo: React.FC<AccountInfoProps> = ({ user }: AccountInfoProps) => {
  const classNames = getClassNames()
  const { t } = useTranslation()

  const [demoRequestedAllowed, setDemoRequestedAllowed] = useState<boolean>(
    user.publisherAccountType === PublisherAccountType.Basic || user.readerAccountType === ReaderAccountType.Basic,
  )
  const [supportTicketStatus, setSupportTicketStatus] = useState<'idle' | 'inProgress' | 'success'>('idle')

  const onDemoRequest = async (): Promise<void> => {
    setSupportTicketStatus('inProgress')
    try {
      await ApiClient.createSupportTicketAsync({
        subject: 'Please review demo request.',
        type: SupportTicketType.DemoRequest,
      })
      setDemoRequestedAllowed(false)
      setSupportTicketStatus('success')
    } catch (e) {
      setSupportTicketStatus('idle')
    }
  }

  const levels: {
    [key in PublisherAccountType | ReaderAccountType]: string
  } = {
    [PublisherAccountType.Basic]: t('pages.user.account_level_basic'),
    [PublisherAccountType.Demo]: t('pages.user.account_level_demo'),
    [PublisherAccountType.Premium]: t('pages.user.account_level_premium'),
    [PublisherAccountType.Enterprise]: t('pages.user.account_level_enterprise'),
  }

  return (
    <>
      <h2 className={classNames.title}>{t('pages.user.account_title')}</h2>
      <div className={classNames.container}>
        <p>{t('pages.user.reader_account_level', { level: levels[user.readerAccountType] })}</p>
        <p>{t('pages.user.publisher_account_level', { level: levels[user.publisherAccountType] })}</p>
        {demoRequestedAllowed && (
          <>
            <PrimaryButton
              disabled={supportTicketStatus === 'inProgress'}
              onClick={onDemoRequest}
              text={t('pages.user.request_demo_button')}
            />
            <p>{t('pages.user.request_demo_note')}</p>
          </>
        )}
        {supportTicketStatus === 'success' && <p>{t('pages.user.request_demo_success')}</p>}
      </div>
    </>
  )
}
