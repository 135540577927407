import { AllFields, AllValues } from '../../Utils'
import { ValueComponent } from '../../../fields/ValueComponent'
import { FontIcon, FontSizes, Stack, mergeStyleSets } from '@fluentui/react'
import { IconType, PropertiesPostSectionType } from '@notidar/api'
import { iconMap } from '../shared'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      width: '100%',
    },
    separator: {
      margin: '0 8px',
      fontSize: FontSizes.large,
    },
    key: {
      display: 'flex',
      justifyContent: 'flex-end',
      textAlign: 'right',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      wordWrap: 'break-word',
      overflow: 'hidden',
      fontSize: FontSizes.large,
    },
    value: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      wordWrap: 'break-word',
      overflow: 'hidden',
      fontSize: FontSizes.large,
    },
  })
}

export interface PropertiesSectionItemComponentProps {
  field: AllFields
  value: AllValues
  icon: IconType
  displayType: PropertiesPostSectionType
}

export const PropertiesSectionItemComponent = ({
  field,
  value,
  icon,
  displayType,
}: PropertiesSectionItemComponentProps): JSX.Element | null => {
  const classNames = getClassNames()

  return (
    <Stack horizontal className={classNames.container} verticalAlign='center'>
      <Stack.Item basis={displayType === PropertiesPostSectionType.Icons ? undefined : '50%'} className={classNames.key}>
        {displayType === PropertiesPostSectionType.Icons ? <FontIcon iconName={iconMap[icon]} /> : field.displayName ?? field.name}
      </Stack.Item>
      <Stack.Item className={classNames.separator}>{displayType === PropertiesPostSectionType.Icons ? '' : ':'}</Stack.Item>
      <Stack.Item basis={displayType === PropertiesPostSectionType.Icons ? undefined : '50%'} className={classNames.value}>
        <ValueComponent field={field} value={value} />
      </Stack.Item>
    </Stack>
  )
}
