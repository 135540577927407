import { Filter, FilterType, TextFilter } from '@notidar/api'
import { AllFields } from '../utils'
import { ITheme, IconButton, Stack, Text, mergeStyleSets, useTheme } from '@fluentui/react'
import { TextSearchFilterEditorComponent } from './text/editor/TextSearchFilterEditorComponent'
import { useState } from 'react'
import { allowedFilterTypes } from './shared/shared.types'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    header: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
    },
    headerText: {
      margin: '0 10px 0',
    },
    formContainer: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      padding: '0 10px 10px',
    },
  })
}

interface FilterEditorComponentProps {
  fields: AllFields[]
  filter: Filter
  onUpdate: (filter: Filter, isValid: boolean) => void
  onRemove: (filter: Filter) => void
}

export const FilterEditorComponent = ({ filter, fields, onUpdate, onRemove }: FilterEditorComponentProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme)
  const [state, setState] = useState<{
    isValid?: boolean
  }>({})
  const [isHidden, setIsHidden] = useState<boolean>(true)

  const onUpdateInternal = (filter: Filter, isValid: boolean): void => {
    setState({ isValid: isValid })
    if (isHidden && !isValid) {
      setIsHidden(false)
    }
    onUpdate(filter, isValid)
  }

  const getEditor = (filter: Filter, fields: AllFields[]): JSX.Element | null => {
    switch (filter.type) {
      case FilterType.Text:
        return (
          <TextSearchFilterEditorComponent
            fields={fields}
            hidden={isHidden}
            onUpdate={onUpdateInternal}
            filter={filter as Partial<TextFilter> & Filter}
          />
        )
      default:
        return null
    }
  }

  const switchIsHidden = () => {
    setIsHidden(x => !x)
  }

  const remove = () => {
    onRemove(filter)
  }

  return (
    <Stack>
      <Stack
        className={classNames.header}
        style={isHidden ? {} : { backgroundColor: theme.palette.neutralQuaternary }}
        horizontal
        verticalAlign='center'
        horizontalAlign='space-between'
      >
        <Stack horizontal verticalAlign='center'>
          <Text
            className={classNames.headerText}
            style={state.isValid === false ? { color: 'rgb(164, 38, 44)' } : {}}
            variant={'large'}
            nowrap
            block
          >
            {allowedFilterTypes.find(x => x.key === filter.type)?.text}
          </Text>
          <IconButton iconProps={{ iconName: 'Cancel' }} title='Remove' onClick={remove} />
        </Stack>
        <IconButton
          iconProps={{ iconName: isHidden ? 'ChevronDown' : 'ChevronUp' }}
          title={isHidden ? 'Expand' : 'Hide'}
          onClick={switchIsHidden}
        />
      </Stack>
      <div className={isHidden ? undefined : classNames.formContainer}>{getEditor(filter, fields)}</div>
    </Stack>
  )
}