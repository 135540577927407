import { FontIcon, IconButton, Label, Stack } from '@fluentui/react'
import { Field, PropertiesPostSectionItem } from '@notidar/api'
import { iconMap } from '../shared'
import { useTranslation } from 'react-i18next'

export interface PropertyListItemProps {
  item: PropertiesPostSectionItem
  field?: Field
  onRemove: () => void
}

export const PropertyListItem = ({ item, field, onRemove }: PropertyListItemProps) => {
  const { t } = useTranslation();
  return (
    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign='center'>
      {item.icon ? <FontIcon iconName={iconMap[item.icon]} /> : <FontIcon iconName={iconMap.RadioBullet} />}
      {field ? <Label>{field.displayName ? `${field.displayName} (${field.name})` : `${field.name}`}</Label> : t("content.sections.properties.properties_field_not_found")}
      <IconButton iconProps={{ iconName: 'Cancel' }} title={t("content.sections.properties.properties_remove")} ariaLabel={t("content.sections.properties.properties_remove")} onClick={onRemove} />
    </Stack>
  )
}
