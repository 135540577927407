import { useAppSelector } from '../../../redux/hooks'
import { selectAppState } from '../../../redux/features/appSlice'
import { Stack, ITheme, mergeStyleSets, useTheme } from '@fluentui/react'
import { Body } from './Body'
import { Header } from './Header'
import { LeftPanel } from './LeftPanel'

const getClassNames = (theme: ITheme, isLeftSideBarVisible: boolean) => {
  return mergeStyleSets({
    sideBarContainer: {
      position: 'sticky',
      top: 0,
      height: '100%',
      borderWidth: '0px 1px 0px 0px',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      overflow: 'hidden',
      width: isLeftSideBarVisible ? undefined : 0,
    },
    leftPanel: {
      borderWidth: '0px 1px 0px 0px',
      borderStyle: 'solid',
      height: '100%',
      maxWidth: '60px',
      width: '60px',
      minWidth: '60px',
      borderColor: theme.palette.neutralQuaternary,
    },
    header: {
      height: '60px',
      borderWidth: '0px 0px 1px 0px',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
    },
    container: {
      width: '220px',
    },
    navigation: {
      overflow: 'auto',
      height: 'calc(100% - 61px)',
    }
  })
}

export const LeftSideBar = () => {
  const theme = useTheme()
  const appState = useAppSelector(selectAppState)
  const classNames = getClassNames(theme, appState.isLeftSideBarVisible)

  return (
    <Stack horizontal className={classNames.sideBarContainer}>
      <div className={classNames.leftPanel}>
        <LeftPanel />
      </div>
      <div className={classNames.container}>
        <div className={classNames.header}>
          <Header />
        </div>
        <div className={classNames.navigation}>
          <Body />
        </div>
      </div>
    </Stack>
  )
}
