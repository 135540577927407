import { useOnSignIn } from '../../hooks'
import { Card } from '../App/Card'
import { PrimaryButton, mergeStyleSets } from '@fluentui/react'

const getClassNames = () => {
  return mergeStyleSets({
    header: {
      margin: 10,
      textAlign: 'center',
    },
    paragraph: {
      margin: 10,
      textAlign: 'center',
    },
    buttonContainer: {
      margin: 10,
      display: 'flex',
      justifyContent: 'center',
    },
  })
}

export interface AuthRequiredCardProps {}

export const AuthRequiredCard: React.FC<AuthRequiredCardProps> = (_: AuthRequiredCardProps) => {
  const onSignIn = useOnSignIn()
  const classNames = getClassNames()

  return (
    <Card>
      <h2 className={classNames.header}>Not signed</h2>
      <p className={classNames.paragraph}>In order to access this feature, please sign in first.</p>
      <div className={classNames.buttonContainer}>
        <PrimaryButton onClick={onSignIn} text='Sign in' />
      </div>
    </Card>
  )
}
