import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ApiClient } from '../../api'
import type { RootState } from '../store'
import { ChannelIdentifier, OperationStatus } from '../models'
import { Channel, ErrorCode, ErrorCodes, getErrorMessageCode } from '@notidar/api'
import { msalInstance } from '../../auth'

interface ChannelState {
  channelIdentifier?: ChannelIdentifier
  status: OperationStatus
  error: ErrorCode | null
  channel: Channel | undefined
}

const initialState: ChannelState = {
  status: 'idle',
  error: null,
  channel: undefined,
}

export const loadChannel = createAsyncThunk<
  { channel: Channel },
  void,
  { state: RootState, rejectValue: ErrorCode }
>('channels/initializeChannel', async (_, thunkAPI) => {
    const { channelIdentifier } = selectChannelState(thunkAPI.getState())

    if (!channelIdentifier) {
      return thunkAPI.rejectWithValue(ErrorCodes.UNKNOWN)
    }

    try
    {
      const response = await ApiClient.getChannelAsync(channelIdentifier, {
        signal: thunkAPI.signal,
        secure: msalInstance.getActiveAccount() !== null,
      })
      
      const channel = response.data.channel
  
      if (!channel) {
        return thunkAPI.rejectWithValue(ErrorCodes.UNKNOWN)
      }
  
      return { channel }
    } catch (e) {
      return thunkAPI.rejectWithValue(getErrorMessageCode(e));
    }
  },
)

export const channelReducer = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    resetChannelState: state => {
      state.channelIdentifier = undefined
      state.channel = undefined
      state.status = 'idle'
      state.error = null
    },
    initChannelState: (state, action: PayloadAction<{ channelIdentifier: ChannelIdentifier }>) => {
      state.channelIdentifier = action.payload.channelIdentifier
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadChannel.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload ?? ErrorCodes.UNKNOWN;
      })
      .addCase(loadChannel.pending, (state, action) => {
        state.error = null
        state.status = 'loading'
        state.channel = undefined
      })
      .addCase(loadChannel.fulfilled, (state, action) => {
        if (
          state.channelIdentifier === action.payload.channel.channelId ||
          state.channelIdentifier === action.payload.channel.name
        ) {
          state.status = 'succeeded'
          state.channel = action.payload.channel
        }
      })
  },
})

export const { resetChannelState, initChannelState } = channelReducer.actions

export const selectChannelState = (state: RootState) => state.channel

export default channelReducer.reducer
