import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ApiClient } from '../../api'
import type { RootState } from '../store'
import { OperationError, OperationStatus } from '../models'
import { Channel } from '@notidar/api'
import { msalInstance } from '../../auth'

interface ChannelSearchState {
  searchTerm: string | undefined
  pageToken: string | undefined | null
  status: OperationStatus
  error: OperationError
  channels: Channel[]
}

const initialState: ChannelSearchState = {
  status: 'idle',
  error: null,
  channels: [],
  pageToken: undefined,
  searchTerm: undefined,
}

export const searchChannels = createAsyncThunk(
  'channels/searchChannels',
  async (params: { searchTerm: string | undefined; pageToken: string | undefined }, thunkAPI) => {
    const response = await ApiClient.getChannelsAsync(
      { SearchTerm: params.searchTerm, PageToken: params.pageToken },
      { signal: thunkAPI.signal, secure: msalInstance.getActiveAccount() !== null }
    )
    const { channels, pageToken } = response.data
    return { channels, pageToken: pageToken }
  }
)

export const channelSearchSlice = createSlice({
  name: 'channelSearch',
  initialState,
  reducers: {
    resetSearch: (state, action: PayloadAction<string | undefined>) => {
      state.channels = []
      state.status = 'idle'
      state.error = null
      state.pageToken = undefined
      state.searchTerm = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(searchChannels.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'Something failed'
      })
      .addCase(searchChannels.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(searchChannels.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.channels = (state.channels ?? []).concat(action.payload.channels)
        state.pageToken = action.payload.pageToken
      })
  },
})

export const { resetSearch } = channelSearchSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectChannelSearchState = (state: RootState) => state.channelSearch

export default channelSearchSlice.reducer
