import { Dropdown, IDropdownOption, mergeStyleSets } from '@fluentui/react'
import { LanguageCode } from '@notidar/api'
import { useCallback } from 'react'
import { ApiClient } from '../../../../api'
import { useAppDispatch } from '../../../../redux/hooks'
import { updateSettingsLanguage } from '../../../../redux/features/userSlice'
import { useTranslation } from 'react-i18next'

const options: IDropdownOption[] = [
  { key: LanguageCode.EN, text: 'English' },
  { key: LanguageCode.PL, text: 'Polski' },
  { key: LanguageCode.RU, text: 'Русский' },
]

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      margin: 10,
    },
  })
}

export interface GeneralSettingsProps {
  language: LanguageCode
}

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({ language }: GeneralSettingsProps) => {
  const classNames = getClassNames()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const onChange = useCallback(
    async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
      try {
        const value = option?.key as LanguageCode
        if (value) {
          dispatch(updateSettingsLanguage(value))
          await ApiClient.updateUserLanguageAsync({ language: value })
        }
      } catch {
        // ignore
      }
    },
    [dispatch],
  )

  return (
    <div className={classNames.container}>
      <Dropdown
        label={t('pages.settings.language_label')}
        selectedKey={language}
        onChange={onChange}
        options={options}
      />
    </div>
  )
}
