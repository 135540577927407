import * as React from 'react'
import { mergeStyleSets, ITheme, useTheme } from '@fluentui/react'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    card: {
      marginBottom: '10px',
      overflow: 'hidden',
      backgroundColor: theme.palette.white,
    },
  })
}

export interface CardProps {
  children: React.ReactNode
}

export const Card: React.FC<CardProps> = ({ children }: CardProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme)
  return <div className={classNames.card}>{children}</div>
}
