import { Label, SearchBox } from '@fluentui/react'
import { useState } from 'react'
import { AllFields } from '../../../utils'
import { MarkdownField, StringField, StringListField, TextFilter } from '@notidar/api'
import { useTranslation } from 'react-i18next'

export interface TextFilterComponentProps {
  filter: TextFilter
  fields: AllFields[]
  onSubmit: (filter: TextFilter) => void
}

export const TextFilterComponent = ({ filter, fields, onSubmit }: TextFilterComponentProps): JSX.Element | null => {
  const { t } = useTranslation();
  const [state, setState] = useState<string | undefined>(filter.query ?? undefined)

  const field = fields.find(y => y.name === filter.field) as StringField | StringListField | MarkdownField;
  const placeholder: string = (field?.displayName ?? field?.name)?.toLocaleLowerCase() ?? '';//TODO

  const onSubmitInternal = () => {
    const value = state;
    onSubmit({ ...filter, query: value });
  }

  const onClear = () => {
    setState("")
    onSubmit({ ...filter, query: "" });
  }

  return <>
    <Label>{field.displayName ?? field.name}</Label>
    <SearchBox
      iconProps={{ iconName: 'Search' }}
      placeholder={t("content.filters.text.placeholder")}
      value={state}
      onChange={(e, value) => setState?.(value || "")}
      onSearch={onSubmitInternal}
      onClear={onClear}
    />
  </>
}
