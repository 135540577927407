import { DefaultButton, Dropdown, IDropdownOption, IStackTokens, Stack } from '@fluentui/react'
import { FieldType, PostSection, PostSectionType, TagsPostSection } from '@notidar/api'
import { SectionEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { getFieldByFieldKey } from '../../utils'
import { useTranslation } from 'react-i18next'

const stackTokens: IStackTokens = { childrenGap: 10 }

const isValid = (section: Partial<TagsPostSection> & PostSection): boolean => {
  return section.tagsField !== undefined
}

export interface TagsSectionEditorComponentProps
  extends SectionEditorComponentProps<PostSectionType.Tags> {}

export const TagsSectionEditorComponent = ({
  fields,
  hidden,
  onUpdate,
  section,
}: TagsSectionEditorComponentProps) => {
  const { t } = useTranslation();
  const [sectionState, setSectionState] = useState<Partial<TagsPostSection> & PostSection>(section)

  const allowedMarkdownFields = fields
    .filter(x => x.type === FieldType.Enum || x.type === FieldType.EnumList)
    .map(x => ({ key: x.name, text: x.displayName ? `${x.displayName} (${x.name})` : x.name }))

  useEffect(() => {
    onUpdate(sectionState, isValid(sectionState))
  }, [sectionState]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTagsChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    const newState: Partial<TagsPostSection> & PostSection = {
      ...sectionState,
      tagsField: option?.key as string,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onTagsClear = (): void => {
    const newState: Partial<TagsPostSection> & PostSection = {
      ...sectionState, 
      tagsField: undefined 
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const tagsField = getFieldByFieldKey({ fields }, sectionState.tagsField)

  if (hidden) {
    return null
  }

  return (
    <Stack>
      <Stack tokens={stackTokens} horizontal wrap verticalAlign='end'>
        <Dropdown
          key={sectionState.tagsField}
          disabled={allowedMarkdownFields.length === 0}
          onChange={onTagsChange}
          selectedKey={tagsField ? sectionState.tagsField : undefined}
          placeholder={
            allowedMarkdownFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field")
          }
          options={allowedMarkdownFields}
          label={t("content.sections.tags.tags_field")}
        />
        <DefaultButton
          disabled={!sectionState.tagsField}
          onClick={onTagsClear}
          text={t("content.sections.shared.clear")}
        />
      </Stack>
    </Stack>
  )
}
