import { ITheme, IconButton, Stack, Text, mergeStyleSets, useTheme } from '@fluentui/react'
import { useState } from 'react'
import {
  DateTimeField,
  EnumField,
  EnumListField,
  Field,
  FieldType,
  ImageFileListField,
  LinkField,
  MarkdownField,
  MoneyField,
  NumberField,
  StringField,
} from '@notidar/api'
import { EnumFieldEditorComponent } from './Enum/EnumFieldEditorComponent'
import { DateTimeFieldEditorComponent } from './DateTime/DateTimeFieldEditorComponent'
import { LinkFieldEditorComponent } from './Link/LinkFieldEditorComponent'
import { MarkdownFieldEditorComponent } from './Markdown/MarkdownFieldEditorComponent'
import { ImageFieldEditorComponent } from './ImageFile/ImageFieldEditorComponent'
import { StringFieldEditorComponent } from './String/StringFieldEditorComponent'
import { useLocalizedFieldTypes } from './Shared.types'
import { NumberFieldEditorComponent } from './Number/NumberFieldEditorComponent'
import { MoneyFieldEditorComponent } from './Money/MoneyFieldEditorComponent'
import { useTranslation } from 'react-i18next'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    header: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
    },
    headerText: {
      margin: '0 10px 0',
    },
    formContainer: {
      borderWidth: '1px 0 0 0',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralQuaternary,
      padding: '0 10px 10px',
    },
  })
}

export interface FieldEditorComponentProps {
  field: Field
  onUpdate: (field: Field, isValid: boolean) => void
  onRemove: (field: Field) => void
}

export const FieldEditorComponent = ({ field, onUpdate, onRemove }: FieldEditorComponentProps) => {
  const { t } = useTranslation();
  const theme = useTheme()
  const allowedFieldTypes = useLocalizedFieldTypes();
  const classNames = getClassNames(theme)
  const [state, setState] = useState<{
    name?: string | null
    title?: string | null
    isValid?: boolean
  }>({
    name: field.name,
    title: field.displayName,
  })
  const [isHidden, setIsHidden] = useState<boolean>(true)

  const onUpdateInternal = (field: Field, isValid: boolean): void => {
    setState({ isValid: isValid, name: field.name, title: field.displayName })
    if (isHidden && !isValid) {
      setIsHidden(false)
    }
    onUpdate(field, isValid)
  }

  const getEditor = (field: Field): JSX.Element | null => {
    switch (field.type) {
      case FieldType.Enum:
      case FieldType.EnumList:
        return (
          <EnumFieldEditorComponent
            field={field as EnumField | EnumListField}
            hidden={isHidden}
            onUpdate={onUpdateInternal}
          />
        )
      case FieldType.Number:
        return <NumberFieldEditorComponent field={field as NumberField} hidden={isHidden} onUpdate={onUpdateInternal} />
      case FieldType.Money:
        return <MoneyFieldEditorComponent field={field as MoneyField} hidden={isHidden} onUpdate={onUpdateInternal} />
      case FieldType.ImageFileList:
        return (
          <ImageFieldEditorComponent
            field={field as ImageFileListField}
            hidden={isHidden}
            onUpdate={onUpdateInternal}
          />
        )
      case FieldType.DateTime:
        return (
          <DateTimeFieldEditorComponent field={field as DateTimeField} hidden={isHidden} onUpdate={onUpdateInternal} />
        )
      case FieldType.Link:
        return <LinkFieldEditorComponent field={field as LinkField} hidden={isHidden} onUpdate={onUpdateInternal} />
      case FieldType.Markdown:
        return (
          <MarkdownFieldEditorComponent field={field as MarkdownField} hidden={isHidden} onUpdate={onUpdateInternal} />
        )
      case FieldType.String:
      case FieldType.StringList:
        return <StringFieldEditorComponent field={field as StringField} hidden={isHidden} onUpdate={onUpdateInternal} />
      default:
        return null
    }
  }

  const switchIsHidden = () => {
    setIsHidden(x => !x)
  }

  const remove = () => {
    onRemove(field)
  }

  return (
    <Stack>
      <Stack
        className={classNames.header}
        style={isHidden ? {} : { backgroundColor: theme.palette.neutralQuaternary }}
        horizontal
        verticalAlign='center'
        horizontalAlign='space-between'
      >
        <Stack horizontal verticalAlign='center'>
          <Text
            className={classNames.headerText}
            style={state.isValid === false ? { color: 'rgb(164, 38, 44)' } : {}}
            variant={'large'}
            nowrap
            block
          >
            {(state.title ? `${state.title} (${state.name})` : `${state.name}`) +
              ' - ' +
              allowedFieldTypes.find(x => x.key === field.type)?.text}
          </Text>
          <IconButton iconProps={{ iconName: 'Cancel' }} title={t("shared.remove")} onClick={remove} />
        </Stack>
        <IconButton
          iconProps={{ iconName: isHidden ? 'ChevronDown' : 'ChevronUp' }}
          title={isHidden ? t("shared.expand") : t("shared.collapse")}
          onClick={switchIsHidden}
        />
      </Stack>
      <div className={isHidden ? undefined : classNames.formContainer}>{getEditor(field)}</div>
    </Stack>
  )
}
