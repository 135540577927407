import { FieldType, GalleryPostSection, ImageFileListValue, LinkListValue, LinkValue } from '@notidar/api'
import { SectionComponentProps } from '../SectionComponent'
import { SectionContainer } from '../SectionContainer'
import GallerySectionLightbox from './GallerySectionLightbox'
import { originalImageFileUrlFromId, optimizedImageFileUrlFromId, parseFieldValue } from '../../utils'
import { useContext } from 'react'
import { AppContentContext } from '../../context/appContentContext'
import { GalleryImage } from './GallerySectionTypes'
import { notEmpty } from '@notidar/common'

export interface GallerySectionComponentProps extends SectionComponentProps<GalleryPostSection> {
}

export const GallerySectionComponent = (props: GallerySectionComponentProps): JSX.Element | null => {
  const { mediaBaseUrl, screenType, themeType } = useContext(AppContentContext)

  const galleryImages = parseFieldValue<FieldType.Link | FieldType.LinkList | FieldType.ImageFileList, GalleryImage[]>(
    props,
    props.section.mediaField,
    {
      Link: (value: LinkValue) => { return value.linkPayload?.link ? [{ src: value.linkPayload.link, height: 0, width: 0 }] : undefined },
      LinkList: (value: LinkListValue) => { return value.linkListPayload?.links?.map(x => ({ src: x, height: 0, width: 0 })) },
      ImageFileList: (value: ImageFileListValue) => {
        return value.imageFileListPayload?.fileIds
          ?.filter(notEmpty)
          .map(x => ({
            src: originalImageFileUrlFromId(mediaBaseUrl, x),
            optimizedSrc: optimizedImageFileUrlFromId(mediaBaseUrl, x),
            height: 0,
            width: 0,
          })) ?? []
      },
    }) ?? []

  if (galleryImages.length === 0) {
    return null
  }

  return (
    <SectionContainer fullWidth>
      <GallerySectionLightbox
        screenType={screenType}
        themeType={themeType}
        galleryImages={galleryImages}
      />
    </SectionContainer>
  )
}
