import { useTranslation } from 'react-i18next'
import { CardList } from '../../App/Card'
import { NavigateMessageCard } from '../../shared/NavigateMessageCard'

export const NotFoundView = () => {
  const { t } = useTranslation();

  return (
    <CardList>
      <NavigateMessageCard title={t('pages.not_found.title')} description={t('pages.not_found.description')} navigateText={t('pages.not_found.go_search')} navigateLink='/' />
    </CardList>
  )
}
