import { IconButton, Label, Stack } from '@fluentui/react'
import { EnumOption } from '@notidar/api'
import { useTranslation } from 'react-i18next'

export interface EnumOptionItemProps {
  enumOption: EnumOption
  onRemove: () => void
}

export const EnumOptionItem = ({ enumOption, onRemove }: EnumOptionItemProps) => {
  const { t } = useTranslation();

  return (
    <Stack horizontal>
      <Label>
        {enumOption.displayString
          ? `${enumOption.displayString} (${enumOption.enumId})`
          : `${enumOption.enumId}`}
      </Label>
      <IconButton
        iconProps={{ iconName: 'Cancel' }}
        title={t('content.fields.enum.options_remove')}
        ariaLabel={t('content.fields.enum.options_remove')}
        onClick={onRemove}
      />
    </Stack>
  )
}
