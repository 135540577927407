import { Checkbox, Stack, TextField } from '@fluentui/react'
import { FieldType, StringField, StringListField } from '@notidar/api'
import { FieldEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface StringFieldEditorComponentProps
  extends FieldEditorComponentProps<FieldType.String | FieldType.StringList> {}

export const StringFieldEditorComponent = ({
  field,
  hidden,
  onUpdate,
}: StringFieldEditorComponentProps) => {
  const [fieldState, setFieldState] = useState<StringField | StringListField>(field);
  const { t } = useTranslation();

  const isValid = (field: StringField | StringListField): boolean => {
    return validateTitle(field.displayName ?? '') === ''
  }
  
  const validateTitle = (value: string): string => {
    if (value.length === 0) {
      return ''
    }
  
    if (value.length > 32) {
      return t("content.fields.shared.displayName_too_long");
    }
  
    return ''
  }

  useEffect(() => {
    onUpdate(fieldState, isValid(fieldState))
  }, [fieldState]) // eslint-disable-line react-hooks/exhaustive-deps

  const titleValueChanged = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const newField: StringField | StringListField = { ...fieldState, displayName: newValue }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  const searchableValueChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    const newField: StringField | StringListField = { ...fieldState, searchable: checked }
    setFieldState(newField)
    onUpdate(newField, isValid(newField))
  }

  if (hidden) {
    return null
  }

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <TextField
        label={t('content.fields.shared.displayName')}
        value={fieldState.displayName ?? ''}
        onChange={titleValueChanged}
        onGetErrorMessage={validateTitle}
        deferredValidationTime={500}
      />
      <Checkbox label={t("content.fields.shared.searchable")} checked={fieldState.searchable} onChange={searchableValueChanged} />
    </Stack>
  )
}
