import { MessageBar, MessageBarType, Stack, mergeStyleSets } from '@fluentui/react'
import { SubscriptionNotificationEditComponent } from './SubscriptionNotificationEditComponent'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { selectSubscriptionsState, updateSubscriptionNotificationLevel } from '../../redux/features/subscriptionsSlice'
import { selectPostsState } from '../../redux/features/postsSlice'
import { NotificationLevel } from '@notidar/api'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    sidebarContainer: {
      height: '100%',
      padding: '10px',
    },
  })
}

export const ChannelNotifications = () => {
  const classNames = getClassNames()
  const { t } = useTranslation()
  const { subscriptions, subscriptionsActionStatus } = useAppSelector(selectSubscriptionsState)
  const { subscriptionId } = useAppSelector(selectPostsState)
  const dispatch = useAppDispatch()

  const subscription = subscriptions?.find(s => s.subscriptionId === subscriptionId);

  const updateNotificationLevel = (notificationLevel: NotificationLevel) => {
    if (subscription) {
      dispatch(updateSubscriptionNotificationLevel({ channelId: subscription.channelId, subscriptionId: subscription.subscriptionId, notificationLevel }));
    }
  }

  return (
    <Stack verticalAlign='space-between' className={classNames.sidebarContainer}>
      {subscription
        ? <SubscriptionNotificationEditComponent
          loading={subscriptionsActionStatus === 'loading'}
          onChange={updateNotificationLevel}
          selectedNotificationLevel={subscription.notificationLevel} />
        : <MessageBar messageBarType={MessageBarType.info}>{t('right_bar.notifications.unavailable')}</MessageBar>}
    </Stack>
  )
}

