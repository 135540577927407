import { useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import { ChannelIdentifier, SubscriptionId } from '../../../redux/models'
import { useEffect } from 'react'
import {
  initPostsState,
  loadNextPosts,
  refreshPostsState,
  selectPostsState,
  resetPostsState,
} from '../../../redux/features/postsSlice'
import {
  selectChannelState,
  initChannelState,
  resetChannelState,
  loadChannel,
} from '../../../redux/features/channelSlice'
import { InfiniteListFeed } from '@notidar/content'
import { selectAppState } from '../../../redux/features/appSlice'
import { useNavigate } from '../../../hooks'
import { CentralizedView } from '../CentralizedView'
import { selectSubscriptionsState } from '../../../redux/features/subscriptionsSlice'
import { Card, CardList } from '../../App/Card'
import { useTranslation } from 'react-i18next'
import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react'
import { MessageCard } from '../../shared/MessageCard'
import { ErrorCodes } from '@notidar/api'
import { selectUserChannelsState } from '../../../redux/features/userChannelsSlice'

export const ChannelFeedView = () => {
  const { channelIdentifier, subscriptionId } = useParams<{ channelIdentifier: ChannelIdentifier, subscriptionId: SubscriptionId }>()
  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { enableManagement } = useAppSelector(selectAppState)
  const channelState = useAppSelector(selectChannelState)
  const subscriptionsState = useAppSelector(selectSubscriptionsState)
  const postsState = useAppSelector(selectPostsState)
  const { channels: userChannels } = useAppSelector(selectUserChannelsState);

  const subscription = subscriptionsState.subscriptions?.find(s => s.subscriptionId === subscriptionId)
  const isManagedChannel = channelState.channel && userChannels?.findIndex(x => x.channelId === channelState.channel!.channelId) !== -1

  useEffect(() => {
    if (channelIdentifier) {
      dispatch(initChannelState({ channelIdentifier }))
      dispatch(loadChannel())
    }
    return () => {
      dispatch(resetChannelState())
    }
  }, [dispatch, channelIdentifier])

  useEffect(() => {
    if (channelState.channel && postsState.channelId && postsState.channelId === channelState.channel?.channelId) {
      if (postsState.fieldsVersion && channelState.channel.fieldsVersion !== postsState.fieldsVersion) {
        dispatch(resetPostsState());
        dispatch(loadChannel());
      }
    }
  }, [dispatch, channelState, postsState])

  useEffect(() => {
    const channel = channelState.channel;
    if(channel) {
      const channelHasChanged = channel && postsState.channelId !== channel.channelId;
      const subscriptionHasChanged = subscription?.subscriptionId !== postsState.subscriptionId;
      if (channelHasChanged || subscriptionHasChanged) {
        dispatch(initPostsState({
          channelId: channel.channelId,
          fieldsVersion: channel.fieldsVersion,
          subscriptionId: subscription?.subscriptionId,
          channelFields: channel.fields,
          channelFilters: channel.filters,
          subscriptionFilters: subscription?.filters ?? [],
        }))
        dispatch(loadNextPosts())
      }
    }
  }, [dispatch, channelState.channel, subscription, postsState, subscriptionId])

  const loadMorePost = () => {
    if (postsState.status !== 'loading' && postsState.channelId && postsState.pageToken != null) {
      dispatch(loadNextPosts())
    }
  }

  const refreshPosts = () => {
    if (postsState.status !== 'loading' && postsState.channelId) {
      dispatch(refreshPostsState())
      dispatch(loadNextPosts())
    }
  }

  const retry = () => {
    if (channelState.status === 'failed') {
      dispatch(loadChannel())
    }
  }

  if (subscriptionId && !subscription) {
    return (
      <CardList>
        <MessageCard title={t('shared.not_found')} description={t('pages.feed.subscription_not_found')} />
      </CardList>
    )
  }

  if (!channelState.channel || postsState.posts === undefined) {
    if (channelState.status === 'loading' || postsState.status === 'loading') {
      return (
        <CardList>
          <Card>
            <Spinner style={{ margin: 10 }} label={t('shared.loading')} size={SpinnerSize.large} />
          </Card>
        </CardList>
      );
    }
    if (channelState.status === 'failed') {
      if (channelState.error === ErrorCodes.CHANNEL_NOT_FOUND) {
        return (
          <CardList>
            <MessageCard title={t('shared.not_found')} description={t('pages.feed.channel_not_found')} />
          </CardList>
        )
      }
      return (
        <CardList>
          <MessageCard title={t('shared.error')} description={t('shared.unknown_error')}>
            <DefaultButton onClick={retry} text={t('shared.retry')} />
          </MessageCard>
        </CardList>
      )
    }
    return null;
  }

  return (
    <CentralizedView>
      <InfiniteListFeed
        channel={channelState.channel}
        posts={postsState.posts}
        hasMore={postsState.pageToken != null}
        enableManagement={isManagedChannel && enableManagement}
        loadMore={loadMorePost}
        refresh={refreshPosts}
        navigate={navigate}
      />
    </CentralizedView>
  )
}
