import { mergeStyleSets } from "@fluentui/react"

export interface PropertiesSectionTitleComponentProps {
  title: string | undefined | null
}

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      textAlign: "center",
      fontWeight: "normal",
      margin: "0 0 5px 0",
    }
  })
}

export const PropertiesSectionTitleComponent = ({
  title,
}: PropertiesSectionTitleComponentProps): JSX.Element | null => {
  const classNames = getClassNames();
  return title ? <h2 className={classNames.container}>{title}</h2> : null
}
