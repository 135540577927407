import { DefaultButton, ProgressIndicator, mergeStyleSets } from '@fluentui/react'
import { ImageFilePreview } from './ImageFilePreview'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: 10,
      padding: 10,
    },
    infoContainer: {
      flexGrow: 4,
    },
    errorMessage: {
      margin: 0,
      display: '-webkit-box',
      '-webkit-line-clamp': '3',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
  })
}

export interface ImageFileValueEditorItemProps {
  imageUrl: string
  isUploading?: boolean
  errorMessage?: string
  onRemove: () => void
}

export const ImageFileValueEditorItem = ({
  imageUrl,
  isUploading,
  errorMessage,
  onRemove,
}: ImageFileValueEditorItemProps) => {
  const { t } = useTranslation();
  const classNames = getClassNames();

  return (
    <div className={classNames.container}>
      <ImageFilePreview imageUrl={imageUrl} />
      <div className={classNames.infoContainer}>
        <DefaultButton onClick={onRemove} text={t("content.fields.image.remove")} />
        {isUploading && <ProgressIndicator label={t("content.fields.image.uploading")} />}
        {errorMessage && <p className={classNames.errorMessage}>{errorMessage}</p>}
      </div>
    </div>
  )
}
