import { Card, CardList } from '../../App/Card'
import { Spinner } from '@fluentui/react'
import { ChannelNameHeader } from '../../Content/Shared/ChannelNameHeader'
import { AppearanceSettings } from './Appearance/AppearanceSettings'
import { useAppSelector } from '../../../redux/hooks'
import { selectUserState } from '../../../redux/features/userSlice'
import { GeneralSettings } from './General/GeneralSettings'
import { useTranslation } from 'react-i18next'
import { useAccount } from '@azure/msal-react'
import { AuthRequiredCard } from '../AuthRequiredCard'
import { CurrencySettings } from './Currency/CurrencySettings'

export const SettingsView = () => {
  const { t } = useTranslation()
  const { user } = useAppSelector(selectUserState)
  let account = useAccount()

  if (!account) {
    return (
      <CardList>
        <AuthRequiredCard />
      </CardList>
    )
  }

  return (
    <CardList>
      <Card>
        {user === undefined && <Spinner label={t('shared.loading')} />}
        {user && (
          <>
            <ChannelNameHeader name={t('pages.settings.title')} />
            <GeneralSettings language={user.settings.language} />
            <AppearanceSettings selectedTheme={user.settings.theme} />
            <CurrencySettings currencyCode={user.settings.currencyCode} />
          </>
        )}
      </Card>
    </CardList>
  )
}
