import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ApiClient } from '../../api'
import type { RootState } from '../store'
import { ChannelId, OperationError, OperationStatus, SubscriptionId } from '../models'
import { Post } from '@notidar/api'
import { msalInstance } from '../../auth'
import { areFilterEquals, generateDefaultFilters, notEmptyFilter } from '@notidar/content'
import { AllFields, AllFilters } from '@notidar/content/src/utils'

interface PostsState {
  status: OperationStatus
  error: OperationError
  pageToken: string | undefined | null
  filters: AllFilters[]
  posts: Post[] | undefined
  channelId?: ChannelId
  subscriptionId?: SubscriptionId
  fieldsVersion?: number
}

const initialState: PostsState = {
  status: 'idle',
  error: null,
  filters: [],
  pageToken: undefined,
  posts: undefined,
  channelId: undefined,
  subscriptionId: undefined,
  fieldsVersion: undefined
}

export const loadNextPosts = createAsyncThunk<
  {
    channelId: ChannelId
    usedPageToken: string | undefined
    posts: Post[]
    nextPageToken: string | null
    fieldsVersion: number
  },
  void,
  { state: RootState }
>('posts/loadMore', async (_, thunkAPI) => {
  const { channelId, pageToken: usedPageToken, filters } = selectPostsState(thunkAPI.getState())

  if (!channelId) {
    return thunkAPI.rejectWithValue('ChannelId was not set')
  }

  if (usedPageToken === null) {
    return thunkAPI.rejectWithValue('No more posts')
  }

  const actualFilters = filters.filter(notEmptyFilter);

  const response = actualFilters.length === 0
    ? await ApiClient.getPostsAsync(channelId, { PageToken: usedPageToken }, { signal: thunkAPI.signal, secure: msalInstance.getActiveAccount() !== null })
    : await ApiClient.searchPostsAsync(channelId, { pageToken: usedPageToken, filters: actualFilters }, { signal: thunkAPI.signal, secure: msalInstance.getActiveAccount() !== null })

  const { posts, pageToken: nextPageToken, fieldsVersion } = response.data
  return { channelId: channelId, usedPageToken, posts: posts, nextPageToken: nextPageToken ?? null, fieldsVersion: fieldsVersion }
})

export const postsReducer = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    resetPostsState: state => {
      state.channelId = undefined
      state.subscriptionId = undefined
      state.posts = undefined
      state.status = 'idle'
      state.pageToken = undefined
      state.error = null
    },
    initPostsState: (state, action: PayloadAction<{
      channelId: ChannelId,
      fieldsVersion: number,
      subscriptionId?: SubscriptionId,
      channelFields?: AllFields[],
      channelFilters?: AllFilters[],
      subscriptionFilters?: AllFilters[]
    }>) => {
      state.channelId = action.payload.channelId;
      state.subscriptionId = action.payload.subscriptionId;
      state.posts = undefined;
      state.status = 'idle';
      state.pageToken = undefined;
      state.error = null;
      state.fieldsVersion = action.payload.fieldsVersion;
      let resultFilters: AllFilters[] = [...action.payload.channelFilters ?? []].map(x => ({ ...x }));
      if (resultFilters.length === 0 && (action.payload.channelFields?.length ?? 0) > 0) {
        resultFilters = generateDefaultFilters(action.payload.channelFields!)
      }
      if (resultFilters.length > 0 && (action.payload.subscriptionFilters?.length ?? 0) > 0) {
        action.payload.subscriptionFilters?.forEach(filter => {
          let index = resultFilters.findIndex((existingFilter) => areFilterEquals(existingFilter, filter));
          if (index !== -1) {
            resultFilters[index] = { ...resultFilters[index], ...filter }
          }
        });
      }
      state.filters = resultFilters;
    },
    updateFilters: (state, action: PayloadAction<AllFilters[]>) => {
      state.filters = action.payload;
      state.posts = undefined
      state.status = 'idle'
      state.pageToken = undefined
      state.error = null
    },
    refreshPostsState: state => {
      state.posts = undefined
      state.status = 'idle'
      state.pageToken = undefined
      state.error = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadNextPosts.rejected, state => {
        state.status = 'failed'
        state.error = 'Something failed'
      })
      .addCase(loadNextPosts.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(loadNextPosts.fulfilled, (state, action) => {
        if (state.channelId === action.payload.channelId && state.pageToken === action.payload.usedPageToken) {
          state.status = 'succeeded'
          state.posts = (state.posts ?? []).concat(action.payload.posts)
          state.pageToken = action.payload.nextPageToken
          state.fieldsVersion = action.payload.fieldsVersion
        }
      })
  },
})

export const { resetPostsState, initPostsState, refreshPostsState, updateFilters } = postsReducer.actions

// Other code such as selectors can use the imported `RootState` type
export const selectPostsState = (state: RootState) => state.posts

export default postsReducer.reducer
