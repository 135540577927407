import ReactMarkdown from 'react-markdown'
import { SectionComponentProps } from '../SectionComponent'
import { mergeStyleSets, useTheme, ITheme } from '@fluentui/react'
import { FieldType, MarkdownPostSection, MarkdownValue } from '@notidar/api'
import { SectionContainer } from '../SectionContainer'
import { parseFieldValue } from '../../utils'
import { useContext } from 'react'
import { AppContentContext } from '../../context/appContentContext'

const getClassNames = (theme: ITheme, themeType?: 'light' | 'dark') => {
  return mergeStyleSets({
    root: {
      wordWrap: 'break-word',
      p: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        margin: '5px 0 0 0',
      },
      img: {
        maxWidth: '100%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        selectors: {
          '~ em': {
            display: 'block',
            textAlign: 'center',
          },
        },
        filter: themeType === 'dark' ? 'brightness(0.5) contrast(1.1)' : 'none',
      },
      a: {
        color: theme.palette.themePrimary,
      },
    },
  })
}

export interface MarkdownSectionComponentProps extends SectionComponentProps<MarkdownPostSection> {
}

export const MarkdownSectionComponent = (props: MarkdownSectionComponentProps) => {
  const theme = useTheme()
  const { themeType } = useContext(AppContentContext)

  const classNames = getClassNames(theme, themeType)

  const markdown = parseFieldValue<FieldType.Markdown, string>(
    props,
    props.section.markdownField,
    {
      Markdown: (value: MarkdownValue) => { return value.markdownPayload?.value ?? undefined }
    })

  if (!markdown) {
    return null
  }

  return (
    <SectionContainer>
      <ReactMarkdown linkTarget={'_blank'} className={classNames.root}>
        {markdown}
      </ReactMarkdown>
    </SectionContainer>
  )
}
