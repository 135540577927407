import { ErrorCodeProblemDetails } from "."

export function getErrorMessageCode(e: any): ErrorCode {
  const details = e.error as ErrorCodeProblemDetails;
  if (details.errorCode && details.errorCode in ErrorCodes) {
    return details.errorCode as ErrorCode;
  }
  return ErrorCodes.UNKNOWN;
}

export const ApiErrorCodes = {
  CHANNEL_NOT_FOUND: "CHANNEL_NOT_FOUND",

  CONNECTION_RSS_LINK_NOT_VALID: "CONNECTION_RSS_LINK_NOT_VALID",
  CONNECTION_RSS_FORMAT_NOT_SUPPORTED: "CONNECTION_RSS_FORMAT_NOT_SUPPORTED",
  CONNECTION_RSS_PARSING_FAILED: "CONNECTION_RSS_PARSING_FAILED",
  CONNECTION_RSS_SOURCE_FAILED_TO_RESPOND: "CONNECTION_RSS_SOURCE_FAILED_TO_RESPOND",
  CONNECTION_RSS_TOO_MANY_REDIRECTS : "CONNECTION_RSS_TOO_MANY_REDIRECTS",
} as const;

export const ErrorCodes = {
  UNKNOWN: "UNKNOWN",
  CONNECTION_RSS_TAKES_TOO_LONG: "CONNECTION_RSS_TAKES_TOO_LONG",
  ...ApiErrorCodes,
} as const;

export type ApiErrorCode = keyof typeof ApiErrorCodes;
export type ErrorCode = keyof typeof ErrorCodes;