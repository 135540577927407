import { Label, List, mergeStyleSets, Text } from '@fluentui/react'
import { ActionsPostSectionItem } from '@notidar/api'
import { ActionItem } from './ActionItem'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    message: {
      margin: '5px 0 5px',
      color: 'rgb(164, 38, 44)',
    },
  })
}

export interface ActionItemListProps {
  items: ActionsPostSectionItem[]
  onRemove: (index: number) => void
}

export const ActionItemList = ({ items, onRemove }: ActionItemListProps) => {
  const { t } = useTranslation();
  const classNames = getClassNames()

  const onRenderCell = (
    item?: ActionsPostSectionItem,
    index?: number,
    _?: boolean
  ): JSX.Element | null => {
    if (!item || index === undefined) {
      return null
    }

    return <ActionItem key={item.displayString} item={item} onRemove={() => onRemove(index)} />
  }

  if (items.length === 0) {
    return (
      <>
        <Label>{t("content.sections.actions.actions_title")}</Label>
        <Text nowrap block className={classNames.message}>
          {t("content.sections.actions.actions_not_available")}
        </Text>
      </>
    )
  }

  return (
    <>
      <Label>{t("content.sections.actions.actions_title")}</Label>
      <List items={items} onRenderCell={onRenderCell} />
    </>
  )
}
