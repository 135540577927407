import { Card, CardList } from '../../App/Card'
import { mergeStyleSets, Link } from '@fluentui/react'
import { useOnClickNavigate } from '../../../hooks'
import { Trans, useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    mainHeader: {
      margin: 10,
    },
    header: {
      margin: "20px 10px 10px",
      textDecoration: "underline",
    },
    paragraph: {
      margin: 10,
    },
    list: {
      margin: 10,
    },
  })
}

export const AboutView = () => {
  const { t } = useTranslation()
  const classNames = getClassNames()
  const onClickNavigate = useOnClickNavigate()

  return (
    <CardList>
      <Card>
        <h2 className={classNames.mainHeader}>{t('pages.about.title')}</h2>
        <p className={classNames.paragraph}>{t('pages.about.slogan')}</p>
        <h3 className={classNames.header}>{t('pages.about.what_is_title')}</h3>
        <p className={classNames.paragraph}>{t('pages.about.what_is_main')}</p>
        <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.what_is_creator' /></p>
        <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.what_is_reader' /></p>
        <h3 className={classNames.header}>{t('pages.about.use_case_title')}</h3>
        <p className={classNames.paragraph}>{t('pages.about.use_case_start')}</p>
        <ul className={classNames.list}>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.use_case_list.real_estate')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.use_case_list.car_listing')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.use_case_list.job_listing')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.use_case_list.news_feed')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.use_case_list.event_feed')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.use_case_list.product_listing')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.use_case_list.media_feed')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.use_case_list.recipes_feed')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.use_case_list.alerts_feed')}</p>
          </li>
        </ul>
        <p className={classNames.paragraph}>{t('pages.about.use_case_end')}</p>
        <h3 className={classNames.header}>{t('pages.about.limits_title')}</h3>
        <p className={classNames.paragraph}>{t('pages.about.limits_readers')}</p>
        <ul className={classNames.list}>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.limits_readers_list.subscriptions')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.limits_readers_list.notifications')}</p>
          </li>
        </ul>
        <p className={classNames.paragraph}>{t('pages.about.limits_creators')}</p>
        <ul className={classNames.list}>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.limits_creators_list.channels')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.limits_creators_list.posts')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.limits_creators_list.fields')}</p>
          </li>
          <li>
            <p className={classNames.paragraph}>{t('pages.about.limits_creators_list.sections')}</p>
          </li>
        </ul>
        <p className={classNames.paragraph}>{t('pages.about.limits_custom')}</p>
        <h3 className={classNames.header}>{t('pages.about.principles_title')}</h3>
        <p className={classNames.paragraph}>{t('pages.about.principles_main')}</p>
        <ul className={classNames.list}>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.principles_list.in_between' /></p>
          </li>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.principles_list.simplicity' /></p>
          </li>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.principles_list.flexibility' /></p>
          </li>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.principles_list.extensibility' /></p>
          </li>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.principles_list.accessibility' /></p>
          </li>
        </ul>
        <h3 className={classNames.header}>{t('pages.about.plans_and_vision_title')}</h3>
        <p className={classNames.paragraph}>{t('pages.about.plans_and_vision_main')}</p>
        <ul className={classNames.list}>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.plans_and_vision_list.feed' /></p>
          </li>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.plans_and_vision_list.conversion' /></p>
          </li>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.plans_and_vision_list.localization' /></p>
          </li>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.plans_and_vision_list.collaboration' /></p>
          </li>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.plans_and_vision_list.personalization' /></p>
          </li>
          <li>
            <p className={classNames.paragraph}><Trans components={{ b: <b /> }} i18nKey='pages.about.plans_and_vision_list.custom_sections' /></p>
          </li>
        </ul>
        <p className={classNames.paragraph}>{t('pages.about.plans_and_vision_end')}</p>
        <h3 className={classNames.header}>{t('pages.about.support_title')}</h3>
        <p className={classNames.paragraph}>
          <Trans
            i18nKey='pages.about.support_main'
            components={[(<Link onClick={onClickNavigate('/support')} href='https://notidar.com/support/'></Link>)]}
          />
        </p>
        <h3 className={classNames.header}>{t('pages.about.contact_title')}</h3>
        <p className={classNames.paragraph}>{t('pages.about.contact_main')}</p>
        <ul className={classNames.list}>
          <li>
            <p className={classNames.paragraph}>Email: <Link href='mailto:notidar.com@gmail.com'>notidar.com@gmail.com</Link></p>
          </li>
          <li>
            <p className={classNames.paragraph}>Reddit: <Link href='https://www.reddit.com/r/notidar/'>reddit.com/r/notidar</Link></p>
          </li>
          <li>
            <p className={classNames.paragraph}>Github: <Link href='https://github.com/notidar'>github.com/notidar</Link></p>
          </li>
          <li>
            <p className={classNames.paragraph}>X/Twitter: <Link href='https://x.com/notidar_app'>x.com/notidar_app</Link></p>
          </li>
          <li>
            <p className={classNames.paragraph}>YouTube: <Link href='https://www.youtube.com/@notidar'>youtube.com/@notidar</Link></p>
          </li>
        </ul>
      </Card>
    </CardList>
  )
}
