import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { resetSearch, searchChannels, selectChannelSearchState } from '../../../../redux/features/channelSearchSlice'
import { ChannelList } from './ChannelList'

export interface ChannelSearchProps {
  term: string | undefined
}

export const ChannelSearch: React.FC<ChannelSearchProps> = ({ term }: ChannelSearchProps) => {
  const channelSearchState = useAppSelector(selectChannelSearchState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (channelSearchState.searchTerm !== term) {
      dispatch(resetSearch(term))
      dispatch(
        searchChannels({
          searchTerm: term,
          pageToken: undefined,
        }),
      )
    }
  }, [term, channelSearchState, dispatch])

  return (
    <ChannelList
      channels={channelSearchState.channels}
      showLoading={channelSearchState.status === 'loading'}
      showError={channelSearchState.status === 'failed'}
    />
  )
}
