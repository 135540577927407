import { Label, List, mergeStyleSets, Stack, Text } from '@fluentui/react'
import { EnumOption } from '@notidar/api'
import { EnumOptionItem } from './EnumOptionItem'
import { useTranslation } from 'react-i18next'

const getClassNames = () => {
  return mergeStyleSets({
    message: {
      margin: '5px 0 5px',
      color: 'rgb(164, 38, 44)',
    },
  })
}

export interface EnumOptionItemListProps {
  enumOptionList: EnumOption[]
  onRemove: (enumOption: EnumOption) => void
}

export const EnumOptionItemList = ({ enumOptionList, onRemove }: EnumOptionItemListProps) => {
  const classNames = getClassNames();
  const { t } = useTranslation();

  const onRenderCell = (
    enumOption?: EnumOption,
    index?: number,
    _?: boolean
  ): JSX.Element | null => {
    if (!enumOption) {
      return null
    }

    return (
      <EnumOptionItem
        key={enumOption.enumId}
        enumOption={enumOption}
        onRemove={() => onRemove(enumOption)}
      />
    )
  }

  if (enumOptionList.length === 0) {
    return (
      <Stack>
        <Label>{t("content.fields.enum.options")}</Label>
        <Text nowrap block className={classNames.message}>
          {t("content.fields.enum.options_not_available")}
        </Text>
      </Stack>
    )
  }

  return (
    <>
      <Label>{t("content.fields.enum.options")}</Label>
      <List items={enumOptionList} onRenderCell={onRenderCell} />
    </>
  )
}
