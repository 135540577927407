import { Label, Link, useTheme, ITheme } from '@fluentui/react'
import { FontSizes } from '@fluentui/theme'
import { mergeStyleSets } from '@fluentui/merge-styles'
import { FieldType, LinkValue, StringValue, TitlePostSection } from '@notidar/api'
import { SectionComponentProps } from '../../SectionComponent'
import { SectionContainer } from '../../SectionContainer'
import { parseFieldValue } from '../../../utils'

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    link: {
      color: theme.palette.black,
      ':hover, :active, :active:hover': {
        textDecoration: 'none',
      },
      ':focus': {
        color: theme.palette.themePrimary,
      },
    },
  })
}

export interface TitleSectionComponentProps extends SectionComponentProps<TitlePostSection> { }

export const TitleSectionComponent = (props: TitleSectionComponentProps) => {
  const theme = useTheme()
  const classNames = getClassNames(theme)

  const title = parseFieldValue<FieldType.String, string>(
    props,
    props.section.titleField,
    {
      String: (value: StringValue) => { return value.stringPayload?.value ?? "" }
    })

  const subtitle = parseFieldValue<FieldType.String, string>(
    props,
    props.section.subtitleField,
    {
      String: (value: StringValue) => { return value.stringPayload?.value ?? "" }
    })

  const link = parseFieldValue<FieldType.Link, string>(
    props,
    props.section.linkField,
    {
      Link: (value: LinkValue) => { return value.linkPayload?.link ?? "" }
    })

  if (!title) {
    return null
  }

  const titleValue = link ? (
    <Link target='_blank' href={link} className={classNames.link}>
      {title}
    </Link>
  ) : (
    title
  )

  return (
    <SectionContainer>
      <Label style={{ fontSize: FontSizes.xxLarge, padding: 0, color: theme.palette.neutralPrimary }}>{titleValue}</Label>
      {subtitle ? <Label style={{ fontSize: FontSizes.medium, padding: 0, color: theme.palette.neutralPrimary }}>{subtitle}</Label> : null}
    </SectionContainer>
  )
}
