import * as React from 'react';
import { mergeStyles } from '@fluentui/merge-styles';

const defaultClass = mergeStyles({
  margin: "0px 10px 10px 10px"
});

const fullWidthClass = mergeStyles({
  margin: "0px 0px 5px 0px"
});

export interface SectionContainerProps {
  fullWidth?: boolean,
  children: React.ReactNode,
};

export const SectionContainer: React.FC<SectionContainerProps> = ({ children, fullWidth }: SectionContainerProps) => {
  const rootClass = fullWidth ? fullWidthClass : defaultClass;
  return (
    <div className={rootClass}>
      {children}
    </div>
  )
};