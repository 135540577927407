import { NumberField, NumberValue } from '@notidar/api'

export interface NumberValueComponentProps {
  field: NumberField
  value: NumberValue
}

export const NumberValueComponent = ({ field, value }: NumberValueComponentProps): JSX.Element | null => {
  const numberValue = value.numberPayload?.value;
  if (numberValue === undefined || numberValue === null) {
    return null;
  }

  const exponentMultiplier = 10 ** field.exponent;
  const displayValue = numberValue / exponentMultiplier;

  if (field.suffix) {
    return <span>{displayValue}{field.suffix}</span>
  }

  return <span>{displayValue}</span>
}
