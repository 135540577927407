import { DefaultButton } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

export interface UploadImageFileButtonProps {
  allowMultiple: boolean
  forceCamera: boolean
  onImagesAdded: (files: File[]) => void
}

export const UploadImageFileButton = ({
  allowMultiple,
  forceCamera,
  onImagesAdded,
}: UploadImageFileButtonProps) => {
  const { t } = useTranslation();
  let test: HTMLInputElement | null
  return (
    <>
      <DefaultButton
        style={{ margin: '10px 5px' }}
        onClick={() => test?.click()}
        text={allowMultiple ? t("content.fields.image.upload_multiple") : t("content.fields.image.upload")} />
      <input
        multiple={allowMultiple}
        hidden={true}
        ref={input => (test = input)}
        accept='image/png, image/gif, image/jpeg, image/jpg'
        capture={forceCamera ? 'environment' : undefined}
        type='file'
        onChange={event => {
          if (event?.target?.files) {
            let imagesToAdd: File[] = []
            for (let i = 0; i < event.target.files.length; i++) {
              const selectedFile = event.target.files.item(i)
              if (selectedFile) {
                imagesToAdd.push(selectedFile)
              }
            }
            if (imagesToAdd.length > 0) {
              onImagesAdded(imagesToAdd)
            }
            event.target.value = ''
          }
        }}
      />
    </>
  )
}
