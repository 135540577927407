import { FieldType, Filter, FilterType, TextFilter } from '@notidar/api'
import { FilterEditorComponentProps } from '../../shared/shared.types'
import { useEffect, useState } from 'react'
import { AllFields } from '../../../utils'
import { IStackTokens, Stack } from '@fluentui/react'

const stackTokens: IStackTokens = { childrenGap: 10 }

const isValid = (filter: Partial<TextFilter> & Filter, fields: AllFields[]): boolean => {
  const isFieldTypeValid = 
    [FieldType.String, FieldType.StringList, FieldType.Markdown].includes(fields.find(field => field.name === filter.field)?.type ?? FieldType.Unknown);

    if(!isFieldTypeValid) {
      return false;
    }

    return true;
}

interface TextSearchFilterEditorComponentProps extends FilterEditorComponentProps<FilterType.Text> { }

export const TextSearchFilterEditorComponent = ({ filter, fields, hidden, onUpdate }: TextSearchFilterEditorComponentProps): JSX.Element | null => {
  const [filterState, setFilterState] = useState<Partial<TextFilter> & Filter>(filter)

  useEffect(() => {
    onUpdate(filterState, isValid(filterState, fields))
  }, [filterState]) // eslint-disable-line react-hooks/exhaustive-deps

  if (hidden) {
    return null
  }

  var availableFields = fields
    .filter(x => [FieldType.String, FieldType.StringList, FieldType.Markdown].includes(x.type))
    .filter(x => filterState.field !== x.name);

  // const onFieldAdded = (fieldName: string): void => {
  //   const selectedField = availableFields.find(x => x.name === fieldName);
  //   if (selectedField) {
  //     const newState = { ...filterState, fields: [...filterState.fields ?? [], selectedField.name] };
  //     setFilterState(newState);
  //     onUpdate(filterState, isValid(filterState, fields));
  //   }
  // }

  // const onFieldRemoved = (fieldName: string): void => {
  //   const newState = { ...filterState, fields: [...filterState.fields?.filter(x => x !== fieldName) ?? []] };
  //   setFilterState(newState);
  //   onUpdate(filterState, isValid(filterState, fields));
  // }

  return (
    null
    // <Stack>
    //   <FieldItemList existingFields={fields} fieldNames={filterState.fields ?? []} onRemove={onFieldRemoved} />
    //   <Stack tokens={stackTokens} horizontal wrap verticalAlign='end'>
    //     <FieldItemSelector availableFields={availableFields} onFieldSelected={onFieldAdded} />
    //   </Stack>
    // </Stack>
  )
}
