import { RightSideBarType } from '../../../redux/features/appSlice'
import { ChannelToggles } from '../../Toggles'
import { ChannelFilters } from '../../Filters'
import { ChannelNotifications } from '../../Notifications'

export interface BodyProps {
  type: RightSideBarType
}

export const Body = ({ type }: BodyProps) => {
  switch (type) {
    case RightSideBarType.Filters:
      return <ChannelFilters />
    case RightSideBarType.Notifications:
      return <ChannelNotifications />
    case RightSideBarType.Toggles:
      return <ChannelToggles />
    default:
      return null
  }
}
